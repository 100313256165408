import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ResourceSlider extends Component {
  render() {
    const { resource } = this.props;
    if (!resource || resource.length === 0) return null;
    return (
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <ol className="carousel-indicators">
          {resource.map((entry, index) => (
            <li
              key={index}
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={index}
              className={`${index === 0 ? 'active' : ''}`}
            />
          ))}
        </ol>
        <div className="carousel-inner resources featured">
          {resource.map((entry, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? 'active' : ''}`}
            >
              <a
                href={entry.fields.url}
                target="_blank"
                rel="noopener noreferrer"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <li className="media">
                  <div className="media-img">
                    <img
                      src={
                        entry.fields.image
                          ? entry.fields.image.fields.file.url
                          : ''
                      }
                      className="rounded img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="media-body">
                    <h3 className="mt-0 mb-1">{entry.fields.title}</h3>
                    <span>{entry.fields.category}</span>
                    <span>
                      {`Posted on: ${new Date(
                        entry.fields.date
                      ).toDateString()}`}
                    </span>
                    <h4>{entry.fields.newsdescription}</h4>
                  </div>
                </li>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ResourceSlider.propTypes = {
  resource: PropTypes.array
};
export default ResourceSlider;
