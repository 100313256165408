import { replaceTemplatesInString } from '../../utils/strings';

export function mapToFormInputOptionsValues(questionOptions) {
  return questionOptions.reduce(
    (accumulator, questionOption) => {
      const { label, value } = questionOption;
      return {
        options: [...accumulator.options, label],
        values: [...accumulator.values, value]
      };
    },
    { options: [], values: [] }
  );
}

export function mapQuestionsToFormInputProps(
  questions,
  { inputIdPrefix } = {}
) {
  const formInputProps = questions.map(question => {
    const {
      id,
      label,
      required,
      unit,
      validationMessage,
      questionType,
      minRange,
      maxRange,
      description,
      questionOptions = []
    } = question;

    const { options, values } = mapToFormInputOptionsValues(questionOptions);

    const inputConfig = {
      ...(options.length && values.length ? { options, values } : null),
      label: unit ? `${label} (${unit})` : label,
      description,
      inputType: questionType,
      inputID: inputIdPrefix ? `${inputIdPrefix}${id}` : id,
      required,
      invalidFeedback: replaceTemplatesInString(validationMessage, {
        minRange,
        maxRange
      })
    };
    return inputConfig;
  });

  return formInputProps;
}
