import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPrnOptions, actions } from '../actions/prnOptions';

function usePRNOptions() {
  const state = useSelector(state => state.prnOptions) || {};
  const dispatch = useDispatch();

  function refetch() {
    dispatch(actions.CLEAR());
    dispatch(fetchPrnOptions());
  }

  useEffect(() => {
    if (!state.didLoad && !state.isLoading) {
      dispatch(fetchPrnOptions());
    }
  }, [dispatch, state.didLoad, state.isLoading]);

  return { ...state, refetch };
}

export default usePRNOptions;
