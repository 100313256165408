import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../../FormInput/FormInput';
import useFeatureConfiguration from '../../../hooks/useFeatureConfiguration';
import AlertBanner from '../../AlertBanner';

function LoginModalContent({
  handleSubmit,
  handleChange,
  email,
  password,
  errors,
  success,
  signButtonClick,
  submitError,
  clearSubmitError
}) {
  const config = useFeatureConfiguration();
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {config?.logInModalTitle ?? 'Please Log In'}
        </h5>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {submitError ? (
          <AlertBanner
            className="mt-4"
            type="error"
            title={
              config?.logInModalErrorTitle ??
              'Error validating your credentials'
            }
            errorMessage={
              config?.logInModalErrorMessage ??
              'Could not validate your credentials. Please try again later.'
            }
            onCloseClick={clearSubmitError}
          />
        ) : null}
        <form noValidate className="needs-validation" onSubmit={handleSubmit}>
          <div className="mb-3">
            <FormInput
              label={config?.logInModalEmailField?.label ?? 'Email*'}
              inputType="text"
              inputID="email"
              placeholder={
                config?.logInModalEmailField?.placeholder ?? 'you@example.com'
              }
              value={email}
              required={true}
              onChange={handleChange}
              invalidFeedback={
                config?.logInModalEmailField?.errorMessage ??
                'Please enter your email'
              }
              errors={errors}
              success={success}
            />
          </div>
          <div className="mb-3">
            <FormInput
              label={config?.logInModalPasswordField?.label ?? 'Password*'}
              inputType="password"
              inputID="password"
              placeholder={config?.logInModalPasswordField?.placeholder ?? ''}
              value={password}
              required={true}
              onChange={handleChange}
              invalidFeedback={
                config?.logInModalPasswordField?.errorMessage ??
                'Please enter a password that is at least 8 characters long with at least one letter and one number'
              }
              errors={errors}
              success={success}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-dark"
          data-bs-dismiss="modal"
        >
          {config?.modalsCloseButton || 'Close'}
        </button>
        <button
          type="button"
          onClick={signButtonClick}
          className="btn btn-monarc-orange"
          data-testid="signin-btn"
        >
          {config?.confirmAccountOwnershipModalLogIn || 'Log In'}
        </button>
      </div>
    </div>
  );
}

LoginModalContent.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  email: PropTypes.string,
  password: PropTypes.string,
  errors: PropTypes.any,
  success: PropTypes.any,
  signButtonClick: PropTypes.func,
  submitError: PropTypes.bool,
  clearSubmitError: PropTypes.func
};

export default LoginModalContent;
