export function cleanLocaleName(locale, short) {
  const splittedLocaleCode = locale.code.split('-');
  let name;
  if (splittedLocaleCode[0] === 'es') {
    name = 'Español';
  } else {
    name = locale.name.split(' ')[0];
  }
  if (!short && splittedLocaleCode[1]) name += ` (${splittedLocaleCode[1]})`;
  return name;
}
