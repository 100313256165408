import i18n from '../i18n';

export const USER_DOCTOR = 'D';
export const USER_PATIENT = 'P';
export const USER_CAREGIVER = 'C';
export const USER_OTHER = 'O';

export const TYPE_MAPPING = {
  [USER_OTHER]: '',
  [USER_CAREGIVER]: i18n.t('userTypes.caregiver'),
  [USER_PATIENT]: i18n.t('userTypes.patient')
};
