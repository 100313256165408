import React from 'react';
import PropTypes from 'prop-types';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import queryString from 'qs';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

import { useTheme } from './ThemeProvider';
import usePRNOptions from '../hooks/usePRNOptions';
import LoadingSpinner from './shared/LoadingSpinner';

function getProgram(item) {
  const {
    disease: { diseaseCode },
    program: { programId: programType }
  } = item;
  return { diseaseCode, programType };
}

function PRNOption({ option, refString }) {
  const {
    theme: { secondaryColor }
  } = useTheme();
  const { diseaseCode, programType } = getProgram(option);
  const sendGAEvent = () => {
    let action = 'Click on Landing page: ' + option.title;
    if (refString) action += ', ref: ' + refString;
    ReactGA.event({
      category: 'User',
      action
    });
  };

  return (
    <Link
      to={`/join/${diseaseCode}/${programType}`}
      onClick={sendGAEvent}
      className="list-group-item list-group-item-action"
      data-testid={`link-${option.title}`}
    >
      {option.title}
      <ChevronRightIcon color={secondaryColor} />
    </Link>
  );
}

PRNOption.propTypes = {
  option: PropTypes.any,
  refString: PropTypes.string
};

function PRNOptions({ location }) {
  const { items: options, isLoading } = usePRNOptions();
  let search = location?.search?.substr?.(1) || '';
  let refString = search
    ? queryString.parse(search).r
    : window.sessionStorage.getItem('referral');
  return (
    <div className="list-group landing" data-cy="list-group-landing">
      {isLoading ? <LoadingSpinner /> : null}
      {options
        .sort((a, b) => a.rank > b.rank)
        .map(entry => (
          <PRNOption key={entry.sysId} option={entry} refString={refString} />
        ))}
    </div>
  );
}

PRNOptions.propTypes = {
  location: PropTypes.any
};

export default PRNOptions;
