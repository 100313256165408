import styled from 'styled-components';

type GapProps = {
  $gap: number;
};

export const VerticalGap = styled.div<GapProps>`
  height: ${({ $gap }) => $gap}px;
  width: 0px;
`;

export const DropdownSeparator = styled.div`
  height: 1px;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.border.secondary};
  margin-inline: 1.5rem;
`;

export const HorizontalGap = styled.div`
  width: 1px;
  flex-shrink: 0;
  align-self: stretch;

  background-color: ${({ theme }) => theme.colors.border.secondary};
`;
