import styled from 'styled-components';

export const Icon = styled.div<{
  $url: string;
  $open?: boolean;
  $size?: number;
}>`
  width: ${({ $size }) => $size || 24}px;
  height: ${({ $size }) => $size || 24}px;
  mask-size: contain;
  background-color: ${({ theme }) => theme.colors.text.primary};
  -webkit-mask-image: url(${({ $url }) => $url});
  mask-image: url(${({ $url }) => $url});
  ${({ $open }) => $open && `transform: rotate(180deg);`}
`;

export const SelectorContainer = styled.div<{ $open: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 52px;
  @media (min-width: 964px) {
    position: fixed;
    top: 24px;
    right: 24px;
  }
  @media (max-width: 964px) {
    align-self: flex-end;
    position: relative;
    margin-bottom: 12px;
  }
  padding: 8px 12px 8px 16px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid
    ${({ theme, $open }) =>
      $open ? theme.colors.action.primary : theme.colors.border.secondary};
  background-color: ${({ theme, $open }) =>
    $open
      ? theme.colors.action.hover.secondary
      : theme.colors.surface.background.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.action.hover.secondary};
    border: 1px solid ${({ theme }) => theme.colors.action.primary};
  }
`;

export const OptionsContainer = styled.div`
  width: 200px;
  padding: 16px 0px 16px 0px;
  gap: 12px;
  border-radius: 8px;
  border: 1px;
  box-shadow: 0px 4px 4px 0px #161c2214;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  background-color: ${({ theme }) => theme.colors.surface.background.primary};
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
`;

export const LocaleOption = styled.div<{ $selected: boolean }>`
  width: 100%;
  background-color: ${({ theme, $selected }) =>
    $selected
      ? theme.colors.surface.background.secondary
      : theme.colors.surface.background.primary};
  min-height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
