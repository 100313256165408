import Joi from 'joi';
import { createQuestionsSchemaObject } from '../../RegisterNew/utils/questionSchema';
import { FIELDS_CONFIG_MAPPING } from '../../UserInformationForm/mappers';
import { USER_INFORMATION_INPUTS_SCHEMA_RULES } from '../../RegisterNew/utils/userInformationSchema';

export const createScreeningQuestionsFormSchema = ({
  screeningQuestions = [],
  inputIdPrefix
}) => {
  const passwordInputID = FIELDS_CONFIG_MAPPING.passwordField.inputID;
  const passwordSchemaObject = {
    [passwordInputID]: USER_INFORMATION_INPUTS_SCHEMA_RULES[passwordInputID]
  };
  const questionsSchemaObject = createQuestionsSchemaObject(
    screeningQuestions,
    { inputIdPrefix }
  );
  return {
    schema: Joi.object({
      ...questionsSchemaObject,
      ...passwordSchemaObject
    })
  };
};
