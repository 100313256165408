import React, { useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import JoinNowBtn from './JoinNowBtn';

const LandingPageFaqs = props => {
  const { content } = props;
  const { title, questions, answers } = useMemo(() => {
    const elements = content.split('---').filter(item => item.trim());

    let questions = [];
    let answers = [];
    elements.forEach((element, index) => {
      if (index === 0) return null;
      const splitted = element.split(/\r\n|\r|\n/).filter(item => item);

      questions.push(splitted[0].replace('##', '').trim());
      answers.push(splitted.slice(1).join('\n'));
    });

    return {
      title: elements[0].replace('#', '').trim() + 's',
      questions,
      answers
    };
  }, [content]);

  const renderFaqItem = useCallback(
    index => (
      <div className="card" key={index}>
        <div className="card-header" role="tab" id={'heading' + index}>
          <a
            className="collapsed"
            data-bs-toggle="collapse"
            data-cy="accordion-toggle"
            data-bs-parent="#accordionEx"
            href={'#collapse' + index}
            aria-expanded="false"
            aria-controls={'collapse' + index}
          >
            <h5 className="mb-0">
              {questions[index]}
              <ChevronUpIcon />
            </h5>
          </a>
        </div>

        <div
          id={'collapse' + index}
          className="collapse"
          data-cy="accordion-data"
          role="tabpanel"
          aria-labelledby={'heading' + index}
          data-bs-parent="#accordionEx"
        >
          <div className="card-body">
            <ReactMarkdown>{answers[index]}</ReactMarkdown>
          </div>
        </div>
      </div>
    ),
    [questions, answers]
  );

  return (
    <div className="faq-section container">
      <div className="row">
        <div className="col-md-2">
          <h2 className="benefits-title">{title}</h2>
        </div>
        <div className="col-md-10 benefits-container">
          <div
            className="accordion md-accordion"
            id="accordionEx"
            role="tablist"
            aria-multiselectable="true"
          >
            {questions.map((_, index) => renderFaqItem(index))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <div className="jumbotron-join-button-container">
            <JoinNowBtn {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

LandingPageFaqs.propTypes = {
  content: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any
};

export default LandingPageFaqs;
