import React, { Component } from 'react';
import Nav from './Nav';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class EmailConfirmationNeeded extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Nav />
        <h2>{this.props.t('confirmEmail')}</h2>
      </div>
    );
  }
}

EmailConfirmationNeeded.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(EmailConfirmationNeeded);
