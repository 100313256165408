import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import PropTypes from 'prop-types';

class RadioInput extends Component {
  state = {
    options: []
  };
  setOptions = () => {
    const { inputID, values, options: opts = [] } = this.props;
    const options = opts.map((option, i) => ({
      value: values[i],
      label: option,
      inputID
    }));

    this.setState({ options });
  };
  componentDidMount = () => {
    this.setOptions();
  };
  componentDidUpdate = oldProps => {
    if (!_.isEqual(oldProps.options, this.props.options)) {
      this.setOptions();
    }
  };
  render() {
    const isError = this.props.errors?.[this.props.inputID];
    const isSuccess = this.props.success?.[this.props.inputID];
    const className = `custom-select d-block w-100 ${
      isError ? 'is-invalid' : ''
    } ${isSuccess ? 'is-valid' : ''}`;
    const { inputID, onChange, required, selectedValue } = this.props;
    return (
      <Select
        isSearchable={true}
        className={className}
        inputId={inputID}
        options={this.state.options}
        onChange={onChange}
        required={required}
        defaultValue={{
          label: selectedValue,
          value: selectedValue,
          inputID
        }}
      />
    );
  }
}

RadioInput.propTypes = {
  errors: PropTypes.any,
  inputID: PropTypes.any,
  onChange: PropTypes.any,
  options: PropTypes.any,
  required: PropTypes.any,
  selectedValue: PropTypes.any,
  success: PropTypes.any,
  values: PropTypes.any
};
export default RadioInput;
