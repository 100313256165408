import React from 'react';
import { DismissAlertBtn } from '../atoms/buttons';
import { ErrorAlert, FormAlertContainer } from '../atoms/containers';
import { H4 } from '../atoms/typography';

type SessionManagementAlertProps = {
  title?: string;
  message: string;
  display: boolean;
  dismiss?: () => void;
};

export function SessionManagementAlert({
  message,
  title,
  display,
  dismiss
}: Readonly<SessionManagementAlertProps>) {
  return (
    <FormAlertContainer>
      {display && (
        <ErrorAlert
          role="alert"
          data-cy="error-alert"
          data-testid="error-alert"
        >
          <div>
            {title && <H4>{title}</H4>}
            {message}
          </div>
          {dismiss && (
            <DismissAlertBtn
              type="button"
              className="close"
              onClick={dismiss}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </DismissAlertBtn>
          )}
        </ErrorAlert>
      )}
    </FormAlertContainer>
  );
}
