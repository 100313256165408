import React from 'react';
import PropTypes from 'prop-types';

function HeaderWithBackground({
  title,
  backgroundImage: image,
  titleStyle,
  style
}) {
  const bannerStyle = { ...style };
  if (image?.src) {
    bannerStyle.backgroundImage = `url('${image.src}')`;
  }
  return (
    <div className="header-banner" style={bannerStyle}>
      <div className="layer" />
      <h2 style={titleStyle}>{title}</h2>
    </div>
  );
}

HeaderWithBackground.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.shape({
    src: PropTypes.string
  }),
  titleStyle: PropTypes.any,
  style: PropTypes.any
};

export default HeaderWithBackground;
