import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SideMenuItemContainer from './SideMenuItemContainer';
import { PRE_SCREENING_PASSED } from '../../constants/screeningPassedTypes';

class SideMenu extends Component {
  state = {
    shrStatus: null,
    shrStatusBadge: null,
    unreadBadge: null,
    unsignedBadge: null
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ shrStatus: this.props.shrStatus });
  }
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeSideMenu();
    }
  };
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  badgeContainer = content => (
    <span className="badge badge-pill badge-danger inbox-header-badge">
      {content}
    </span>
  );
  renderUnreadBadge = () => {
    const { unreadItemsCount } = this.props;
    if (unreadItemsCount && unreadItemsCount > 0) {
      return this.badgeContainer(unreadItemsCount);
    }
    return null;
  };
  renderUnsignedBadge = () => {
    const { unsignedAuthsCount } = this.props;
    if (unsignedAuthsCount && unsignedAuthsCount > 0) {
      return this.badgeContainer(unsignedAuthsCount);
    }
    return null;
  };

  renderShrStatusBadge = () => {
    const { shrStatus } = this.props;
    if ([0, 1, 3].includes(shrStatus)) {
      return this.badgeContainer('!');
    }
    return null;
  };
  componentDidUpdate = () => {
    const { shrStatus } = this.props;
    if (this.state.shrStatus !== shrStatus) {
      this.setState({ shrStatus: this.props.shrStatus });
    }
  };

  closeSideMenu = () => {
    if (this.props.isMobile) {
      this.props.toggleSideMenuMobile();
    }
  };

  render = () => {
    const { isMobile } = this.props;
    const isReallyMobile = isMobile && window.innerWidth <= 960;
    return (
      <React.Fragment>
        {!isReallyMobile ? (
          <div
            id="sidenav"
            className="col-12 col-md-3 pl-0 pr-0"
            style={{ overflow: 'hidden' }}
          >
            <nav className="col-3 d-none d-md-block order-1 sidebar menu">
              <div className="sidebar-sticky">
                <SideMenuItemContainer
                  className="nav flex-column"
                  closeSideMenu={this.closeSideMenu}
                  {...this.props}
                  {...this.state}
                  unreadBadge={this.renderUnreadBadge()}
                  unsignedBadge={this.renderUnsignedBadge()}
                  shrStatusBadge={this.renderShrStatusBadge()}
                />
              </div>
            </nav>
          </div>
        ) : (
          <div
            ref={this.setWrapperRef}
            className="mobile-sidebar col-12 col-md-3 pl-0 pr-0"
            key="list-container"
          >
            <SideMenuItemContainer
              closeSideMenu={this.closeSideMenu}
              {...this.props}
              {...this.state}
              unreadBadge={this.renderUnreadBadge()}
              unsignedBadge={this.renderUnsignedBadge()}
              shrStatusBadge={this.renderShrStatusBadge()}
            />
          </div>
        )}
      </React.Fragment>
    );
  };
}

SideMenu.propTypes = {
  isMobile: PropTypes.any,
  shrStatus: PropTypes.any,
  history: PropTypes.any,
  toggleSideMenuMobile: PropTypes.any,
  unreadItemsCount: PropTypes.any,
  unsignedAuthsCount: PropTypes.any,
  needsToSign: PropTypes.bool
};

function mapStateToProps(state) {
  const {
    auth: { profileSession },
    providers: { providers, isLoading },
    user: {
      consents: { consents, needsToSign },
      shrStatus
    },
    records
  } = state;
  return {
    profileSession,
    providers,
    shrStatus,
    records,
    consents,
    isLoading,
    needsToSign,
    unsignedAuthsCount: needsToSign ? 1 : 0,
    needsScreening:
      profileSession?.user?.screeningPassed === PRE_SCREENING_PASSED
  };
}
export default connect(mapStateToProps)(SideMenu);
