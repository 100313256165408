import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../shared/LoadingSpinner';

const SignedAuthItem = ({ id, onClick, title, version, loading }) => (
  <div
    name="inboxItem"
    data-id={id}
    data-testid={id}
    data-url={'/home/authorizations/pdf/' + id}
    onClick={onClick}
    className="list-group-item list-group-item-action inbox-card-item-list inbox-card-item-list-read flex-column align-items-start mb-2"
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-11 col-md-11">
          <div className="w-100">
            <h5 className="mb-1 inbox-item-title">{title}</h5>
            <div className="text-muted">
              <i className="fas fa-file" /> {version}
            </div>
          </div>
        </div>
        {loading && (
          <div className="col-1 col-md-1">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  </div>
);

SignedAuthItem.propTypes = {
  id: PropTypes.any,
  onClick: PropTypes.any,
  title: PropTypes.any,
  version: PropTypes.any,
  loading: PropTypes.bool
};

export default SignedAuthItem;
