import styled from 'styled-components';
import { ItemProps } from './nav';

export const FormLogo = styled.img`
  display: flex;
  align-self: center;
  max-width: 300px;
  max-height: 100px;
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const SessionMgtFeedbackIcon = styled.img`
  margin-bottom: 32px;
`;

type NavIconProps = ItemProps & {
  $url?: string;
  $flip?: boolean;
};

export const NavIcon = styled.div<NavIconProps>`
  width: 24px;
  height: 24px;
  mask-size: contain;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.brand?.color?.primary : '#161C22'};
  -webkit-mask-image: url(${({ $url }) => $url});
  mask-image: url(${({ $url }) => $url});
  transform: rotate(${({ $flip }) => ($flip ? '180deg' : '0')});
`;
type IconProps = ItemProps & {
  $url: string;
  $rotation?: string;
  $color?: string;
};

export const Icon = styled.div.attrs<IconProps>(props => ({
  className: props.className ?? 'sc-icon'
}))`
  width: 24px;
  height: 24px;
  mask-size: contain;
  background-color: ${({ $isActive, theme, $color }) =>
    $isActive ? theme.brand?.color?.primary : $color ?? '#161C22'};
  -webkit-mask-image: url(${({ $url }) => $url});
  mask-image: url(${({ $url }) => $url});
  ${({ $rotation }) => ($rotation ? `transform: rotate(${$rotation});` : '')}
`;

export const IconSpan = styled.div.attrs<IconProps>(props => ({
  className: props.className ?? 'sc-icon'
}))`
  width: 24px;
  height: 24px;
  mask-size: contain;
  background-color: ${({ $isActive, theme, $color }) =>
    $isActive ? theme.brand?.color?.primary : $color ?? '#161C22'};
  -webkit-mask-image: url(${({ $url }) => $url});
  mask-image: url(${({ $url }) => $url});
  ${({ $rotation }) => ($rotation ? `transform: rotate(${$rotation});` : '')}
`;

type CloseIconProps = { $url?: string };
export const CloseIcon = styled(Icon).attrs<CloseIconProps>(({ theme }) => ({
  $url: '/portal/close.svg',
  $color: theme.colors.text.disabled
}))``;

export const LockImage = styled.img.attrs(({ src }) => ({
  src: src ?? '/portal/lock.svg'
}))`
  width: 100px;
  height: 104px;
`;
