import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { ThemeConsumer } from './ThemeProvider';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class DashboardRestricted extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <ThemeConsumer>
        {({ theme }) => (
          <div className="container" id="container">
            <div className="my-3 p-3 bg-white rounded box-shadow dashboard-item">
              <h2>{this.props.t('dashboardRestricted.title')}</h2>
              <hr />
              <p>
                {this.props.t('dashboardRestricted.body')} {theme?.companyName}{' '}
                {theme?.programName}.
              </p>
              <h3>
                <a href="/join">{this.props.t('dashboardRestricted.share')}</a>
              </h3>
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

DashboardRestricted.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(DashboardRestricted);
