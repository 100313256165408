import React from 'react';
import {
  FooterWrapper,
  LockupImg,
  LockupText,
  LockupWrapper
} from '../atoms/footer';
import MoleculeFooter from '../molecules/Footer';
import { HorizontalGap } from '../atoms/spacing';
import { useTheme } from 'styled-components';

type FooterProps = {
  fullWidth?: boolean;
};
function Footer({ fullWidth = true }: FooterProps) {
  const {
    screenAndPagesContentConfiguration: {
      profilePageContentConfiguration: config
    } = {}
  } = useTheme();

  return (
    <FooterWrapper $fullWidth={fullWidth}>
      <LockupWrapper>
        <LockupText>{config?.lockupText ?? 'Powered By'}</LockupText>
        <LockupImg
          src={config?.glookoLogo?.src ?? '/portal/glooko.svg'}
          alt={config?.glookoLogo?.alt}
        />
      </LockupWrapper>
      <HorizontalGap />
      <MoleculeFooter transparentBackground noPadding noGap />
    </FooterWrapper>
  );
}

export default Footer;
