import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useFeatureConfiguration from '../../hooks/useFeatureConfiguration';
import SideMenuItem from './SideMenuItem';

const validStatus = [2, 3, 4, 5, 6];
const noData = [3, 5];
function shouldShowSHRMenu(providers, shrStatus, isLoading) {
  let isValid = providers.length > 0 || isLoading;
  if (!isValid)
    isValid = validStatus.includes(shrStatus) && providers.length > 0;
  return isValid;
}

export default function SHRMenuItem(props) {
  const {
    closeSideMenu,
    url,
    shrStatusBadge,
    providers,
    shrStatus,
    isLoading,
    records: { selectedRequest } = {}
  } = props;
  const config = useFeatureConfiguration();
  const noSelectedRequest = !selectedRequest || _.isEmpty(selectedRequest);
  const link =
    noData.includes(shrStatus) || noSelectedRequest
      ? '/home/loading'
      : '/home/shr';

  return (
    <SideMenuItem
      onClick={closeSideMenu}
      url={url}
      title={config.sideMenuSHRLabel || 'Smart Health Record'}
      icon="fas fa-briefcase-medical"
      link={
        shouldShowSHRMenu(providers, shrStatus, isLoading)
          ? link
          : '/home/onboarding'
      }
      badge={shrStatusBadge}
      className="sub-menu"
      {...props}
    />
  );
}

SHRMenuItem.propTypes = {
  records: PropTypes.any,
  closeSideMenu: PropTypes.any,
  url: PropTypes.any,
  shrStatusBadge: PropTypes.any,
  providers: PropTypes.any,
  shrStatus: PropTypes.any,
  isLoading: PropTypes.any
};
