import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef
} from 'react';
import queryString from 'qs';
import ReactGA from 'react-ga';
import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle';

import { getUserStudyFromProgram } from './utils/cms.js';
import { storeReferral } from './utils/OneVueAPI';
import { useTheme } from './Components/ThemeProvider.js';
import Nav from './Components/Nav';
import Jumbotron from './Components/Jumbotron.js';
import Page404 from './Components/Page404';
import LandingPageProcess from './Components/LandingPageProcess.js';
import LandingPageSupport from './Components/LandingPageSupport.js';
import LandingPageBenefits from './Components/LandingPage/LandingPageBenefits';
import LandingPageFaqs from './Components/LandingPage/LandingPageFaqs';
import StickyFooter from './Components/StickyFooter/StickyFooter';
import HelpModule from './Components/HelpModule.js';

const App = props => {
  const {
    location,
    match: {
      params: { diseaseCode, programType }
    },
    is404,
    scrollContainerRef
  } = props;
  const { theme } = useTheme();
  const [isHomePage, setIsHomePage] = useState(true);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [landingPage, setLandingPage] = useState(theme.landingPage);
  const [referral, setReferral] = useState();
  const { programName, companyName } = theme;
  const scrollRef = useRef(null);

  const {
    jumbotronHeader: title,
    jumbotronDescription: description,
    joinButtonTitle: buttonTitle,
    jumbotronBackgroundImage,
    descriptionParagraph1,
    processTitle,
    process: processItems,
    faq,
    showHelpButton
  } = landingPage || {};

  const link = useMemo(
    () =>
      isHomePage ? '/' : `/join/${diseaseCode}/${programType}?r=${referral}`,
    [isHomePage, diseaseCode, programType, referral]
  );

  const clearStorageAndStyles = useCallback(() => {
    const footer = document.querySelector('footer.footer');
    if (footer) {
      footer.classList.remove('is-register');
    }
    localStorage.removeItem('register_step');
    localStorage.removeItem('register_state');
  }, []);

  useEffect(() => {
    (async () => {
      if (diseaseCode && programType) {
        const [{ fields }] = await getUserStudyFromProgram(
          `${diseaseCode}__${programType}`
        );
        setLandingPage(fields.landingPage.fields);
        setIsHomePage(false);
      } else {
        setIsHomePage(true);
        setLandingPage(theme.landingPage);
      }
      setIsLoadingContent(false);
    })();
  }, [diseaseCode, programType]);

  useEffect(() => {
    // set company title
    document.title = `${programName} | ${companyName}`;
  }, [programName, companyName]);

  useEffect(() => {
    const search = location.search.substr(1);
    let ref = queryString.parse(search).r;
    clearStorageAndStyles();
    if (diseaseCode) {
      ReactGA.event({
        category: 'User',
        action: 'Site hit: ' + diseaseCode
      });
    }

    if (ref) {
      storeReferral(ref);
      ReactGA.event({
        category: 'User',
        action: 'referral: ' + ref
      });
    } else {
      ref = window.sessionStorage.getItem('referral');
    }
    setReferral(ref);
    const url = window.location.pathname + window.location.search;
    localStorage.setItem('landing_url', url);
    ReactGA.pageview(url);
  }, []);

  const noTitle = useMemo(
    () => (
      <div>
        <Nav {...props} linkTo={link} isLanding />
        <div className="container">
          <div className="row justify-content-center vertical-center">
            <picture className="loading-logo-container">
              <img
                className="animated infinite pulse loading-logo col-2"
                src={theme.smallLogo?.src}
                alt={theme.smallLogo?.alt}
                style={{ objectFit: 'contain', height: 144 }}
              />
            </picture>
          </div>
        </div>
      </div>
    ),
    [diseaseCode, programType, referral]
  );

  const not404 = useMemo(() => {
    return (
      <div>
        <span id="scrollReference" ref={scrollRef} />
        <Nav {...props} linkTo="/" isLanding />
        <Jumbotron
          {...props}
          title={title}
          description={description}
          buttonTitle={buttonTitle}
          backgroundImageURL={jumbotronBackgroundImage?.fields?.file?.url}
          referral={referral}
          link={link}
          diseaseCode={diseaseCode}
          programType={programType}
          displayStoreLinks={theme.webDisplayStoreLinks}
        />
        <LandingPageBenefits
          {...props}
          diseaseCode={diseaseCode}
          programType={programType}
          content={descriptionParagraph1}
          scrollRef={scrollRef}
        />
        <hr className="landing-page-separator" />
        <LandingPageProcess
          {...props}
          processTitle={processTitle}
          items={processItems}
          buttonTitle={buttonTitle}
          diseaseCode={diseaseCode}
          programType={programType}
          link={link}
          scrollRef={scrollRef}
        />
        <hr className="landing-page-separator" />
        {faq && (
          <LandingPageFaqs
            {...props}
            content={faq}
            diseaseCode={diseaseCode}
            programType={programType}
            scrollRef={scrollRef}
          />
        )}
        {isHomePage && theme.isiConfiguration?.contentHTMLandCSS ? (
          <hr className="landing-page-separator" />
        ) : null}
        {isHomePage && theme.isiConfiguration?.contentHTMLandCSS ? (
          <StickyFooter scrollContainerRef={scrollContainerRef} />
        ) : null}
        <LandingPageSupport scrollRef={scrollRef} />
      </div>
    );
  }, [link, diseaseCode, programType, landingPage]);

  const fourOhFour = useMemo(() => {
    return (
      <div>
        <Nav linkTo={`/join?r=${referral}`} {...props} />
        <Page404 {...props} />
      </div>
    );
  }, [props]);

  const content = useMemo(() => {
    if (title === '') {
      return noTitle;
    }
    if (!is404) {
      return not404;
    }
    return fourOhFour;
  }, [title, is404, noTitle, not404, fourOhFour]);

  return (
    <>
      {!isLoadingContent && content}
      {showHelpButton && <HelpModule />}
    </>
  );
};

export default App;
