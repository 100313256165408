import { map } from 'lodash';
import { getEntries } from '../utils/cms';
import { createFetchActions, flattenModel } from './utils';

export const { types, actions } = createFetchActions('PRN_OPTIONS');

export function processPRNOptions(items) {
  return map(items, flattenModel);
}

export function fetchPrnOptions() {
  return async dispatch => {
    dispatch(actions.START());
    try {
      const items = await getEntries({
        content_type: 'prnOption',
        include: 10
      });
      dispatch(actions.SUCCESS(processPRNOptions(items)));
    } catch (error) {
      dispatch(actions.FAILURE(error));
    }
  };
}
