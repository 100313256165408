import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
class SideMenuItem extends Component {
  state = {
    active: false,
    displayChildren: false
  };

  componentDidMount() {
    if (
      this.props.location &&
      (this.props.location.pathname.includes('shr') ||
        this.props.location.pathname.includes('providers') ||
        this.props.location.pathname.includes('loading'))
    ) {
      this.setState({
        displayChildren: true
      });
    }
  }

  _isItemActive(link, useRegexp) {
    const {
      history: {
        location: { pathname }
      }
    } = this.props;
    if (useRegexp) {
      return link.test(pathname);
    }

    return pathname === link;
  }
  setActiveSubMenuItem = link => {
    let active = false;

    if (this.props.url.includes(link)) {
      // this.setState({
      active = true;
      // })
    } else if (
      (this.props.url.includes('loading') && link.includes('shr')) ||
      (this.props.url.includes('sync') && link.includes('providers'))
    ) {
      active = true;
    }
    return active;
  };
  _renderSubMenuItems = () => {
    const { subMenuItems } = this.props;

    const { displayChildren } = this.state;
    if (!displayChildren || !Array.isArray(subMenuItems)) {
      return null;
    }
    const components = subMenuItems.map((menuItem, index) => {
      const { title, link } = menuItem;

      const active = this.setActiveSubMenuItem(link || '/home/shr');
      return (
        <Link
          to={link || '/home/shr'}
          className={`sub-nav-container nav-item-container ${
            active ? 'active' : ''
          }`}
          onClick={this.props.onClick}
          key={index}
        >
          <li
            className={active ? 'sub-nav nav-item active' : 'nav-item sub-nav'}
            data-cy={`subnav-btn-${index}`}
          >
            <div className="row ">
              <div className="col-1" />
              <div className="col-9">{title}</div>
            </div>
          </li>
        </Link>
      );
    });

    return (
      <ul className="nav flex-column sidebar-item-children-wrapper">
        {components}
      </ul>
    );
  };
  _toggleChildren = () => {
    const { displayChildren } = this.state;
    this.setState({
      displayChildren: !displayChildren
    });
  };
  _hasChildren = () => {
    const { subMenuItems } = this.props;
    return Array.isArray(subMenuItems) && subMenuItems.length > 0;
  };
  checkActiveCondition = () => {
    return (
      (this.props.url.includes('loading') &&
        this.props.link.includes('onboarding')) ||
      (this.props.url.includes('providers') &&
        this.props.link.includes('onboarding')) ||
      (this.props.url.includes('shr') && this.props.link.includes('onboarding'))
    );
  };
  isHome = () => {
    const { url, link } = this.props;
    return url.endsWith(link) || this.checkActiveCondition();
  };
  renderHasChildren = (icon, title, active) => (
    <React.Fragment>
      <div
        onClick={() => this._toggleChildren()}
        className={active ? 'nav-item-container active' : 'nav-item-container'}
      >
        <li
          data-cy="shr-menu"
          className={`nav-item  ${active ? 'active' : ''}`}
        >
          <div className="row ">
            <div className="col-1">
              <i className={icon} />
            </div>
            <div className="col-9">
              <span className="sidenav-title">{title}</span>
              {this.props.badge && (
                <span className="badge-container">{this.props.badge}</span>
              )}
            </div>
          </div>
        </li>
      </div>
      {this._renderSubMenuItems()}
    </React.Fragment>
  );
  renderLink = (active, iconClassName) => (
    <Link
      className={active ? 'nav-item-container active' : 'nav-item-container'}
      to={this.props.link || '/home/loading'}
      onClick={this.props.onClick}
    >
      <li className={active ? 'nav-item active' : 'nav-item'}>
        <div className="row ">
          <div className="col-1">
            <i className={iconClassName} />
          </div>
          <div className="col-9">
            <span className="sidenav-title" data-cy={this.props.dataCy}>
              {this.props.title}
            </span>
            {this.props.badge && (
              <span className="badge-container">{this.props.badge}</span>
            )}
          </div>
        </div>
      </li>
    </Link>
  );
  render() {
    let active = false;
    active = this.isHome();
    if (this._hasChildren()) {
      const { icon, title } = this.props;
      return this.renderHasChildren(icon, title, active);
    }
    const iconClassName = this.props.icon.includes('fa')
      ? this.props.icon
      : `icon icon-icn-${this.props.icon}`;
    return this.renderLink(active, iconClassName);
  }
}
SideMenuItem.propTypes = {
  badge: PropTypes.object,
  dataCy: PropTypes.any,
  history: PropTypes.object,
  icon: PropTypes.string,
  link: PropTypes.string,
  location: PropTypes.object,
  onClick: PropTypes.func,
  subMenuItems: PropTypes.array,
  title: PropTypes.string,
  url: PropTypes.string
};
export default SideMenuItem;
