import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormInput from '../FormInput/FormInput';
import PolicyLabel from './PolicyLabel';
import PolicyModal from './PolicyModal';

function Policy({ policy, fieldName, accepted, onChange }) {
  const [isVisible, setIsVisible] = useState(false);
  const handleOpenModal = () => setIsVisible(true);
  const handleCloseModal = () => setIsVisible(false);
  return (
    <>
      <PolicyModal
        policy={policy}
        visible={isVisible}
        onClose={handleCloseModal}
        closeOnOutsideClick
      />
      <FormInput
        wrapperClassName="d-flex policy-input"
        inputWrapperClassName="p-1 align-content-start"
        labelClassName="m-0 px-2"
        inputID={fieldName}
        inputType="checkbox"
        label={<PolicyLabel policy={policy} onOpenModal={handleOpenModal} />}
        name={fieldName}
        value={fieldName}
        checked={accepted || false}
        onChange={onChange}
      />
    </>
  );
}

Policy.propTypes = {
  policy: PropTypes.any,
  fieldName: PropTypes.string,
  accepted: PropTypes.bool,
  onChange: PropTypes.func
};

export default Policy;
