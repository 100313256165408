import { cmsLocalesTypes as types } from '../actions/locales';
import { SET_USER_LOCALE } from '../constants/actionTypes';
import StateLoader from './StateLoader';

const stateLoader = new StateLoader();
const initialState = stateLoader.initializeState().cmsLocales;

export default function cmsLocalesReducer(state = initialState, action) {
  switch (action.type) {
    case types.START:
      return {
        ...state,
        didLoad: false,
        isLoading: true
      };
    case types.SUCCESS:
      return {
        ...state,
        locales: action.payload,
        didLoad: true,
        isLoading: false
      };
    case types.FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case types.CLEAR:
      return { ...initialState };
    case SET_USER_LOCALE:
      return {
        ...state,
        userLocale: action.payload
      };
    default:
      return state;
  }
}
