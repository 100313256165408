import React from 'react';
import ReactGA from 'react-ga';
import { useTheme } from '../ThemeProvider.js';

const MobileStoreLinks = () => {
  const { theme } = useTheme();
  const handleClick = (type, url) => () => {
    ReactGA.event({
      category: 'User',
      action: `Clicking on ${type}, ref: ${url}`
    });
  };
  return (
    <div className="list-group landing" data-cy="list-group-landing">
      <a
        href={theme?.appUrlAppStore}
        onClick={handleClick('App Store link', theme?.appUrlAppStore)}
        className="list-group-item list-group-item-action mobile-store-link"
        style={{ background: 'transparent', border: 'none', padding: 0 }}
      >
        <img
          src="/app-store-badge.png"
          className="my-2"
          alt="Download on the App Store"
          style={{ height: '100%', width: '100%', objectFit: 'contain' }}
        />
      </a>
      <a
        href={theme?.appUrlGooglePlay}
        onClick={handleClick('Google Play link', theme?.appUrlGooglePlay)}
        className="list-group-item list-group-item-action mobile-store-link"
        style={{ background: 'transparent', border: 'none', padding: 0 }}
      >
        <img
          src="/google-play-badge.png"
          className="img-fluid my-2"
          alt="Get it on Google Play"
          style={{ height: '100%', width: '100%', objectFit: 'contain' }}
        />
      </a>
    </div>
  );
};

export default MobileStoreLinks;
