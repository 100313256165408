import { useReducer } from 'react';
import { register } from '../../../utils/OneVueAPI';
import { mapToRegistrationPayload } from '../utils/mappers';

const STATUS = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  error: 'error'
};

const initialState = { status: STATUS.idle, error: null };

const reducer = (state, action) => {
  switch (action.type) {
    case 'start':
      return { ...state, status: STATUS.loading, error: null };
    case 'success':
      return {
        ...state,
        status: STATUS.success,
        screeningPassed: action.payload
      };
    case 'error':
      return { ...state, status: STATUS.error, error: action.payload };
    default:
      throw new Error('Unknown action: ' + action.type);
  }
};

export default function useRegistrationDataSubmission() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const registerUser = async (formSubmissionData, allQuestions) => {
    try {
      dispatch({ type: 'start' });
      const payload = mapToRegistrationPayload(
        formSubmissionData,
        allQuestions
      );
      const result = await register(payload);
      dispatch({ type: 'success', payload: result.screeningPassed });
    } catch (err) {
      dispatch({ type: 'error', payload: err?.message || err.toString() });
    }
  };

  return {
    registerUser,
    isLoading: state.status === STATUS.loading,
    isIdle: state.status === STATUS.idle,
    isSuccess: state.status === STATUS.success,
    screeningPassed: state.screeningPassed,
    error: state.error
  };
}
