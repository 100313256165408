import { find } from 'lodash';
import { useMemo } from 'react';
import { useAuthUser } from '../selectors/auth';
import usePRNOptions from './usePRNOptions';

export default function useUserPRNOption() {
  const { items, ...prnOptions } = usePRNOptions();
  const user = useAuthUser();
  const prnOption = useMemo(() => {
    return find(items, ({ prnOptionId }) => prnOptionId === user.prnOption);
  }, [user.prnOption, items]);
  return { prnOption, ...prnOptions };
}
