import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import SignedAuthItem from './SignedAuthItem';
import UnsignedAuthItem from './UnsignedAuthItem';
import useFeatureConfiguration from '../../../../hooks/useFeatureConfiguration';
import { getConsent } from '../../../../utils/OneVueAPI.js';

export default function ConsentList({
  signedAuths,
  unsignedAuths,
  authToken,
  userActions
}) {
  const config = useFeatureConfiguration();
  const [loadingIds, setLoadingIds] = useState([]);

  useEffect(() => {
    userActions.getConsents(authToken);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [userActions, authToken]);

  const clickConsent = event => {
    event.preventDefault();
    let url = event.currentTarget.getAttribute('data-url');

    if (url.includes('/pdf/')) {
      let id = event.currentTarget.getAttribute('data-id');
      setLoadingIds(ids => [...ids, id]);
      getConsent(id)
        .then(({ data }) => {
          window.open(URL.createObjectURL(data), '_blank');
        })
        .catch(() => {
          alert(config?.signedConsentPDFFailureMessage || 'File not found.');
        })
        .finally(() => {
          setLoadingIds(ids => ids.filter(i => i !== id));
        });
    } else {
      window.location = url;
    }
  };
  return (
    <div className="container center-container" id="container">
      <div className="my-3 card box-shadow dashboard-item">
        <div className="card-body inbox-card-container-header">
          <h2 className="">
            <i className="fas fa-file-alt" />{' '}
            {config.authorizationsPageTitle || 'My Authorizations'}
          </h2>
        </div>
        <div className="card-footer inbox-items-container">
          <div className="list-group inbox-card-item">
            {unsignedAuths.map(item => {
              return (
                <UnsignedAuthItem
                  title={item.title}
                  version={item.icfVersionNumber}
                  id={item.id}
                  key={item.id}
                  status={item.status}
                  onClick={clickConsent}
                />
              );
            })}
          </div>
          <hr />
          <div className="list-group inbox-card-item">
            {signedAuths.map(consent => {
              return (
                <SignedAuthItem
                  title={consent.protocolTitle}
                  version={consent.icfVersion}
                  id={consent.id}
                  key={consent.id}
                  onClick={clickConsent}
                  loading={loadingIds.includes(`${consent.id}`)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

ConsentList.propTypes = {
  signedAuths: PropTypes.any,
  userActions: PropTypes.object,
  unsignedAuths: PropTypes.any,
  authToken: PropTypes.string
};
