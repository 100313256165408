import React, { Component } from 'react';
import HelpModal from './HelpModal';
import CloseIcon from 'mdi-react/CloseIcon';
import ReactGA from 'react-ga';

const helpStyle = {
  position: 'fixed',
  bottom: '20px',
  right: '48px',
  zIndex: '1001',
  animationDelay: '2s'
};

const buttonStyle = {
  color: 'white',
  width: '64px',
  height: '64px',
  borderRadius: '50px',
  fontSize: '30px',
  lineHeight: '0.0',
  boxShadow: '0 0 3px rgb(25,25,25,0.5)',
  fontWeight: '200'
};

class HelpModule extends Component {
  state = {
    helpIcon: 'fa-question'
  };
  toggleHelpIcon = () => {
    this.state.helpIcon === 'fa-question'
      ? this.setState({ helpIcon: 'fa-times' }, () => {
          ReactGA.event({
            category: 'User',
            action: 'Clicked Help Button'
          });
        })
      : this.setState({ helpIcon: 'fa-question' });
  };
  render() {
    return (
      <div>
        <div id="helpButton" className="animated bounceInUp" style={helpStyle}>
          <button
            type="button"
            id="HelpButton"
            style={buttonStyle}
            className="btn btn-monarc-orange"
            data-bs-toggle="modal"
            onClick={this.toggleHelpIcon}
            data-bs-target="#helpModal"
          >
            {this.state.helpIcon !== 'fa-question' ? (
              <CloseIcon size={40} />
            ) : (
              <i className={`fas ${this.state.helpIcon}`} />
            )}
          </button>
        </div>
        <HelpModal onClick={this.toggleHelpIcon} />
      </div>
    );
  }
}

export default HelpModule;
