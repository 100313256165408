import { Study } from '@ts/models';
import { ColumnConfig } from 'theme';
import i18n from '../../i18n';

const columnConfigs: Array<ColumnConfig<Study>> = [
  {
    id: 'displayName',
    label: i18n.t('studyContent.columnConfigs.name'),
    sortable: true
  },
  {
    id: 'studyId',
    label: i18n.t('studyContent.columnConfigs.studyId'),
    sortable: true
  },
  {
    id: 'seatsLimit',
    label: i18n.t('studyContent.columnConfigs.seatsLimit'),
    sortable: true
  },
  { id: 'takenSeats', label: i18n.t('studyContent.columnConfigs.takenSeats') },
  {
    id: 'sites',
    label: i18n.t('studyContent.columnConfigs.sites'),
    withTooltip: true
  }
];

export default {
  columnConfigs,
  formFields: {
    displayName: {
      label: i18n.t('studyContent.formFields.displayName.label'),
      placeholder: i18n.t('studyContent.formFields.displayName.placeholder')
    },
    studyId: {
      label: i18n.t('studyContent.formFields.studyId.label'),
      placeholder: i18n.t('studyContent.formFields.studyId.placeholder')
    },
    seatsLimit: {
      label: i18n.t('studyContent.formFields.seatsLimit.label'),
      placeholder: i18n.t('studyContent.formFields.seatsLimit.placeholder')
    },
    sites: {
      label: i18n.t('studyContent.formFields.sites.label'),
      placeholder: i18n.t('studyContent.formFields.sites.placeholder')
    }
  },
  reasonForChangeModalHeaderTitle: i18n.t('studyContent.reasonModal.header'),
  reasonForChangeModalBodyText: i18n.t('studyContent.reasonModal.body'),
  reasonForChangeInputLabel: i18n.t('studyContent.reasonModal.inputLabel'),
  reasonForChangeInputPlaceholder: i18n.t(
    'studyContent.reasonModal.inputPlaceholder'
  ),
  reasonForChangeErrorMessage: i18n.t('studyContent.reasonModal.error'),
  reasonForChangeModalCancelButtonLabel: i18n.t(
    'studyContent.reasonModal.cancelBtn'
  ),
  reasonForChangeModalSubmitButtonLabel: i18n.t(
    'studyContent.reasonModal.submitBtn'
  ),
  deleteModalHeaderTitle: i18n.t('studyContent.deleteModal.header'),
  deleteModalBodyText: i18n.t('studyContent.deleteModal.body'),
  deleteReasonInputLabel: i18n.t('studyContent.deleteModal.inputLabel'),
  deleteReasonPlaceholder: i18n.t('studyContent.deleteModal.inputPlaceholder'),
  deleteModalCancelButtonLabel: i18n.t('studyContent.deleteModal.cancelBtn'),
  deleteModalDeleteButtonLabel: i18n.t('studyContent.deleteModal.deleteBtn')
};
