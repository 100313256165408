import { filter, matches } from 'lodash';

export function getNeedsToSign(consent, consents) {
  if (consent) {
    return (
      consents.length === 0 ||
      // user has signed a consent, checking that ICF version number match
      filter(consents, matches({ signed: false })).length >= 1
    );
  }
  return false;
}
