import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { upperFirst, find, reduce } from 'lodash';
import QueryString from 'qs';

import usePRNOptions from '../../hooks/usePRNOptions';
import { useTheme } from '../ThemeProvider';
import Nav from '../Nav';
import Policy from './Policy';
import { getReferral } from '../../utils/OneVueAPI';

function PoliciesPage({ match, history, _usePRNOptions = usePRNOptions }) {
  const {
    theme: { prnOptionRoleSelectionScreen },
    referToken
  } = useTheme();
  const { items, isLoading } = _usePRNOptions();
  const [user, setUser] = useState({});
  const prnOption = useMemo(() => {
    const { diseaseCode, programType } = match.params || {};
    return find(
      items,
      item =>
        item.disease.diseaseCode === diseaseCode &&
        item.program.programId === programType
    );
  }, [items, match.params]);
  const nextButtonDisabled = reduce(
    prnOption?.policies,
    (acc, { types, requestAcceptance }) => {
      const fieldName = `accepted${upperFirst(types)}`;
      if (!acc && requestAcceptance && !user[fieldName]) {
        acc = true;
      }
      return acc;
    },
    isLoading || false
  );
  const togglePolicyCheck = fieldName => () => {
    setUser(currentUser => ({
      ...currentUser,
      [fieldName]: !currentUser[fieldName]
    }));
  };
  const handleSubmit = event => {
    event.preventDefault();
    localStorage.setItem('policies_user', JSON.stringify(user));
    const search = location.search.substr(1);
    const referral = QueryString.parse(search).r || getReferral();
    const { diseaseCode, programType } = match.params || {};
    const token = referral || referToken;
    const nextPath = `/register/${diseaseCode}/${programType}?r=${token}`;
    history.push(nextPath);
  };

  return (
    <div className="policies-page">
      <Nav />
      <div className="container">
        <h2>
          {prnOptionRoleSelectionScreen?.policiesSectionLabel || 'Policies'}
        </h2>
        {prnOptionRoleSelectionScreen?.policiesSectionSubtitle ? (
          <h5>{prnOptionRoleSelectionScreen?.policiesSectionSubtitle}</h5>
        ) : null}
        {isLoading ? (
          <div id="loading-holder" className="mb-3">
            <div className="row justify-content-center">
              <div className="fa-3x text-center">
                <i className="fas fa-circle-notch fa-spin" />
              </div>
            </div>
          </div>
        ) : null}
        <form className="d-flex flex-column px-5" onSubmit={handleSubmit}>
          {prnOption?.policies?.map?.(policy => {
            const fieldName = `accepted${upperFirst(policy.types)}`;
            return (
              <Policy
                key={policy.sysId}
                policy={policy}
                fieldName={fieldName}
                accepted={user[fieldName]}
                onChange={togglePolicyCheck(fieldName)}
              />
            );
          })}
          <button
            type="submit"
            disabled={nextButtonDisabled}
            className="btn btn-primary align-self-end"
            data-testid="policy-page-submit-btn"
          >
            {prnOptionRoleSelectionScreen?.buttonLabel || 'Next'}
          </button>
        </form>
      </div>
    </div>
  );
}

PoliciesPage.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  _usePRNOptions: PropTypes.func
};

export default PoliciesPage;
