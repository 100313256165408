import React from 'react';
import PropTypes from 'prop-types';
import SideMenuItem from './SideMenuItem';
import useFeatureConfiguration from '../../hooks/useFeatureConfiguration';

export default function ProfileMenuItem(props) {
  const { closeSideMenu, url } = props;
  const config = useFeatureConfiguration();
  return (
    <SideMenuItem
      onClick={closeSideMenu}
      url={url}
      link="/home/profile"
      title={config.sideMenuProfileLabel || 'My Profile'}
      icon="fas fa-user-circle"
      {...props}
    />
  );
}

ProfileMenuItem.propTypes = {
  closeSideMenu: PropTypes.func,
  url: PropTypes.string
};
