import i18n from '../../i18n';

export default {
  pageTitle: i18n.t('participantProfileContent.pageTitle'),
  pageDescription: i18n.t('participantProfileContent.pageDescription'),
  idLabel: i18n.t('participantProfileContent.idLabel'),
  siteLabel: i18n.t('participantProfileContent.siteLabel'),
  trialPhaseLabel: i18n.t('participantProfileContent.trialPhaseLabel'),
  dateInformedConsentLabel: i18n.t(
    'participantProfileContent.dateInformedConsentLabel'
  ),
  primaryContactLabel: i18n.t('participantProfileContent.primaryContactLabel'),
  emailLabel: i18n.t('participantProfileContent.emailLabel'),
  phoneLabel: i18n.t('participantProfileContent.phoneLabel'),
  stateLabel: i18n.t('participantProfileContent.stateLabel'),
  dateOfBirthLabel: i18n.t('participantProfileContent.dateOfBirthLabel'),
  zipCodeLabel: i18n.t('participantProfileContent.zipCodeLabel'),
  accountCreationLabel: i18n.t(
    'participantProfileContent.accountCreationLabel'
  ),
  lastActiveLabel: i18n.t('participantProfileContent.lastActiveLabel'),
  appVersionLabel: i18n.t('participantProfileContent.appVersionLabel'),
  osVersionLabel: i18n.t('participantProfileContent.osVersionLabel'),
  editModalTitle: i18n.t('participantProfileContent.editModalTitle'),
  editModalDescription: i18n.t(
    'participantProfileContent.editModalDescription'
  ),
  editModalCancelButtonLabel: i18n.t(
    'participantProfileContent.editModalCancelButtonLabel'
  ),
  editModalSubmitButtonLabel: i18n.t(
    'participantProfileContent.editModalSubmitButtonLabel'
  ),
  editButtonLabel: i18n.t('participantProfileContent.editButtonLabel'),
  formFields: {
    participantId: {
      label: i18n.t('participantProfileContent.formFields.participantId.label')
    },
    email: {
      label: i18n.t('participantProfileContent.formFields.email.label')
    },
    site: { label: i18n.t('participantProfileContent.formFields.site.label') },
    phone: {
      label: i18n.t('participantProfileContent.formFields.phone.label')
    },
    trialPhase: {
      label: i18n.t('participantProfileContent.formFields.trialPhase.label')
    },
    state: {
      label: i18n.t('participantProfileContent.formFields.state.label')
    },
    primaryContact: {
      label: i18n.t('participantProfileContent.formFields.primaryContact.label')
    },
    zipCode: {
      label: i18n.t('participantProfileContent.formFields.zipCode.label')
    },
    dateInformedConsent: {
      label: i18n.t(
        'participantProfileContent.formFields.dateInformedConsent.label'
      )
    },
    dateOfBirth: {
      label: i18n.t('participantProfileContent.formFields.dateOfBirth.label')
    }
  },
  unlockParticipantLabel: i18n.t(
    'participantProfileContent.unlockParticipantLabel'
  ),
  unlockParticipantModalTitle: i18n.t(
    'participantProfileContent.unlockParticipantModalTitle'
  ),
  unlockParticipantReasonInputLabel: i18n.t(
    'participantProfileContent.unlockParticipantReasonInputLabel'
  ),
  unlockParticipantReasonInputPlaceholder: i18n.t(
    'participantProfileContent.unlockParticipantReasonInputPlaceholder'
  ),
  unlockParticipantSubmitLabel: i18n.t(
    'participantProfileContent.unlockParticipantSubmitLabel'
  ),
  unlockParticipantCancelLabel: i18n.t(
    'participantProfileContent.unlockParticipantCancelLabel'
  ),
  deleteButtonLabel: i18n.t('participantProfileContent.deleteButtonLabel'),
  deleteModalHeaderTitle: i18n.t(
    'participantProfileContent.deleteModal.header'
  ),
  deleteModalBodyText: i18n.t('participantProfileContent.deleteModal.body'),
  deleteReasonInputLabel: i18n.t(
    'participantProfileContent.deleteModal.inputLabel'
  ),
  deleteReasonPlaceholder: i18n.t(
    'participantProfileContent.deleteModal.inputPlaceholder'
  ),
  deleteModalCancelButtonLabel: i18n.t(
    'participantProfileContent.deleteModal.cancelBtn'
  ),
  deleteModalDeleteButtonLabel: i18n.t(
    'participantProfileContent.deleteModal.deleteBtn'
  ),
  resetPasswordButtonLabel: i18n.t(
    'participantProfileContent.resetPasswordButtonLabel'
  ),
  resetPasswordModalHeaderTitle: i18n.t(
    'participantProfileContent.resetPasswordModal.header'
  ),
  resetPasswordInputLabel: i18n.t(
    'participantProfileContent.resetPasswordModal.inputLabel'
  ),
  resetPasswordInputPlaceholder: i18n.t(
    'participantProfileContent.resetPasswordModal.inputPlaceholder'
  ),
  resetPasswordInputErrorMessage: i18n.t(
    'participantProfileContent.resetPasswordModal.inputErrorMessage'
  ),
  resetPasswordModalCancelButtonLabel: i18n.t(
    'participantProfileContent.resetPasswordModal.cancelBtn'
  ),
  resetPasswordModalSubmitButtonLabel: i18n.t(
    'participantProfileContent.resetPasswordModal.submitBtn'
  ),
  resetPasswordReasonModalHeaderTitle: i18n.t(
    'participantProfileContent.resetPasswordReasonModal.header'
  ),
  resetPasswordReasonInputLabel: i18n.t(
    'participantProfileContent.resetPasswordReasonModal.inputLabel'
  ),
  resetPasswordReasonPlaceholder: i18n.t(
    'participantProfileContent.resetPasswordReasonModal.inputPlaceholder'
  ),
  resetPasswordReasonModalCancelButtonLabel: i18n.t(
    'participantProfileContent.resetPasswordReasonModal.cancelBtn'
  ),
  resetPasswordReasonModalSubmitButtonLabel: i18n.t(
    'participantProfileContent.resetPasswordReasonModal.submitBtn'
  ),
  deactivateButtonLabel: i18n.t(
    'participantProfileContent.deactivateButtonLabel'
  ),
  deactivateModalHeaderTitle: i18n.t(
    'participantProfileContent.deactivateModal.header'
  ),
  deactivateModalBodyText: i18n.t(
    'participantProfileContent.deactivateModal.body'
  ),
  deactivateReasonInputLabel: i18n.t(
    'participantProfileContent.deactivateModal.inputLabel'
  ),
  deactivateReasonPlaceholder: i18n.t(
    'participantProfileContent.deactivateModal.inputPlaceholder'
  ),
  deactivateModalCancelButtonLabel: i18n.t(
    'participantProfileContent.deactivateModal.cancelBtn'
  ),
  deactivateModalSubmitButtonLabel: i18n.t(
    'participantProfileContent.deactivateModal.submitBtn'
  ),
  deactivateSuccessMessage: i18n.t(
    'participantProfileContent.deactivateSuccessMessage'
  ),
  activateButtonLabel: i18n.t('participantProfileContent.activateButtonLabel'),
  activateModalHeaderTitle: i18n.t(
    'participantProfileContent.activateModal.header'
  ),
  activateModalBodyText: i18n.t('participantProfileContent.activateModal.body'),
  activateReasonInputLabel: i18n.t(
    'participantProfileContent.activateModal.inputLabel'
  ),
  activateReasonPlaceholder: i18n.t(
    'participantProfileContent.activateModal.inputPlaceholder'
  ),
  activateModalCancelButtonLabel: i18n.t(
    'participantProfileContent.activateModal.cancelBtn'
  ),
  activateModalSubmitButtonLabel: i18n.t(
    'participantProfileContent.activateModal.submitBtn'
  ),
  activateSuccessMessage: i18n.t(
    'participantProfileContent.activateSuccessMessage'
  )
};
