import React from 'react';
import PropTypes from 'prop-types';

function ContentWrapper({ children, wrapperClassName }) {
  return (
    <div className="container center-container screening-questions">
      <div className="row justify-content-center">
        <div className={wrapperClassName || 'col-12 col-sm-12 col-md-12'}>
          {children}
        </div>
      </div>
    </div>
  );
}

ContentWrapper.propTypes = {
  children: PropTypes.node,
  wrapperClassName: PropTypes.string
};

export default ContentWrapper;
