import * as types from '../constants/actionTypes';
import request from '../utils/request';
import * as api from '../utils/OneVueAPI';
import { getConsents } from './user';

const loginStart = () => {
  return {
    type: types.LOGIN_START,
    meta: {
      crossTab: true
    }
  };
};

const loginSuccess = response => {
  return {
    type: types.LOGIN_SUCCESS,
    meta: {
      crossTab: true
    },
    response
  };
};

const loginFailure = error => {
  return {
    type: types.LOGIN_FAILURE,
    meta: {
      crossTab: true
    },
    error
  };
};

const logoutStart = () => {
  return {
    type: types.LOGOUT_START,
    meta: {
      crossTab: true
    }
  };
};

const logoutSuccess = fromIdle => {
  return {
    type: types.LOGOUT_SUCCESS,
    meta: {
      crossTab: true,
      fromIdle
    }
  };
};

const logoutFailure = error => {
  return {
    type: types.LOGOUT_FAILURE,
    meta: {
      crossTab: true
    },
    error
  };
};

export const login = (email, password) => {
  return async dispatch => {
    try {
      dispatch(loginStart());
      const response = await request({
        url: 'login',
        method: 'POST',
        data: { email: email, password: password }
      });

      const json = response.data;

      if (json.success !== false && json.sessionKey) {
        dispatch(loginSuccess(json));
        dispatch(getConsents(json.sessionKey));
      } else {
        dispatch(loginFailure('No session key provided'));
      }
    } catch (err) {
      dispatch(loginFailure(err));
    }
  };
};
export const logout = (accessToken, fromIdle) => {
  return async dispatch => {
    try {
      dispatch(logoutStart());

      await request({
        url: 'logout',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Session-Key': accessToken
        }
      });

      dispatch(logoutSuccess(fromIdle));
    } catch (err) {
      dispatch(logoutFailure(err));
    } finally {
      api.logout(!!accessToken);
    }
  };
};
