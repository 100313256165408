import React, { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/AuthFormInput';
import useLoginData from '@src/Components/Login/useLoginData';
import { some } from 'lodash';

import { RouteChildrenProps, Link } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import { useSelector } from 'react-redux';
import {
  ErrorAlert,
  InfoAlert,
  FormContainer,
  FormAlertContainer
} from '../atoms/containers';
import { DismissAlertBtn, GhostBtn, PrimaryButton } from '../atoms/buttons';
import { FormLogo } from '../atoms/images';
import { VerticalGap } from '../atoms/spacing';
import LocaleSelector from '../organisms/LocaleSelector';
import { Body1Div, H2, H4 } from '../atoms/typography';

export const LoginPage = ({ history }: RouteChildrenProps) => {
  const {
    formData: { email, password },
    errors,
    success,
    loginError,
    isSignInClicked,
    handleChange,
    handleDidClickSignIn: _handleDidClickSignIn,
    handleDismissError
  } = useLoginData(history);

  const loggedOutFromIdle = useSelector(
    (state: any) => state.auth.loggedOutFromIdle
  );

  const {
    screenAndPagesContentConfiguration: {
      sessionContentConfiguration: config
    } = {},
    mainLogo
  } = useTheme();

  const handleDidClickSignIn = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (isSignInClicked) return;
      _handleDidClickSignIn(event);
    },
    [isSignInClicked, _handleDidClickSignIn]
  );
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return;
      handleDidClickSignIn(event);
    },
    [handleDidClickSignIn]
  );

  const alert = useMemo(() => {
    if (loginError || window.location.search.includes('error')) {
      return (
        <ErrorAlert
          role="alert"
          data-cy="error-alert"
          data-testid="error-alert"
        >
          {config?.loginFailureMessage ||
            'The email or password you entered is incorrect, or your account has been locked due to multiple unsuccessful login attempts. Contact support for assistance.'}
          <DismissAlertBtn
            type="button"
            className="close"
            onClick={handleDismissError}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </DismissAlertBtn>
        </ErrorAlert>
      );
    }

    if (loggedOutFromIdle) {
      return (
        <InfoAlert>
          <div>
            <H4>{config?.loggedOutIdleTitle || 'Logged out.'}</H4>
            <Body1Div>
              {config?.loggedOutIdleMessage ||
                'Your account has been logged out due to inactivity. Please login again to access the portal.'}
            </Body1Div>
          </div>
        </InfoAlert>
      );
    }

    return null;
  }, [
    loginError,
    loggedOutFromIdle,
    config?.loggedOutIdleMessage,
    config?.loggedOutIdleTitle,
    config?.loginFailureMessage,
    handleDismissError
  ]);

  const validated = useMemo(() => {
    if (!email || !password) return false;
    if (some(errors, Boolean)) return false;
    return true;
  }, [email, password, errors]);

  return (
    <UnauthenticatedFormContainer>
      <LocaleSelector />
      <FormAlertContainer>{alert}</FormAlertContainer>
      <FormLogo alt={mainLogo.alt} src={mainLogo.src} />
      <FormContainer>
        <H2 $align="center">{config?.loginTitle || 'Log in'}</H2>
        <FormInput
          label={config?.loginEmailField?.label || 'Email*'}
          inputType="text"
          inputID="email"
          data-testid="email-input"
          placeholder={
            config?.loginEmailField?.placeholder || 'you@example.com'
          }
          value={email}
          required={true}
          onChange={handleChange}
          invalidFeedback={
            config?.loginEmailField?.errorMessage || 'Please enter your email'
          }
          errors={errors}
          success={success}
        />
        <VerticalGap $gap={24} />
        <FormInput
          label={config?.loginPasswordField?.label || 'Password*'}
          showLabel={config?.loginPasswordField?.showLabel}
          hideLabel={config?.loginPasswordField?.hideLabel}
          inputType="password"
          inputID="password"
          data-testid="password-input"
          placeholder={config?.loginPasswordField?.placeholder || ''}
          value={password}
          required={true}
          onChange={handleChange}
          invalidFeedback={
            config?.loginPasswordField?.errorMessage ||
            'Please enter a password that is at least 8 characters long with at least one letter and one number'
          }
          errors={errors}
          success={success}
          onKeyUp={handleKeyUp}
        />
      </FormContainer>
      <VerticalGap $gap={40} />
      <PrimaryButton
        data-testid="loginform-submit-btn"
        onClick={handleDidClickSignIn}
        type="button"
        disabled={!validated}
        $minWidth="160px"
      >
        {isSignInClicked ? (
          <i className="fas fa-circle-notch fa-spin" />
        ) : (
          config?.signInButton || 'Sign in'
        )}
      </PrimaryButton>
      <Link
        to={{
          pathname: '/forgot-password',
          state: {
            email: email
          }
        }}
      >
        <GhostBtn>{config?.forgotPasswordText || 'Forgot Password?'}</GhostBtn>
      </Link>
    </UnauthenticatedFormContainer>
  );
};
