import React, { useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import PortalFooter from './ts/components/organisms/Footer';
import Footer from './ts/components/molecules/Footer';
import { ThemeProvider } from './Components/ThemeProvider';
import Navigation from './navigation';
import config from './config/config';
import { useLocation } from 'react-router-dom';
import { portalFooterPaths } from './ts/constants/footer';

export default function Root() {
  const scrollContainerRef = useRef(null);

  const { search, pathname } = useLocation();

  useEffect(() => {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_TAG_ID);
  }, []);

  const params = new URLSearchParams(search);

  const isPortal = pathname.startsWith('/portal');
  const isPortalFooter = portalFooterPaths.some(footerPath =>
    pathname.startsWith(footerPath)
  );
  const mainWrapperStyles = {
    paddingBottom: '0',
    height: 'calc(100vh - 65px)',
    overflow: 'auto'
  };
  if (isPortal || isPortalFooter) {
    mainWrapperStyles.height = '100vh';
  }

  const renderFooterComponent = () => {
    if (isPortal) return null;
    if (isPortalFooter) return <PortalFooter />;
    return <Footer />;
  };

  return (
    <ThemeProvider>
      <div
        id="mainContainer"
        className={params.has('mobileAppDisplay') ? 'mobile-app-transform' : ''}
      >
        <div
          ref={scrollContainerRef}
          className="container-fluid px-0 main"
          style={mainWrapperStyles}
        >
          <Navigation scrollContainerRef={scrollContainerRef} />
        </div>
        {renderFooterComponent()}
      </div>
    </ThemeProvider>
  );
}
