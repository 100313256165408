import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ButtonOutline from '../ButtonOutline.js';
import ExitToAppIcon from 'mdi-react/ExitToAppIcon';

import { useTheme } from '../ThemeProvider.js';
import LogInButton from './LogInButton.js';
import LocalePicker from '../LocalePicker';

export default function LogOutButton({
  isMobile,
  profileSession,
  needsToSign,
  logout
}) {
  const { theme: { navBarHomeButton, navBarSignOutButton } = {} } = useTheme();
  const isLogin = window.location.pathname.includes('/login');
  let linkTo = needsToSign ? '/home/onboarding' : '/home';
  if (!profileSession?.confirmed) {
    linkTo = '/confirm_email';
  }

  if (isLogin) {
    return <LogInButton isMobile={isMobile} />;
  }
  if (isMobile) {
    return (
      <div className="float-right btn-container">
        <LocalePicker isMobile />
        <div style={{ display: 'inline-block', paddingBlockStart: 6 }}>
          <ExitToAppIcon color="#80868B" onClick={logout} />
        </div>
      </div>
    );
  }
  return (
    <div className="d-flex float-right">
      <LocalePicker />
      <Link to={linkTo}>
        <ButtonOutline
          title={navBarHomeButton || 'Home'}
          dataCy={'home-btn'}
          buttonStyle="monarc-orange"
        />
      </Link>
      <ButtonOutline
        title={navBarSignOutButton || 'Sign Out'}
        buttonStyle="dark"
        dataCy={'sign-out-btn'}
        action={logout}
      />
    </div>
  );
}

LogOutButton.propTypes = {
  isMobile: PropTypes.bool,
  profileSession: PropTypes.object,
  needsToSign: PropTypes.bool,
  logout: PropTypes.func
};
