import React from 'react';
import PropTypes from 'prop-types';

function Footer({ label, isDisabled, onSubmit }) {
  return (
    <div className="footer">
      <button
        type="submit"
        disabled={isDisabled}
        className={`btn btn-lg btn-primary ${isDisabled ? 'disabled' : ''}`}
        onClick={onSubmit}
      >
        {label}
      </button>
    </div>
  );
}

Footer.propTypes = {
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Footer;
