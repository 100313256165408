import { MiddlewareAPI, Action } from '@reduxjs/toolkit';
import { logout } from '@src/actions/auth';
import { AppDispatch, RootState } from '@ts/services/store';

type RejectedQueryAction = Action<string> & {
  error?: { message: string };
  payload?: { status: number; data?: { message: string } };
};
const sessionExpired = ({
  dispatch,
  getState
}: MiddlewareAPI<AppDispatch, RootState>) => (next: AppDispatch) => (
  action: RejectedQueryAction
) => {
  if (
    action.type === 'api/executeQuery/rejected' &&
    action.error?.message === 'Rejected' &&
    action.payload?.status === 401 &&
    action.payload.data?.message === 'Authentication required'
  ) {
    dispatch(logout(getState().auth.authToken));
  }
  return next(action);
};

export default sessionExpired;
