import useUserPRNOption from './useUserPRNOption';

function createSingleSentenceDocument(sentence) {
  return {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            data: {},
            marks: [],
            value: sentence
          }
        ]
      }
    ]
  };
}

export const defaultConfiguration = {
  title: 'Screening Questions',
  submitLabel: 'Submit',
  submitErrorMessage: 'There was an error submitting your answers!',
  qualificationMessage: createSingleSentenceDocument('You qualified!'),
  disqualificationMessage: createSingleSentenceDocument("You don't qualify."),
  continueLabel: 'Continue'
};

export default function useScreeningQuestionsConfiguration() {
  const { prnOption, refetch, isLoading, didLoad, error } = useUserPRNOption();
  const registrationPage = prnOption?.registrationPage;

  return {
    userInformation: registrationPage?.userInformation || {},
    questions: registrationPage?.screeningQuestions || [],
    configuration: {
      ...defaultConfiguration,
      ...(registrationPage?.screeningQuestionsConfiguration || {})
    },
    refetch,
    isLoading,
    didLoad,
    error
  };
}
