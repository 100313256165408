import React from 'react';
import PropTypes from 'prop-types';
import {
  USER_CAREGIVER,
  USER_DOCTOR,
  USER_PATIENT
} from '../../constants/userTypes';
import AuthorizationsMenuItem from './AuthorizationsMenuItem';
import SideMenuItem from './SideMenuItem';
import PatientMenuItems from './PatientMenuItems';

export default function UserMenuItems(props) {
  const {
    profileSession: {
      user: { userType }
    },
    closeSideMenu,
    url
  } = props;
  switch (userType) {
    case USER_DOCTOR:
      return (
        <SideMenuItem
          onClick={closeSideMenu}
          url={url}
          link="/dashboard/patients/list"
          title="My Patients"
          icon="group"
        />
      );
    case USER_CAREGIVER:
      return <AuthorizationsMenuItem {...props} />;
    case USER_PATIENT:
      return <PatientMenuItems {...props} />;
    default:
      return null;
  }
}

UserMenuItems.propTypes = {
  url: PropTypes.string,
  closeSideMenu: PropTypes.any,
  profileSession: PropTypes.any
};
