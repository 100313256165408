import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Paginator from '../shared/Pagination';
import PropTypes from 'prop-types';
import { getPageCondition } from './getRecordDetails';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as recordActions from '../../actions/records';

const HEADER = ['Name', 'Facility', 'Location', 'Sync Status', 'View Records'];

class ProvidersTable extends Component {
  state = {
    rowsPerPage: 10,
    filteredData: [],
    filtered: false,
    page: 1
  };
  filterData = oldProps => {
    const { filteredData } = this.state;
    const { jsonData, searchText } = this.props;
    const { jsonData: oldJsonData, searchText: oldSearchText } = oldProps;
    const isFirstRender = isEmpty(filteredData) && jsonData !== oldJsonData;
    const hasSearchTextChanged = searchText !== oldSearchText;
    const hasListSizeChanged = _.size(oldJsonData) !== _.size(jsonData);

    if (isFirstRender || hasSearchTextChanged || hasListSizeChanged) {
      let body = jsonData;
      if (searchText) body = this.filterRecords(body);

      this.setState({
        filteredData: _.sortBy(body, item => item.portalName),
        filtered: true,
        page: 1
      });
    }
  };
  componentDidUpdate(oldProps) {
    this.filterData(oldProps);
  }
  componentDidMount = () => {
    if (this.props.isUserIndex) {
      this.setState({
        filteredData: _.sortBy(this.props.jsonData, item => item.portalName),
        filtered: true,
        page: 1,
        loaded: true
      });
    }
  };
  setPage = page => this.setState({ page });
  renderHeader = () => {
    return (
      <thead className="table__header">
        <tr>
          {HEADER.map((item, index) => {
            return <th key={index}>{item}</th>;
          })}
        </tr>
      </thead>
    );
  };
  getIncludedValue = (headerItem, rowIndex) => {
    const entity = headerItem.tagName.slice(0, -3);
    const { jsonData } = this.props;

    const { relationships } = jsonData.data
      ? jsonData.data[rowIndex]
      : jsonData[rowIndex];
    const entityData = relationships[entity].data;

    const element = _.find(jsonData.included, obj => {
      return obj.type === entityData.type && obj.id === entityData.id;
    });

    if (Array.isArray(headerItem.includedTagName)) {
      return headerItem.includedTagName.map(tag => element[tag]).join(' ');
    }

    return element[headerItem.includedTagName];
  };
  checkForRowHeaderItem = (headerItem, formattedValue, bodyItem) => {
    let isFormatted;
    if (headerItem.linkTo && formattedValue !== '--') {
      if (headerItem.linkTo === 'tel') {
        formattedValue = (
          <a href={`tel:${formattedValue.replace(/[{()}]/g, '')}`}>
            {formattedValue}
          </a>
        );
        isFormatted = true;
      } else {
        const params = headerItem.linkTo.split('/:').splice(1);

        const linkUrl = params.reduce((accumulator, currentValue) => {
          const replace = ':' + currentValue;
          const regularExpression = new RegExp(replace, 'g');
          return accumulator.replace(regularExpression, bodyItem[currentValue]);
        }, headerItem.linkTo);

        formattedValue = <Link to={linkUrl}>{formattedValue}</Link>;
        isFormatted = true;
      }
    }
    return { isFormatted, formattedValue };
  };
  rowElement = (headerItem, bodyItem, value, rowIndex, columnIndex) => {
    let formattedValue = value,
      isFormatted;
    if (this.props.isIncluded && headerItem.tagName) {
      formattedValue = this.getIncludedValue(headerItem, rowIndex);
      isFormatted = true;
    }
    const checkedValue = this.checkForRowHeaderItem(
      headerItem,
      formattedValue,
      bodyItem
    );
    formattedValue = checkedValue.formattedValue;
    isFormatted = checkedValue.isFormatted;
    return (
      <td
        key={columnIndex}
        className={`providers-td-${columnIndex} ${headerItem.className || ''}`}
        onClick={() => {
          return headerItem.onClick ? headerItem.onClick(bodyItem) : null;
        }}
        data-bs-label={headerItem.displayName}
      >
        {headerItem !== 'View Records' ? (
          <div>{isFormatted ? formattedValue : value}</div>
        ) : value !== '' ? (
          <Link to="#" onClick={event => this.viewRecords(event, value)}>
            View
          </Link>
        ) : (
          ''
        )}
      </td>
    );
  };
  viewRecords = (event, requestId) => {
    const { recordActions } = this.props;

    recordActions.setSelectedRequest(requestId);
    this.props.history.push('/home/shr');
  };
  getBodyItemValue = (headerItem, rowElem) => {
    switch (headerItem) {
      case 'Name':
        return rowElem.portalName;
      case 'Facility':
        return rowElem.facilityName;
      case 'Location':
        return rowElem.location;
      case 'Sync Status':
        return rowElem.status;
      case 'View Records':
        return rowElem.status == 'DOWNLOADED' ? rowElem.id : '';
      default:
        return '';
    }
  };
  renderBodyItems = (header, rowElem, bodyItem, rowIndex) => {
    return header.map((headerItem, columnIndex) => {
      const extraColumnCondition =
        this.props.extraColumn && columnIndex === header.length - 1;

      if (extraColumnCondition) return null;
      const value = this.getBodyItemValue(headerItem, rowElem);

      return this.rowElement(
        headerItem,
        bodyItem,
        value,
        rowIndex,
        columnIndex
      );
    });
  };
  renderBodyPlaceholder = () => {
    let rows = [];
    const columnArray = Array(HEADER.length).fill('');
    for (let rowIndex = 0; rowIndex < 3; rowIndex++) {
      rows.push(
        <tr key={rowIndex}>
          {columnArray.map((item, index) => (
            <td key={index}>
              <div className="ph-item">
                <div className={`ph-col-${12 / columnArray.length} big`}>
                  <div className="ph-row">
                    <div className="ph-col-6 big" />
                  </div>
                </div>
              </div>
            </td>
          ))}
        </tr>
      );
    }

    return rows;
  };
  filterRecords = body =>
    body.filter(item => {
      const lookUpItem =
        item.attributes.name ||
        `${item.attributes.first_name} ${item.attributes.last_name}`;
      return lookUpItem.includes(this.props.searchText);
    });

  renderBody = () => {
    const { extraColumn } = this.props;
    const { filteredData: body, page, rowsPerPage } = this.state;

    return body.map((bodyItem, rowIndex) => {
      if (!getPageCondition(rowIndex, page, rowsPerPage)) return null;

      return (
        <tr key={rowIndex}>
          {this.renderBodyItems(HEADER, bodyItem, bodyItem, rowIndex)}
          {extraColumn ? extraColumn(bodyItem) : null}
        </tr>
      );
    });
  };
  renderPagination = isLoading =>
    !isLoading && (
      <Paginator
        data={this.state.filteredData}
        setPage={this.setPage}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.page}
        {...this.props}
      />
    );
  renderTableBody = filteredData => {
    return !isEmpty(filteredData) ? (
      <tbody>{this.renderBody()}</tbody>
    ) : (
      <tbody>
        <tr>
          <td>No Records</td>
          <td />
          <td />
        </tr>
      </tbody>
    );
  };
  render() {
    const { isLoading } = this.props;
    const { filteredData } = this.state;
    return (
      <div className="table-responsive providers-table">
        <table
          className={`${
            isEmpty(filteredData) && !isLoading ? 'no-records ' : ''
          }table table--responsive p-3 m-3`}
        >
          {this.renderHeader()}
          {!isLoading ? (
            this.renderTableBody(filteredData)
          ) : (
            <tbody>{this.renderBodyPlaceholder()}</tbody>
          )}
        </table>
        {this.renderPagination(isLoading)}
      </div>
    );
  }
}
ProvidersTable.propTypes = {
  history: PropTypes.object,
  jsonData: PropTypes.array,
  searchText: PropTypes.string,
  isUserIndex: PropTypes.bool,
  isIncluded: PropTypes.bool,
  extraColumn: PropTypes.func,
  isLoading: PropTypes.bool,
  records: PropTypes.object,
  recordActions: PropTypes.object
};

function mapStateToProps({ records }) {
  return {
    records
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recordActions: bindActionCreators(recordActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProvidersTable);
