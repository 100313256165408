import React from 'react';
import PropTypes from 'prop-types';

const UnsignedAuthItem = ({ id, status, onClick, title, version }) => (
  <div
    name="inboxItem"
    data-id={id}
    data-testid={id}
    data-status={status}
    data-url={'/home/authorizations/sign/' + id}
    onClick={onClick}
    className="list-group-item list-group-item-action inbox-card-item-list flex-column align-items-start mb-2"
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-11 col-md-11">
          <div className="w-100">
            <h5 className="mb-1 inbox-item-title">{title}</h5>
            <div className="text-muted">{'Version ' + version}</div>
          </div>
          <i className="fas fa-pen-nib" /> Sign
        </div>
      </div>
    </div>
  </div>
);

UnsignedAuthItem.propTypes = {
  id: PropTypes.any,
  onClick: PropTypes.any,
  status: PropTypes.any,
  title: PropTypes.any,
  version: PropTypes.any
};
export default UnsignedAuthItem;
