import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import PropTypes from 'prop-types';

class SelectInput extends Component {
  state = {
    options: []
  };

  setOptions = () => {
    const { inputID, values, options: opts = [] } = this.props;
    const options = opts.map((option, i) => ({
      value: values[i],
      label: option,
      inputID
    }));

    this.setState({ options });
  };

  componentDidMount = () => {
    this.setOptions();
  };

  componentDidUpdate = prevProps => {
    if (!_.isEqual(prevProps.options, this.props.options)) {
      this.setOptions();
    }
  };

  render() {
    const {
      inputID,
      onChange,
      required,
      selectedValue,
      placeholder,
      errors,
      success
    } = this.props;

    const isError = errors?.[inputID];
    const isSuccess = success?.[inputID];
    const className = `custom-select d-block w-100 ${
      isError ? 'is-invalid' : ''
    } ${isSuccess ? 'is-valid' : ''}`.trim();

    return (
      <Select
        isSearchable={true}
        className={className}
        inputId={inputID}
        options={this.state.options}
        onChange={onChange}
        required={required}
        placeholder={placeholder || ''}
        defaultValue={
          selectedValue
            ? {
                label: selectedValue,
                value: selectedValue,
                inputID
              }
            : null
        }
      />
    );
  }
}

SelectInput.propTypes = {
  errors: PropTypes.any,
  inputID: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.any,
  options: PropTypes.any,
  required: PropTypes.any,
  selectedValue: PropTypes.any,
  success: PropTypes.any,
  values: PropTypes.any
};
export default SelectInput;
