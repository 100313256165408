import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import FormInput from '../FormInput/FormInput';
import { mapQuestionsToFormInputProps } from './mappers';

const UserQuestionsForm = ({
  control,
  errors,
  success,
  questions,
  inputIdPrefix
}) => {
  const handleDefaultChange = (event, onChange) => {
    const value = event.target ? event.target.value : event.value;
    onChange(value);
  };
  const handleDateChange = (date, onChange) => {
    onChange(date);
  };

  const formInputProps = useMemo(() => {
    return mapQuestionsToFormInputProps(questions, { inputIdPrefix });
  }, [questions, inputIdPrefix]);

  return (
    <div className="step-container step-divider">
      {formInputProps.map(
        ({
          label,
          inputID,
          invalidFeedback,
          placeholder,
          inputType,
          ...restProps
        }) => {
          return (
            <div className="form-input-container" key={inputID}>
              <Controller
                name={inputID}
                control={control}
                render={({ field: { value, onBlur, onChange } }) => {
                  return (
                    <FormInput
                      inputID={inputID}
                      label={label}
                      value={value || ''}
                      inputType={inputType}
                      placeholder={placeholder}
                      onBlur={onBlur}
                      onChange={val => {
                        inputType === 'date'
                          ? handleDateChange(val, onChange)
                          : handleDefaultChange(val, onChange);
                      }}
                      success={success}
                      errors={errors}
                      invalidFeedback={invalidFeedback}
                      {...restProps}
                    />
                  );
                }}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

UserQuestionsForm.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string
    }).isRequired
  ).isRequired,
  success: PropTypes.shape({}).isRequired,
  control: PropTypes.any,
  questions: PropTypes.array,
  inputIdPrefix: PropTypes.string
};

export default UserQuestionsForm;
