import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/auth';
import Nav from '../Nav';
import SideMenu from '../SideMenu/SideMenu';
class BaseContainer extends Component {
  state = {
    page: '',
    type: '',
    inboxLoading: true,
    inboxUnreadItems: [],
    inboxReadItems: [],
    inboxLoadingError: false,
    unsignedAuths: [],
    signedAuths: [],
    shrStatus: null,
    showSideMenuMobile: false,
    shouldRedirect: false
  };
  _validateSession() {
    const { profileSession: session } = this.props;
    if (!session) this.props.actions.logout();
    if (!isEmpty(session.user.permissions)) {
      window.location.replace('/portal');
    }
  }
  setInbox = () => {
    const { inboxUnreadItems, inboxReadItems } = this.state;
    if (
      inboxUnreadItems.length + inboxReadItems.length !==
      this.props.inbox.length
    ) {
      var unreadArray = [];
      var readArray = [];
      this.props.inbox.forEach(function(s) {
        if (s.inbox.status === 'U') {
          unreadArray.push(s);
        } else if (s.inbox.status === 'R') {
          readArray.push(s);
        }
      });
      this.setState({
        inboxUnreadItems: unreadArray,
        inboxReadItems: readArray,
        inboxLoading: false,
        inboxLoadingError: false
      });
    }
  };
  setAuths = () => {
    const { consents } = this.props;
    let unsigned = [];
    let signed = [];
    consents.map(consent => {
      if (consent.signed === false) {
        unsigned.push(consent);
      } else {
        signed.push(consent);
      }
      return null;
    });
    if (unsigned.length > 0) {
      ReactGA.event({
        category: 'User',
        action: 'Unsigned Authorization Views',
        value: unsigned.length
      });
    }
    if (signed.length > 0) {
      ReactGA.event({
        category: 'User',
        action: 'Signed Authorization Views',
        value: signed.length
      });
    }
    this.setState({
      unsignedAuths: unsigned,
      signedAuths: signed
    });
  };
  componentDidMount() {
    this._validateSession();
    this.setInbox();
    this.setAuths();
  }
  componentDidUpdate(prevProps) {
    this.setInbox();
    if (prevProps.consents !== this.props.consents) {
      this.setAuths();
    }
  }
  toggleSideMenuMobile = () => {
    this.setState({ showSideMenuMobile: !this.state.showSideMenuMobile });
  };
  render() {
    const { inboxUnreadItems } = this.state;
    let unreadItemsCount = inboxUnreadItems ? inboxUnreadItems.length : 0;

    return (
      <div>
        <Nav
          linkTo="/home"
          {...this.props}
          className="dashboard-nav"
          toggleSideMenuMobile={this.toggleSideMenuMobile}
        />
        <div className="container-fluid dashboard-container">
          <div className="row">
            <SideMenu
              isMobile={this.state.showSideMenuMobile}
              url={this.props.match.url}
              unsignedAuthsCount={this.state.unsignedAuths.length}
              unreadItemsCount={unreadItemsCount}
              shrStatus={this.state.shrStatus}
              toggleSideMenuMobile={this.toggleSideMenuMobile}
              {...this.props}
            />
            <div className="mt-4 col-md-9 dashboard-container py-4">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  auth,
  user: {
    inbox: {
      inbox,
      isLoading: isLoadingInbox,
      loadingError: inboxLoadingError
    },
    consents: { consents }
  }
}) {
  return {
    profileSession: auth.profileSession,
    inbox,
    isLoadingInbox,
    inboxLoadingError,
    consents
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

BaseContainer.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.any,
  inbox: PropTypes.any,
  consents: PropTypes.any,
  match: PropTypes.any,
  profileSession: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseContainer);
