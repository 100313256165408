import { isArray, isObject, mapValues } from 'lodash';
import { createFetchActionTypes } from '../constants/actionTypes';

export function createFetchActions(name) {
  const types = createFetchActionTypes(name);
  const actions = mapValues(types, type => payload => ({
    type,
    payload
  }));
  return {
    types,
    actions
  };
}

export const flattenAsset = asset => {
  const description = asset?.fields?.description;
  const url = asset?.fields?.file?.url;
  const contentType = asset?.fields?.file?.contentType;
  const fileSize = asset?.fields?.file?.details?.size;
  const width = asset?.fields?.file?.details?.image?.width;
  const height = asset?.fields?.file?.details?.image?.height;
  return {
    contentType,
    fileSize,
    alt: description,
    src: `https:${url}`,
    width,
    height
  };
};

export function flattenModel(model, options = { includeSysVersion: false }) {
  if (!model?.sys) return model;
  let fields = {};
  if (model.sys.type === 'Asset') {
    fields = flattenAsset(model);
  } else if (model.sys.type === 'Entry') {
    fields = mapValues(model.fields, field => {
      if (isArray(field)) {
        return field.map(entry => flattenModel(entry, options));
      }
      if (isObject(field)) {
        return flattenModel(field);
      }
      return field;
    });
  }
  return {
    sysId: model.sys.id,
    ...(options.includeSysVersion ? { sysVersion: model.sys.revision } : {}),
    ...fields
  };
}
