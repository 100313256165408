import states from '../../constants/states';

export const USER_INFORMATION_KEYS = {
  firstNameField: 'firstNameField',
  lastNameField: 'lastNameField',
  birthDateField: 'birthDateField',
  diagnosisDateField: 'diagnosisDateField',
  phoneField: 'phoneField',
  zipCodeField: 'zipCodeField',
  stateField: 'stateField',
  emailField: 'emailField',
  passwordField: 'passwordField',
  confirmPasswordField: 'confirmPasswordField'
};

export const INPUT_ID_KEYS = {
  firstName: 'firstName',
  lastName: 'lastName',
  birthday: 'birthday',
  diagnosisDate: 'diagnosisDate',
  phoneNumber: 'phoneNumber',
  zipCode: 'zipCode',
  state: 'state',
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword'
};

export const FIELDS_CONFIG_MAPPING = {
  [USER_INFORMATION_KEYS.firstNameField]: {
    inputID: INPUT_ID_KEYS.firstName,
    inputType: 'text',
    maxLength: 40
  },
  [USER_INFORMATION_KEYS.lastNameField]: {
    inputID: INPUT_ID_KEYS.lastName,
    inputType: 'text',
    maxLength: 40
  },
  [USER_INFORMATION_KEYS.birthDateField]: {
    inputID: INPUT_ID_KEYS.birthday,
    inputType: 'date'
  },
  [USER_INFORMATION_KEYS.diagnosisDateField]: {
    inputID: INPUT_ID_KEYS.diagnosisDate,
    inputType: 'date'
  },
  [USER_INFORMATION_KEYS.phoneField]: {
    inputID: INPUT_ID_KEYS.phoneNumber,
    inputType: 'tel',
    mask: '(999) 999-9999'
  },
  [USER_INFORMATION_KEYS.zipCodeField]: {
    inputID: INPUT_ID_KEYS.zipCode,
    inputType: 'number',
    mask: '99999'
  },
  [USER_INFORMATION_KEYS.stateField]: {
    inputID: INPUT_ID_KEYS.state,
    inputType: 'select',
    options: states,
    values: states
  },
  [USER_INFORMATION_KEYS.emailField]: {
    inputID: INPUT_ID_KEYS.email,
    inputType: 'text'
  },
  [USER_INFORMATION_KEYS.passwordField]: {
    inputID: INPUT_ID_KEYS.password,
    inputType: 'password'
  },
  [USER_INFORMATION_KEYS.confirmPasswordField]: {
    inputID: INPUT_ID_KEYS.confirmPassword,
    inputType: 'password'
  }
};

export function mapUserInformationToFormInputProps(userInformation) {
  return Object.entries(userInformation)
    .filter(([key, value]) => FIELDS_CONFIG_MAPPING[key] && value)
    .map(([key, value]) => {
      return {
        ...FIELDS_CONFIG_MAPPING[key],
        label: value.label,
        placeholder: value.placeholder,
        invalidFeedback: value.errorMessage
      };
    });
}
