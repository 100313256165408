import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { split, replace } from 'lodash';

import JoinNowBtn from './JoinNowBtn';

const LandingPageBenefits = props => {
  const { content } = props;
  const { title, benefits, info } = useMemo(() => {
    let elements = split(content, /\r\n|\r|\n/).filter(Boolean);
    let benefits = [];

    if (elements[elements.length - 1] === '---')
      elements = elements.slice(0, -1);
    let count = elements.length <= 4 ? elements.length : elements.length - 2;
    for (let i = 1; i <= count; i++) {
      benefits.push(elements[i]);
    }
    let info = elements.length <= 4 ? '' : elements[elements.length - 1];
    return {
      title: replace(elements[0], '#', '').trim(),
      benefits,
      info
    };
  }, [content]);

  return (
    <div className="benefits-section container">
      <div className="row">
        <div className="col-md-4">
          <h2 className="benefits-title">{title}</h2>
          {info && (
            <div className="benefits-info info-desktop">
              {info.replace(new RegExp('_', 'g'), '')}
            </div>
          )}
        </div>
        <div className="col-md-8 benefits-container">
          <ReactMarkdown>{benefits.join('\n')}</ReactMarkdown>
          {info && (
            <div className="benefits-info info-mobile">
              {info.replace(new RegExp('_', 'g'), '')}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <div className="jumbotron-join-button-container">
            <JoinNowBtn {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

LandingPageBenefits.propTypes = {
  content: PropTypes.any
};

export default LandingPageBenefits;
