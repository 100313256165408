import { Link } from 'react-router-dom-v5-compat';
import styled from 'styled-components';

type FooterProps = {
  $isTransparent?: boolean;
  $noPadding?: boolean;
  $noGap?: boolean;
};
export const Footer = styled.footer<FooterProps>`
  background-color: ${({ theme, $isTransparent }) =>
    $isTransparent ? 'transparent' : theme.primaryColor};
  ${({ $noPadding }) => ($noPadding ? '' : 'padding: 12px 16px;')}
  display: flex;
  flex-direction: column;
  ${({ $noGap }) => ($noGap ? '' : 'gap: 12px;')}
  box-shadow: ${({ $isTransparent }) =>
    $isTransparent ? 'none' : '0 -2px 2px -2px #000000'};
  color: ${({ theme, $isTransparent }) =>
    $isTransparent ? theme.colors.text.primary : theme.colors.text.inverse};
  font-size: 0.625rem;
`;

export const Paragraph = styled.p`
  margin: 0;
`;

type StyledLinkProps = { $inverse?: boolean };
export const StyledLink = styled(Link)<StyledLinkProps>`
  color: ${({ theme, $inverse = true }) =>
    $inverse ? theme.colors.text.inverse : theme.colors.text.primary};
  &:hover {
    color: ${({ theme, $inverse = true }) =>
      $inverse ? theme.colors.text.inverse : theme.colors.text.primary};
  }
`;

type FooterWrapperProps = { $fullWidth?: boolean };
export const FooterWrapper = styled.div<FooterWrapperProps>`
  display: flex;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '66%')};
  padding: 24px;
  align-items: center;
  align-self: center;
  gap: 24px;
  background: ${({ theme }) => theme.colors.surface.background.secondary};
`;

export const LockupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

export const LockupText = styled.div`
  width: 75px;
  height: 17px;

  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: 400;
  line-height: ${({ theme }) => theme.font.lineHeight.xxs};
`;

export const LockupImg = styled.img`
  width: 62.118px;
  height: 24px;
`;
