import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from 'mdi-react/CloseIcon';

import ProfileMenuItem from './ProfileMenuItem';
import HomeMenuItem from './HomeMenuItem';
import UserMenuItems from './UserMenuItems';

export default function SideMenuItemContainer(props) {
  const {
    className,
    isMobile,
    needsToSign,
    needsScreening,
    closeSideMenu,
    profileSession: {
      user: { userType }
    },
    match: {
      params: { page }
    }
  } = props;
  const isPatientWithSigned =
    !(needsToSign || needsScreening) || userType !== 'P' || page === 'loading';
  return (
    <ul className={className}>
      {isMobile ? (
        <li>
          <CloseIcon
            color="#80868B"
            className="pull-right"
            onClick={closeSideMenu}
          />
        </li>
      ) : null}
      <HomeMenuItem {...props} isPatientWithSigned={isPatientWithSigned} />
      {isPatientWithSigned ? <UserMenuItems {...props} /> : null}
      {isPatientWithSigned ? <ProfileMenuItem {...props} /> : null}
    </ul>
  );
}

SideMenuItemContainer.propTypes = {
  className: PropTypes.any,
  closeSideMenu: PropTypes.any,
  profileSession: PropTypes.any,
  match: PropTypes.any,
  isMobile: PropTypes.any,
  needsToSign: PropTypes.any,
  needsScreening: PropTypes.any
};
