import React from 'react';
import useUserPRNOption from './useUserPRNOption';

export default function useFeatureConfiguration() {
  const { prnOption } = useUserPRNOption();
  return prnOption?.webFeaturesConfiguration || {};
}

export function featureConfigurationHOC(Component) {
  return function FeatureConfigurationWrapper(props) {
    const config = useFeatureConfiguration();
    return <Component {...props} featureConfiguration={config} />;
  };
}
