import { createSelector } from 'reselect';
import { getOr } from 'lodash/fp';
import { useSelector } from 'react-redux';

/**
 * Custom hook to select the authentication state from store.
 * @returns {*} The authentication state.
 */
export function useAuth() {
  return useSelector(({ auth }) => auth);
}

export function useAuthUser() {
  const { profileSession } = useAuth();
  return profileSession?.user;
}

export const rootAuthSelector = getOr({}, 'auth');
export const selectAuthToken = createSelector(
  rootAuthSelector,
  getOr('', 'authToken')
);
