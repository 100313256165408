import React, { Component } from 'react';
import PRNOptions from './PRNOptions.js';
import ReactGA from 'react-ga';
import JoinNowBtn from './LandingPage/JoinNowBtn.js';
import MobileStoreLinks from './LandingPage/MobileStoreLinks.js';

class Jumbotron extends Component {
  sendGAEvent() {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Jumbotron Join Button'
    });
  }
  render() {
    const jumbotronStyle = {
      backgroundImage:
        'url(' +this.props.backgroundImageURL +')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderRadius: '0px',
      minHeight: '545px',
      color: 'white'
    };
    if (this.props.link !== '/') {
      return (
        <div>
          <section
            className="jumbotron text-center with-link"
            style={jumbotronStyle}
          >
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <h1 className="jumbotron-heading col-12 col-md-10">
                  {this.props.title}
                </h1>
                <p className="lead col-12 col-md-10 my-5">
                  {this.props.description}
                </p>
                <div className="lead col-12 col-md-10">
                  <div className="jumbotron-join-button-container">
                    <JoinNowBtn action={this.sendGAEvent} {...this.props} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      return (
        <section
          className="jumbotron text-center"
          id="jumbotron"
          style={jumbotronStyle}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 title-container">
                <div>
                  <h1>{this.props.title}</h1>
                  <p>{this.props.description}</p>
                </div>
              </div>
              <div className="col-md-6 list-container">
                {this.props.displayStoreLinks ? (
                  <MobileStoreLinks />
                ) : (
                  <PRNOptions {...this.props} />
                )}
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default Jumbotron;
