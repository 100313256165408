import React, { Component } from 'react';
import Nav from './Nav';
import { messagingPreference } from '../utils/OneVueAPI.js';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class MessagingPreferences extends Component {
  state = {
    status: 0,
    token: null
  };

  componentDidMount() {
    this.setState({
      token: this.props.match.params.token
    });
    document.body.style.backgroundColor = 'rgb(114,164,203)';
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  onSubmit = () => {
    messagingPreference(this.state.token, this.submitCompletion);
  };

  renderMessagingPrefError = () => (
    <div>
      <h5>{this.props.t('messagingPreferences.error')}</h5>
    </div>
  );

  renderMessagingPrefSuccess = () => (
    <div>
      <h5>{this.props.t('messagingPreferences.success')}</h5>
    </div>
  );

  renderMessagingPrefDefault = () => (
    <div>
      <h1 className="header-style">
        {this.props.t('messagingPreferences.unsubscribeTitle')}
      </h1>
      <p>{this.props.t('messagingPreferences.unsubscribeBody')}</p>
      <hr />
      <button
        onClick={this.onSubmit}
        className="btn btn-lg btn-outline-primary btn-block btn-monarc-orange"
        type="button"
      >
        {this.props.t('messagingPreferences.unsubscribeBtn')}
      </button>
    </div>
  );

  submitCompletion = responseJSON => {
    if (responseJSON) {
      if (responseJSON.success) {
        this.setState({
          status: 1
        });
      } else {
        this.setState({
          status: 2
        });
      }
    } else {
      this.setState({
        status: 2
      });
    }
  };

  render() {
    const VIEWS = () => ({
      0: this.renderMessagingPrefDefault(),
      1: this.renderMessagingPrefSuccess(),
      2: this.renderMessagingPrefError()
    });

    const outerStyle = {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '40px',
      paddingBottom: '40px'
    };
    const formSignin = {
      width: '100%',
      maxWidth: '650px',
      padding: '15px',
      borderRadius: '5px',
      backgroundColor: '#f5f5f5',
      boxShadow: '0 0 5px rgba(25,25,25,0.25)'
    };

    return (
      <div>
        <div className="mb-5">
          <Nav />
          <div className="text-center" style={outerStyle}>
            <form
              className="my-3 p-3 bg-white rounded box-shadow dashboard-item"
              style={formSignin}
            >
              {VIEWS(this.props)[this.state.status]}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

MessagingPreferences.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(MessagingPreferences);
