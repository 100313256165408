import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '../ThemeProvider.js';
import LogInButton from './LogInButton.js';
import LogOutButton from './LogOutButton.js';
import LocalePicker from '../LocalePicker';

export default function SessionButton({
  isMobile,
  profileSession,
  needsToSign,
  logout,
  authToken
}) {
  const { theme: { webDisplayStoreLinks } = {} } = useTheme();
  if (webDisplayStoreLinks) return <LocalePicker isMobile={isMobile} />;
  if (!authToken) return <LogInButton isMobile={isMobile} />;
  return (
    <LogOutButton
      isMobile={isMobile}
      profileSession={profileSession}
      needsToSign={needsToSign}
      logout={logout}
    />
  );
}

SessionButton.propTypes = {
  isMobile: PropTypes.bool,
  profileSession: PropTypes.object,
  needsToSign: PropTypes.bool,
  logout: PropTypes.func,
  authToken: PropTypes.string
};
