import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import PersonIcon from 'mdi-react/PersonIcon';
import PollBoxIcon from 'mdi-react/PollBoxIcon';
import CellphoneLinkIcon from 'mdi-react/CellphoneLinkIcon';
import ContentPasteIcon from 'mdi-react/ContentPasteIcon';
import LockIcon from 'mdi-react/LockIcon';
import JoinNowBtn from './LandingPage/JoinNowBtn.js';

const LandingPageProcess = props => {
  const { link, items = [], processTitle } = props;
  const { firstRow, secondRow } = useMemo(() => {
    const firstRow = [];
    const secondRow = [];
    items
      .filter(item => item.fields)
      .forEach((item, index) => {
        index <= 2 ? firstRow.push(item) : secondRow.push(item);
      });
    return { firstRow, secondRow };
  }, [items]);
  const getIcon = useCallback(item => {
    if (item.image?.fields?.file?.url) {
      return (
        <img
          src={item.image.fields.file.url}
          className="rounded img-fluid"
          alt={item.image.fields.title || item.title}
        />
      );
    }
    switch (item.id) {
      case '1':
        return <PersonIcon size={48} />;
      case '2':
        return <PollBoxIcon size={48} />;
      case '3':
        return <CellphoneLinkIcon size={48} />;
      case '4':
        return <ContentPasteIcon size={48} />;
      case '5':
        return <LockIcon size={48} />;
      default:
        return null;
    }
  }, []);

  return (
    <div className="container-fluid process-container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <h2 className="process-title">
            {processTitle || 'How does it work?'}
          </h2>
          <div className="row first">
            {firstRow.map(({ fields: item, sys }) => (
              <div className={`item-container col-md-4`} key={sys.id}>
                <div className="item-icon-container">{getIcon(item)}</div>
                <div className="item-body">
                  <h5 className="item-title">{item.title}</h5>
                  <p className="item-description">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          {secondRow.length > 0 && (
            <div className="row second">
              {secondRow.map(({ fields: item, sys }) => (
                <div className={`item-container col-sm-6`} key={sys.id}>
                  <div className="item-icon-container">{getIcon(item)}</div>
                  <div className="item-body">
                    <h5 className="item-title">{item.title}</h5>
                    <p className="item-description">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {link && (
        <div className="row justify-content-center join-now-container">
          <div className="col-12 d-flex justify-content-center">
            <div className="jumbotron-join-button-container">
              <JoinNowBtn {...props} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

LandingPageProcess.propTypes = {
  link: PropTypes.string,
  items: PropTypes.array,
  processTitle: PropTypes.string
};

export default LandingPageProcess;
