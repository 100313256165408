import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from '../ThemeProvider';
import { replaceTemplatesInString } from '../../utils/strings';
import useConfirmTokenSubmission from './useConfirmTokenSubmission';
import NoAuthPagesWrapper from '../NoAuthPagesWrapper';

const defaultConfig = {
  navBarLogInButton: 'Sign In',
  confirmEmailFailureTitle: 'Oops',
  confirmEmailFailureSubtitle:
    'There was an error activating your account. Please contact our team for support: {supportEmail}.',
  confirmEmailActivatedTitle: 'Account Activated!',
  confirmEmailActivatedSubtitle: 'Sign in to access your {programName} account.'
};

function ConfirmToken(props) {
  const { theme } = useTheme();
  const {
    screenAndPagesContentConfiguration: { sessionContentConfiguration } = {}
  } = theme;
  const config = useMemo(
    () => ({
      ...defaultConfig,
      ...sessionContentConfiguration
    }),
    [sessionContentConfiguration]
  );
  const {
    isLoading,
    didLoad,
    data,
    error,
    confirmTokenSubmit
  } = useConfirmTokenSubmission(props.match?.params?.token);
  useEffect(() => {
    confirmTokenSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <NoAuthPagesWrapper {...props}>
      {isLoading || !didLoad ? (
        <div>
          <div className="mb-3">
            <div className="row justify-content-center">
              <div className="fa-3x text-center">
                <i className="fas fa-circle-notch fa-spin" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {error || !data?.success ? (
        <div>
          <h1 className="h3 mb-3 font-weight-normal">
            {replaceTemplatesInString(config.confirmEmailFailureTitle, theme)}
          </h1>
          <p>
            {replaceTemplatesInString(
              config.confirmEmailFailureSubtitle,
              theme
            )}
          </p>
          <br />
        </div>
      ) : null}
      {data?.success ? (
        <div>
          <h1 className="h3 mb-3 font-weight-normal">
            {replaceTemplatesInString(config.confirmEmailActivatedTitle, theme)}
          </h1>
          <p>
            {replaceTemplatesInString(
              config?.confirmEmailActivatedSubtitle,
              theme
            )}
          </p>
          <Link to="/login">
            <button
              className="btn btn-lg btn-outline-primary btn-block"
              type="submit"
            >
              {config.navBarLogInButton}
            </button>
          </Link>
        </div>
      ) : null}
    </NoAuthPagesWrapper>
  );
}

ConfirmToken.propTypes = {
  match: PropTypes.any
};

export default ConfirmToken;
