import { useReducer } from 'react';

import { createFetchActions } from '../../../actions/utils';
import { validateScreening } from '../../../utils/OneVueAPI';
import { mapToQuestionsPayload } from '../../RegisterNew/utils/mappers';

const { types, actions } = createFetchActions('VALIDATE_SCREENING');

const initialState = {
  isLoading: false,
  didLoad: false,
  error: null,
  data: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.START:
      return { ...state, isLoading: true };
    case types.SUCCESS:
      return {
        ...state,
        isLoading: false,
        didLoad: true,
        data: action.payload
      };
    case types.FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case types.CLEAR:
      return { ...initialState };
  }
}

export default function useValidateScreening() {
  const [state, dispatch] = useReducer(reducer, initialState);
  async function validateScreeningAction(data, screeningQuestions) {
    try {
      dispatch(actions.START());
      const payload = {
        ...data,
        screeningQuestions: mapToQuestionsPayload(
          data.screeningQuestions,
          screeningQuestions
        )
      };
      const response = await validateScreening(payload);
      dispatch(actions.SUCCESS(response.data));
    } catch (error) {
      dispatch(actions.FAILURE(error.message));
    }
  }
  return {
    validateScreening: validateScreeningAction,
    ...state
  };
}
