import React from 'react';
import PropTypes from 'prop-types';

const FooterButton = ({ title, isDisabled, onNextClick }) => (
  <div className="footer">
    <div className="buttons-container">
      <div className="btn-next-container">
        <button
          type="submit"
          className={`btn-next ${isDisabled ? 'disabled' : ''}`}
          onClick={onNextClick}
        >
          {title}
        </button>
      </div>
    </div>
  </div>
);

FooterButton.propTypes = {
  title: PropTypes.string.isRequired,
  onNextClick: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default FooterButton;
