import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { omit } from 'lodash';
import ChooseInput from './ChooseInput';

function FormInput(props) {
  const {
    inputID,
    label,
    description,
    inputType,
    errors,
    invalidFeedback,
    wrapperClassName,
    labelClassName,
    descriptionClassName
  } = props;
  return (
    <>
      <div className={wrapperClassName} data-testid={`form-input-${inputID}`}>
        {inputType !== 'checkbox' ? (
          <label className={labelClassName} htmlFor={inputID}>
            {label}
          </label>
        ) : null}
        {description ? (
          <small
            className={`d-block ${
              descriptionClassName ? descriptionClassName : ''
            }`}
          >
            {typeof description === 'string'
              ? description
              : documentToReactComponents(description)}
          </small>
        ) : null}
        <ChooseInput {...omit(props, 'label')} />
        {inputType === 'checkbox' ? (
          <label className={labelClassName} htmlFor={inputID}>
            {label}
          </label>
        ) : null}
      </div>
      {errors?.[inputID] ? (
        <div
          data-testid={`invalid-feedback-${inputID}`}
          className="invalid-feedback"
        >
          {invalidFeedback}
        </div>
      ) : null}
    </>
  );
}

FormInput.propTypes = {
  inputID: PropTypes.string,
  invalidFeedback: PropTypes.any,
  label: PropTypes.any,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errors: PropTypes.any,
  inputType: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  descriptionClassName: PropTypes.string
};

export default FormInput;
