import React from 'react';
import format from 'date-fns/format';
import * as recordTypes from '../../constants/recordTypes';
import { isValid } from 'date-fns';
import _ from 'lodash';

const getFormattedAddres = address =>
  address && address.line
    ? `${address.line
        .map(s => s)
        .join('\n')
        .trim()}\n${address.city}, ${address.state} ${address.postalCode}`
    : address;
function getAppointmentAddress(data) {
  if (data.contained && data.contained.length > 0) {
    if (data.contained[0].address && data.contained[0].address.length > 0) {
      return data.contained[0].address[0].text || '--';
    }
  }
  return '--';
}
function getAppointmentsRecords(record, recordType) {
  if (recordType !== recordTypes.APPOINTMENTS) return {};

  try {
    const { resource: data } = record;

    if (_.isEmpty(data)) return { start: '--', end: '--', address: '--' };
    const address = getAppointmentAddress(data);

    return {
      start: format(data.start, 'MM/DD/YYYY  hh:mm aa')
        .split('  ')
        .join('\n'),
      end: format(data.end, 'MM/DD/YYYY  hh:mm aa')
        .split('  ')
        .join('\n'),
      address: getFormattedAddres(address)
    };
  } catch (e) {
    return {
      start: '--',
      end: '--',
      address: '--'
    };
  }
}
function getConditionsRecords(record, recordType) {
  if (recordType !== recordTypes.CONDITIONS) return {};

  try {
    const { resource: data } = record;

    if (_.isEmpty(data)) {
      return {
        clinicalStatus: '--',
        onSetDateTime: '--',
        formattedDate: '--',
        text: '--'
      };
    }
    const clinicalStatus = data?.clinicalStatus?.coding?.[0]?.code;
    const formattedDate = format(data.onsetDateTime, 'MM/DD/YYYY  hh:mm aa')
      .split('  ')
      .join('\n');
    return {
      clinicalStatus: clinicalStatus ? clinicalStatus : '--',
      onSetDateTime: formattedDate === 'Invalid Date' ? '--' : formattedDate,
      text: data.text ? data.text.div : '--'
    };
  } catch (e) {
    return {
      clinicalStatus: '--',
      onSetDateTime: '--',
      text: '--'
    };
  }
}
function checkForRecords(record) {
  if (_.isEmpty(record) || _.isEmpty(record?.resource))
    return {
      address: '--',
      performer: '--',
      phone: '--',
      performedDateTime: '--',
      status: '--'
    };
}
function getFullName(name) {
  let fullName = '';
  name.given && name.given.map(item => (fullName += item.trim() + ' '));
  name.family && name.family.map(item => (fullName += item.trim() + ' '));
  name.suffix && name.suffix.map(item => (fullName += item.trim() + ' '));
  return fullName;
}
function getValuesToReturn(record, fullName, addressToFormat, phoneValue) {
  return {
    address: getFormattedAddres(addressToFormat),
    performer: fullName ? fullName.trim() : '--',
    phone: phoneValue,
    performedDateTime: format(
      record.data.performedDateTime,
      'MM/DD/YYYY  hh:mm aa'
    )
      .split('  ')
      .join('\n'),
    status: record.data.status
  };
}
function getProcedureRecords(record, recordType) {
  if (recordType !== recordTypes.PROCEDURES) return {};
  checkForRecords(record);

  const { resource: data } = record;
  const { name, address, telecom } = data?.subject ?? {};
  const fullName = getFullName(name);
  const addressToFormat = address && address.length > 0 ? address[0] : '--';
  const phoneValue = telecom && telecom.length > 0 ? telecom[0].value : '--';
  return getValuesToReturn(record, fullName, addressToFormat, phoneValue);
}
function getValue(item) {
  return (
    item.valueString ||
    (item.valueQuantity
      ? `${item.valueQuantity.value} ${
          isNaN(item.valueQuantity.code) ? item.valueQuantity.code : ''
        }`
      : '')
  );
}
function getLabsRecords(record, recordType) {
  if (recordType !== recordTypes.LABS_AND_TEST_RESULTS) return {};

  try {
    const { resource: data } = record;

    const labsValues = [];
    data.contained.map(item => {
      if (!item.code) return null;
      labsValues.push({
        name: item.code.text || item.code.coding[0].code,
        value: getValue(item),
        range: item.referenceRange ? item.referenceRange[0].text : undefined,
        comments: item.comments,
        unitValue: item.valueQuantity ? item.valueQuantity.value : 0,
        unit: item.valueQuantity ? item.valueQuantity.unit : ''
      });
      return null;
    });
    return labsValues;
  } catch (e) {
    return [];
  }
}
function getVitalsContained(contained, effectiveDateTime) {
  try {
    let value = '';
    for (let containedIndex of contained) {
      value =
        value +
        ' ' +
        containedIndex.valueQuantity.value +
        containedIndex.valueQuantity.unit;
    }
    return {
      value,
      effectiveDateTime: format(effectiveDateTime, 'MM/DD/YYYY hh:mm aa')
    };
  } catch (e) {
    return { value: '--', effectiveDateTime: '--' };
  }
}
function getVitalsRecords(record, recordType) {
  if (recordType !== recordTypes.VITALS) return {};

  try {
    const { valueQuantity, effectiveDateTime, contained } =
      record?.resource ?? {};

    if (record?.resource?.valueQuantity) {
      return {
        value: `${valueQuantity.value}  ${valueQuantity.unit}`,
        effectiveDateTime: format(effectiveDateTime, 'MM/DD/YYYY hh:mm aa')
      };
    } else if (contained) {
      return getVitalsContained(contained, effectiveDateTime);
    }
  } catch (e) {
    return { value: '--', effectiveDateTime: '--' };
  }
}
function getEmail(telecom) {
  const findTelecom = telecom.find(item => item.system === 'email');
  return findTelecom ? findTelecom.value : '';
}
function getLanguage(communication) {
  return communication && communication.length > 0
    ? communication[0].language.coding[0].code
    : '--';
}
function getDemographicsRecords(record, recordType) {
  if (recordType !== recordTypes.DEMOGRAPHICS) return {};

  try {
    const {
      resource: { telecom, birthDate, communication, maritalStatus, address }
    } = record;
    const email = getEmail(telecom);
    const language = getLanguage(communication);
    const maritalStatusValue =
      maritalStatus && maritalStatus.coding.length > 0
        ? maritalStatus.coding[0].code
        : '--';

    return {
      email,
      telephones: telecom
        .filter(item => item.system === 'phone')
        .map(phone => `${phone.value}(${phone.use})`)
        .join(', '),
      address: getFormattedAddres(address[0]),
      birthDate,
      language,
      maritalStatus: maritalStatusValue
    };
  } catch (e) {
    return {
      email: '--',
      telephones: '--',
      address: '--',
      birthDate: '--',
      language: '--',
      maritalStatus: '--'
    };
  }
}
function getMedicationsRecords(record, recordType) {
  if (recordType !== recordTypes.MEDICATIONS) return {};
  try {
    const { resource: data } = record;

    const effectiveDateTime = new Date(
      data.effectiveDateTime?.substring(0, 19)
    );

    const dosage = data.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity
      ? `${data.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.value}${data.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit}`
      : '--';

    return {
      description: data.note?.[0]?.text,
      effective_date_time: isValid(effectiveDateTime)
        ? format(effectiveDateTime, 'MM/DD/YYYY  hh:mm aa')
        : '--',
      dosage: dosage,
      status: data.status || '--'
    };
  } catch (e) {
    return {
      description: '--',
      dosage: '--',
      status: '--'
    };
  }
}
function getImmunizationRecords(record, recordType) {
  if (recordType !== recordTypes.IMMUNIZATIONS) return {};
  try {
    const { resource: data } = record;

    return {
      route:
        data.route?.coding[0]?.display ||
        (data.route?.text ? data.route.text : '--'),
      dose: data.doseQuantity
        ? `${data.doseQuantity.value}${data.doseQuantity.code}`
        : '--',
      name: data.vaccineCode ? data.vaccineCode.text : '--',
      recordType
    };
  } catch (e) {
    return {
      route: '--',
      dose: '--',
      name: '--'
    };
  }
}
function getAlertsRecords(record, recordType) {
  if (recordType !== recordTypes.ALERTS) return {};

  try {
    const { resource: data } = record;

    return {
      recordedDate: data?.recordedDate
        ? format(data?.recordedDate, 'MM/DD/YYYY hh:mm aa')
        : '--',
      substance: data?.substance.coding
        ? data?.substance.coding[0].display
        : '--',
      criticality: data?.criticality,
      status: data?.status
    };
  } catch (e) {
    return {
      recordedDate: '--',
      substance: '--',
      criticality: '--',
      status: '--'
    };
  }
}
export function getDetailsByType(record, recordType) {
  try {
    const valuesObject = {
      [recordTypes.MEDICATIONS]: getMedicationsRecords(record, recordType),
      [recordTypes.IMMUNIZATIONS]: getImmunizationRecords(record, recordType),
      [recordTypes.APPOINTMENTS]: getAppointmentsRecords(record, recordType),
      [recordTypes.CONDITIONS]: getConditionsRecords(record, recordType),
      [recordTypes.PROCEDURES]: getProcedureRecords(record, recordType),
      [recordTypes.LABS_AND_TEST_RESULTS]: getLabsRecords(record, recordType),
      [recordTypes.VITALS]: getVitalsRecords(record, recordType),
      [recordTypes.ALERTS]: getAlertsRecords(record, recordType),
      [recordTypes.DEMOGRAPHICS]: getDemographicsRecords(record, recordType)
    };

    if (recordType !== recordTypes.LABS_AND_TEST_RESULTS)
      return { ...valuesObject[recordType], recordType };
    else return valuesObject[recordType];
  } catch (e) {
    return {};
  }
}
export function getInfoOptions(recordType) {
  try {
    let infoOptions;
    switch (recordType) {
      case recordTypes.IMMUNIZATIONS:
        infoOptions = {
          top: [
            { display: 'Route:', tagName: 'route' },
            { display: 'Dose:', tagName: 'dose' },
            { display: 'Name:', tagName: 'name' }
          ]
        };
        break;
      case recordTypes.APPOINTMENTS:
        infoOptions = {
          top: [
            { display: 'Start:', tagName: 'start' },
            { display: 'End:', tagName: 'end' },
            { display: 'Address:', tagName: 'address' }
          ]
        };
        break;
      case recordTypes.CONDITIONS:
        infoOptions = {
          top: [
            { display: 'Clinical Status:', tagName: 'clinicalStatus' },
            { display: 'Set Date:', tagName: 'onSetDateTime' },
            { display: 'Text:', tagName: 'text' }
          ]
        };
        break;
      case recordTypes.PROCEDURES:
        infoOptions = {
          top: [
            { display: 'Address:', tagName: 'address' },
            { display: 'Performer:', tagName: 'performer' },
            { display: 'Phone:', tagName: 'phone' },
            { display: 'Status:', tagName: 'status' },
            { display: 'Performed Date:', tagName: 'performedDateTime' }
          ]
        };
        break;
      case recordTypes.VITALS:
        infoOptions = {
          top: [
            { display: 'Value:', tagName: 'value' },
            { display: 'Effective Date:', tagName: 'effectiveDateTime' }
          ]
        };
        break;
      case recordTypes.ALERTS:
        infoOptions = {
          top: [
            { display: 'Substance:', tagName: 'substance' },
            { display: 'Recorded Date:', tagName: 'recordedDate' },
            { display: 'Criticality:', tagName: 'criticality' },
            { display: 'Status:', tagName: 'status' }
          ]
        };
        break;
      case recordTypes.DEMOGRAPHICS:
        infoOptions = {
          top: [
            {
              display: 'Email:',
              tagName: 'email',
              normalCase: true
            },
            { display: 'Phone(s):', tagName: 'telephones' },
            { display: 'Address:', tagName: 'address' },
            { display: 'Birth Date:', tagName: 'birthDate' }
          ],
          bottom: [
            { display: 'Language:', tagName: 'language' },
            { display: 'Marital Status:', tagName: 'maritalStatus' }
          ]
        };
        break;
      default:
        infoOptions = 'default';
    }
    return infoOptions;
  } catch (e) {
    return { top: [], bottom: [] };
  }
}

export function renderProperties(record, defaultOptions) {
  const positions = ['top', 'bottom'];
  const infoOptions =
    defaultOptions || getInfoOptions(record.details.recordType);
  return positions.map(position => {
    if (!infoOptions[position]) return null;
    return (
      <div className={position} key={position}>
        {infoOptions[position].map(
          ({ display, tagName, normalCase }, index) => (
            <div
              key={index}
              className={`info-tab ${normalCase ? 'normal-case' : ''}`}
            >
              <label>{display}</label>
              <span
                dangerouslySetInnerHTML={{
                  __html: record.details[tagName] || '--'
                }}
              />
            </div>
          )
        )}
      </div>
    );
  });
}
export function getRecordCreatedTime(record, recordType) {
  try {
    const { resource: data } = record;
    let returnDate;
    switch (recordType) {
      case recordTypes.CONDITIONS:
        returnDate = data.onsetDateTime;
        break;
      case recordTypes.APPOINTMENTS:
        returnDate = data.start;
        break;
      case recordTypes.IMAGING:
        returnDate = data.started;
        break;
      case recordTypes.PROCEDURES:
        returnDate = data.occurrenceDateTime;
        break;
      case recordTypes.ALERTS:
        returnDate = data.occurrenceDateTime;
        break;
      case recordTypes.ALLERGIES:
        returnDate = data.recordedDate;
        break;
      case recordTypes.MEDICATIONS:
        returnDate = new Date(data.effectiveDateTime?.substring(0, 19));
        returnDate = isValid(returnDate) ? returnDate : data.recordedDate;
        break;
      case recordTypes.IMMUNIZATIONS:
        returnDate = new Date(data.occurrenceDateTime?.substring(0, 19));
        returnDate = isValid(returnDate) ? returnDate : data.recordedDate;
        break;
      case recordTypes.HOSPITALIZATION:
        returnDate = data.actualPeriod?.start;
        break;
      case recordTypes.OFFICE_VISIT:
        returnDate = data.actualPeriod?.start;
        break;
      case recordTypes.DEMOGRAPHICS:
        returnDate = data.birthDate;
        break;
      case recordTypes.LABS_AND_TEST_RESULTS:
        returnDate = data.effectiveDateTime;
        break;
      case recordTypes.PATHOLOGY:
        returnDate = data.effectiveDateTime;
        break;
      case recordTypes.VITALS:
        returnDate = data.effectiveDateTime;
        break;
      case recordTypes.GENETIC_TESTING:
        returnDate = data.effectiveDateTime
          ? data.effectiveDateTime
          : data.issued;
        break;
      default:
        returnDate = data.recordedDate;
        break;
    }
    return returnDate || data.recordedDate;
  } catch (e) {
    return '';
  }
}
export function getPageCondition(index, page, rowsPerPage) {
  const pageCondition =
    index < (page - 1) * rowsPerPage || index >= page * rowsPerPage;
  return pageCondition ? null : true;
}
