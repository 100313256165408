import styled from 'styled-components';

export const ScrollableContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FormPageContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.surface.background.secondary};
  flex: 1;
  min-height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

export const ErrorAlert = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.border.negative};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface.background.negative};
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 16px 24px;
  width: 100%;
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
`;

export const InfoAlert = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.border.brand};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.action.hover.secondary};
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 16px 24px;
  width: 100%;
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
`;

type FormContainerProps = {
  $maxWidth?: string;
};

export const FormContainer = styled.div<FormContainerProps>`
  margin-top: 80px;
  max-width: ${({ $maxWidth }) => $maxWidth || '400px'};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormAlertContainer = styled.div`
  margin-bottom: 20px;
  min-height: 70px;
  max-width: 620px;
`;

export const SessionMgtFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  font-weight: 700;
  text-align: center;
`;

export const PortalPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  gap: 24px;
  flex-grow: 1;
  overflow: hidden;
`;

export const RowWithGap = styled.div<{ $gap?: string; $alignItems?: string }>`
  display: flex;
  align-items: ${({ $alignItems = 'center' }) => $alignItems};
  gap: ${({ $gap = '8px' }) => $gap};
`;

export const SpanRowWithGap = styled.span<{ $gap: string }>`
  display: flex;
  align-items: center;
  gap: ${({ $gap }) => $gap || '8px'};
`;

export const ColumnWithGap = styled.div<{ $gap: string; $align?: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $align }) => $align || 'center'};
  gap: ${({ $gap }) => $gap || '8px'};
`;

export const Flex1Column = styled.div<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  word-break: break-word;
  ${({ $gap }) => ($gap ? `gap: ${$gap};` : '')}
`;

export const RowWrap = styled.div<{ $mt?: string; $width?: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${({ $mt }) => $mt || '0px'};
  width: ${({ $width = 'auto' }) => $width};
`;
