import * as actionTypes from '../constants/actionTypes';
import StateLoader from './StateLoader';
const stateLoader = new StateLoader();

function convertProvider(provider) {
  const providerAddress = provider.providerAddress
    ? `${provider.providerAddress}, `
    : '';
  const providerCity = provider.providerCity
    ? `${provider.providerCity}, `
    : '';
  const providerState = provider.providerState
    ? `${provider.providerState} `
    : '';

  return {
    id: provider.id,
    portalId: provider.id,
    profileId: provider.id,
    portalName: provider.providerName,
    facilityName: '',
    location: `${providerAddress}${providerCity}${providerState}${provider.providerZip}`,
    status: provider.status,
    statusMessage: provider.status
  };
}

export default function invitationsReducer(
  state = stateLoader.initializeState().providers,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_PROVIDERS_START: {
      return {
        ...state,
        isLoading: true,
        loadingError: '',
        providers: []
      };
    }
    case actionTypes.FETCH_PROVIDERS_SUCCESS: {
      return {
        ...state,
        providers: action.response.map(convertProvider),
        isLoading: false
      };
    }
    case actionTypes.FETCH_PROVIDERS_FAILURE:
      return {
        ...state,
        providers: [],
        isLoading: false,
        loadingError: action.response
      };
    case actionTypes.FETCH_PROVIDERS_BY_PATIENT_START: {
      return {
        ...state,
        isLoading: true,
        loadingError: '',
        providersByPatient: {}
      };
    }
    case actionTypes.FETCH_PROVIDERS_BY_PATIENT_SUCCESS: {
      return {
        ...state,
        providersByPatient: { data: action.response },
        isLoading: false
      };
    }
    case actionTypes.FETCH_PROVIDERS_BY_PATIENT_FAILURE:
      return {
        ...state,
        providersByPatient: {},
        isLoading: false,
        loadingError: action.response
      };
    default:
      return state;
  }
}
