import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Consent from '../pages/DashboardConsent/components/Consent.js';
import ScreeningQuestions from '../pages/ScreeningQuestions';
import DashboardSHR from '../pages/DashboardSHR.js';
import * as providerActions from '../../actions/providers';
import * as userActions from '../../actions/user';
import * as recordActions from '../../actions/records';
import { PRE_SCREENING_PASSED } from '../../constants/screeningPassedTypes.js';

export class Onboarding extends Component {
  state = {
    ComponentToRender: null,
    id: 0,
    isProvidersLoading: true
  };
  componentDidMount = () => {
    this.mounted = true;
    this.setState({ shrStatus: this.props.shrStatus });
  };
  componentWillUnmount = () => {
    this.mounted = false;
  };
  checkProviders = async () => {
    this.setState({ ComponentToRender: DashboardSHR, reload: true });
  };

  setRedirection = () => {
    const { history } = this.props;
    const { shrStatus } = this.state;
    if (
      (shrStatus === 4 && !history) ||
      [0, 1, 3, 5].includes(this.state.shrStatus)
    ) {
      this.setState({ renderLoading: true });
    } else {
      this.setState({ renderSHR: true });
    }
  };
  redirect = () => {
    const { providers, consents } = this.props;
    const { shrStatus } = this.state;
    if (
      shrStatus &&
      providers &&
      providers.length > 0 &&
      consents.length === 0
    ) {
      this.setRedirection();
    }
  };
  getProviders = () => {
    if (this.state.isProvidersLoading) {
      const { profileSession, providerActions, authToken } = this.props;
      providerActions.getProviders(authToken, profileSession.user.id);

      if (this.mounted) this.setState({ isProvidersLoading: false });
    }
  };
  componentDidUpdate = oldProps => {
    if (!this.mounted) return null;
    this.redirect();
    this.getProviders(oldProps);
    if (this.state.shrStatus !== this.props.shrStatus) {
      this.setState({ shrStatus: this.props.shrStatus });
    }
  };
  render() {
    const { needsToSign, needsScreening } = this.props;
    const { renderLoading, renderSHR } = this.state;
    if (renderLoading) return <Redirect to="/home/loading" />;
    if (renderSHR) return <Redirect to="/home/shr" />;

    const props = {
      ...this.props,
      id: this.state.id,
      checkProviders: this.checkProviders,
      showLoading: this.state.isProvidersLoading,
      reload: this.state.reload
    };

    if (needsToSign) return <Consent {...props} />;
    if (needsScreening) return <ScreeningQuestions {...props} />;

    return <DashboardSHR {...props} />;
  }
}

function mapStateToProps({
  auth: { authToken, profileSession },
  user: {
    consents: { consents, consent, needsToSign }
  },
  providers: { providers }
}) {
  return {
    authToken,
    profileSession,
    consents,
    consent,
    providers,
    needsToSign,
    needsScreening:
      profileSession?.user?.screeningPassed === PRE_SCREENING_PASSED
  };
}
function mapDispatchToProps(dispatch) {
  return {
    providerActions: bindActionCreators(providerActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    recordActions: bindActionCreators(recordActions, dispatch)
  };
}

Onboarding.propTypes = {
  authToken: PropTypes.string,
  consents: PropTypes.array,
  history: PropTypes.object,
  profileSession: PropTypes.object,
  providerActions: PropTypes.object,
  providers: PropTypes.array,
  userActions: PropTypes.object,
  shrStatus: PropTypes.number,
  needsToSign: PropTypes.bool,
  needsScreening: PropTypes.bool
};
export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
