import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Modal from '../shared/Modal';
import { cleanLocaleName } from './utils';

export default function LocaleModal({ locales = [], onLocaleSelect = noop }) {
  return (
    <Modal className="locales-modal" show onClose={noop}>
      <div className="modal-body">
        <h2 className="modal-title">Select your Language</h2>
        <div className="locales-list">
          {locales.map(locale => (
            <button
              className="btn locale-item"
              key={locale.code}
              onClick={() => onLocaleSelect(locale.code)}
            >
              {cleanLocaleName(locale)}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}

LocaleModal.propTypes = {
  locales: PropTypes.any,
  onLocaleSelect: PropTypes.func
};
