import React, { Component } from 'react';
import BaseContainer from './BaseContainer';
import DashboardMyRecord from './../pages/DashboardMyRecord';

class DashboardMyRecordContainer extends Component {
  render() {
    return (
      <BaseContainer {...this.props}>
        <DashboardMyRecord {...this.props} />
      </BaseContainer>
    );
  }
}

export default DashboardMyRecordContainer;
