import React from 'react';
import PropTypes from 'prop-types';
import SideMenuItem from './SideMenuItem';
import useFeatureConfiguration from '../../hooks/useFeatureConfiguration';

export default function HomeMenuItem(props) {
  const { closeSideMenu, url, unreadBadge, isPatientWithSigned } = props;
  const config = useFeatureConfiguration();
  const linkTo = isPatientWithSigned ? '/home' : '/home/onboarding';
  return (
    <SideMenuItem
      onClick={closeSideMenu}
      url={url}
      link={linkTo}
      title={config.sideMenuHomeLabel || 'Home'}
      badge={isPatientWithSigned ? unreadBadge : null}
      icon="fas fa-home"
      {...props}
    />
  );
}

HomeMenuItem.propTypes = {
  closeSideMenu: PropTypes.func,
  unreadBadge: PropTypes.any,
  url: PropTypes.string,
  isPatientWithSigned: PropTypes.any
};
