import { useLocation } from 'react-router-dom';

/**
 * Custom hook to use the query search params.
 * @returns {URLSearchParams} The url search params.
 */
export function useSearchQuery() {
  const { search } = useLocation();
  return new URLSearchParams(search);
}
