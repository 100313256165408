import Joi from 'joi';
import { isFinite } from 'lodash';
import { exists } from '../../../utils/existsValidation';
import { QUESTION_TYPES } from '../../../common';

const createTextValidation = ({ minRange, maxRange, required }) => {
  let schema = Joi.string();
  const isMinRangeDefined = exists(minRange);
  const isMaxRangeDefined = exists(maxRange);
  const minRangeParsed = parseInt(minRange, 10);
  const maxRangeParsed = parseInt(maxRange, 10);

  if (isFinite(minRangeParsed)) {
    schema = schema.min(minRangeParsed);
  }
  if (isFinite(maxRangeParsed)) {
    schema = schema.max(maxRangeParsed);
  }
  if (isMinRangeDefined || isMaxRangeDefined || required) {
    schema = schema.required();
  } else {
    schema = schema.allow('');
  }
  return schema;
};

const createNumberValidation = ({
  minRange,
  maxRange,
  withDecimalScale = false,
  required
}) => {
  let schema = Joi.number();
  const isMinRangeDefined = exists(minRange);
  const isMaxRangeDefined = exists(maxRange);
  const minRangeParsed = minRange;
  const maxRangeParsed = maxRange;

  if (!withDecimalScale) {
    schema = schema.integer();
  }

  if (isFinite(minRangeParsed)) {
    schema = schema.custom((value, helper) => {
      return value >= minRangeParsed
        ? value
        : helper.message(
            `The value must be greater than or equal to ${minRangeParsed}.`
          );
    });
  }
  if (isFinite(maxRangeParsed)) {
    schema = schema.custom((value, helper) => {
      return value <= maxRangeParsed
        ? value
        : helper.message(
            `The value must be less than or equal to ${maxRangeParsed}.`
          );
    });
  }
  if (isMinRangeDefined || isMaxRangeDefined || required) {
    schema = schema.required();
  } else {
    schema = schema.allow('');
  }
  return schema;
};

const createDateValidation = ({ required }) => {
  let schema = Joi.date();
  if (required) {
    schema = schema.required();
  } else {
    schema = schema.allow(null);
  }
  return schema;
};

const createTimeValidation = ({ required }) => {
  let schema = Joi.string().pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);

  if (required) {
    schema = schema.required();
  } else {
    schema = schema.allow(null, '');
  }
  return schema;
};

const createRadioValidation = ({ required }) => {
  let schema = Joi.string();
  if (required) {
    schema = schema.required();
  }
  return schema;
};

export const createQuestionSchema = question => {
  switch (question.questionType) {
    case QUESTION_TYPES.text:
      return createTextValidation(question);
    case QUESTION_TYPES.number:
      return createNumberValidation(question);
    case QUESTION_TYPES.date:
      return createDateValidation(question);
    case QUESTION_TYPES.time:
      return createTimeValidation(question);
    case QUESTION_TYPES.radio:
      return createRadioValidation(question);
    default:
      return null;
  }
};

export const createQuestionsSchemaObject = (
  questions,
  { inputIdPrefix } = {}
) => {
  const questionsSchemaObject = questions.reduce((acc, question) => {
    const questionSchema = createQuestionSchema(question);
    const inputID = inputIdPrefix
      ? `${inputIdPrefix}${question.id}`
      : question.id;
    if (!inputID || !questionSchema) return acc;

    return { ...acc, [inputID]: questionSchema };
  }, {});
  return questionsSchemaObject;
};
