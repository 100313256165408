export const getVisiblePanelComponent = () => {
  const width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  const authPanelSelector = `.auth-panel-${width > 790 ? 'desktop' : 'mobile'}`;
  let prefix = `.auth-panel-container${authPanelSelector} ${authPanelSelector}`;

  return document.querySelector(prefix + ' .auth-content');
};
