import PropTypes from 'prop-types';

const UserInformationFieldShape = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string
};

export const UserInformationShape = {
  firstNameField: PropTypes.shape(UserInformationFieldShape),
  lastNameField: PropTypes.shape(UserInformationFieldShape),
  birthDateField: PropTypes.shape(UserInformationFieldShape),
  diagnosisDateField: PropTypes.shape(UserInformationFieldShape),
  phoneField: PropTypes.shape(UserInformationFieldShape),
  zipCodeField: PropTypes.shape(UserInformationFieldShape),
  stateField: PropTypes.shape(UserInformationFieldShape),
  emailField: PropTypes.shape(UserInformationFieldShape),
  passwordField: PropTypes.shape(UserInformationFieldShape),
  confirmPasswordField: PropTypes.shape(UserInformationFieldShape)
};
