import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
class Page404 extends Component {
  backToHome = () => {
    const linkTo = !this.props.authToken ? '/login' : '/home';
    this.props.history.push(linkTo);
  };
  openSupportDialog = () => document.getElementById('HelpButton').click();

  render() {
    const { t } = this.props;

    const whereTo = !this.props.authToken ? 'Login' : 'Home';
    return (
      <div className="page-404">
        <div className="container box-shadow">
          <h2 className="text-monarc-orange">{t('page404.title')}</h2>
          <p>{t('page404.body')}</p>
          <div className="links-404">
            <button
              onClick={this.backToHome}
              className="back-to-home"
              data-testid="back-btn"
            >
              {t('page404.back')} {whereTo}
            </button>
            <button onClick={this.openSupportDialog} data-testid="contact-btn">
              {t('page404.support')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
Page404.propTypes = {
  history: PropTypes.object,
  authToken: PropTypes.string,
  t: PropTypes.func.isRequired
};

function mapStateToProps({ auth }) {
  return {
    authToken: auth.authToken
  };
}

export default connect(mapStateToProps)(withTranslation()(Page404));
