import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

function TextInput(props) {
  const { inputType, inputID, errors, success, ...rest } = props;
  const inputWrapperClassName = rest.inputWrapperClassName || 'input-group';
  let className = 'form-control';
  if (errors?.[inputID] && props.value) {
    className = 'form-control is-invalid';
  }
  if (success?.[inputID]) {
    className = 'form-control is-valid';
  }
  return (
    <div className={inputWrapperClassName}>
      <input
        autoComplete="on"
        className={className}
        type={inputType}
        id={inputID}
        data-testid={props['data-testid']}
        {...omit(rest, [
          'invalidFeedback',
          'inputWrapperClassName',
          'wrapperClassName',
          'labelClassName'
        ])}
      />
    </div>
  );
}

TextInput.propTypes = {
  errors: PropTypes.any,
  inputID: PropTypes.any,
  inputType: PropTypes.any,
  key: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  onFocus: PropTypes.any,
  onKeyPress: PropTypes.any,
  onKeyUp: PropTypes.any,
  pattern: PropTypes.any,
  placeholder: PropTypes.any,
  required: PropTypes.any,
  success: PropTypes.any,
  value: PropTypes.any,
  invalidFeedback: PropTypes.any,
  'data-testid': PropTypes.string
};
export default TextInput;
