import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Content from './Content';
import Footer from './Footer';
import {
  signConsent,
  isLoggedIn,
  validateCredentials
} from '../../../utils/OneVueAPI.js';
import { getVisiblePanelComponent } from './helper';
import NoAuthModalContent from './NoAuthModalContent.js';
import LoginModalContent from './LoginModalContent.js';
import LoggedInModalContent from './LoggedInModalContent.js';
import { withTranslation } from 'react-i18next';

class AuthorizationPanel extends Component {
  state = {
    step: 0,
    stepsNumber: 0,
    readSteps: [-1],
    authState: 'no-auth',
    loginSubmitError: false
  };

  constructor(props) {
    super(props);
    this.signaturePad = createRef();
  }

  setStep = step => this.setState({ step });

  setStepNumber = stepsNumber => this.setState({ stepsNumber });

  setReadSteps = readSteps => this.setState({ readSteps });

  showLogIn = () =>
    this.setState({ authState: 'login', email: '', password: '' });

  closeModal = () => this.setState({ authState: 'no-auth' });

  setAuthSigned = () => {
    const { step, stepsNumber, readSteps } = this.state;
    if (step !== stepsNumber && stepsNumber > 0) {
      let readStepsCopy = [...readSteps];
      for (let i = 0; i <= stepsNumber; i++) readStepsCopy.push(i);
      this.setState({ step: stepsNumber - 1, readSteps: readStepsCopy }, () => {
        const elem = getVisiblePanelComponent();
        elem.scrollTop = elem.scrollHeight;
      });
    }
  };

  componentDidUpdate(_, prevState) {
    prevState.stepsNumber === 0 &&
      this.state.stepsNumber > 0 &&
      this.props.activeUserConsent.signature.signed &&
      this.setAuthSigned();
  }

  handleChange = event => {
    const { id, value } = event.target;
    this.setState({ [id]: value }, () => this.validateField(id, value));
  };
  validateEmail = value =>
    !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || value.length < 1;
  validatePassword = (value, letter, number) =>
    value.length < 8 || !letter.test(value) || !number.test(value);
  validateField = (fieldName, value) => {
    let fieldValidationErrors = {};
    let fieldSuccess = {};

    var letter = /[a-zA-Z]/;
    var number = /[0-9]/;

    switch (fieldName) {
      case 'email':
        this.validateEmail(value)
          ? (fieldValidationErrors[fieldName] = true)
          : (fieldSuccess[fieldName] = true);
        break;
      case 'password':
        this.validatePassword(value, letter, number)
          ? (fieldValidationErrors[fieldName] = true)
          : (fieldSuccess[fieldName] = true);
        break;
      default:
        break;
    }

    this.setState({ errors: fieldValidationErrors, success: fieldSuccess });
  };

  signButtonClick = event => {
    event.preventDefault();
    let trimEmail = this.state.email.trim();
    let fixedEmail = trimEmail.toLowerCase();
    let email = fixedEmail;
    let pass = this.state.password;
    let errors = this.state.errors;
    if (Object.keys(errors).length === 0 && errors.constructor === Object) {
      validateCredentials(email, pass)
        .then(this.loginCompletion)
        .catch(this.loginError);
    }
  };

  loginCompletion = result => {
    const { data } = result;
    const errorMessage = this.props.t('authorizationPanel.authError');
    if (data.success && isLoggedIn()) {
      this.setState({ authState: 'loggedIn' });
    } else if (!data.success) {
      alert(errorMessage);
    }
  };

  loginError = () => {
    this.setState({ loginSubmitError: true });
  };

  clearLoginError = () => {
    this.setState({ loginSubmitError: false });
  };

  handleClear = () => this.signaturePad.current.clear();

  signConsentRequest = async () => {
    const { updateConsents, consent, t } = this.props;
    let signature = this.signaturePad.current?.toDataURL('image/svg+xml');
    const response = await signConsent(signature, consent);
    if (response?.success) {
      ReactGA.event({
        category: 'User',
        action: 'Signed an Authorization'
      });
      document.getElementsByClassName('close')[0].click();
      await updateConsents();
      window.location.href = `/home`;
    } else {
      ReactGA.event({
        category: 'User',
        action: 'Error signing Auth form'
      });
      alert(t('authorizationPanel.saveSignatureError'));
    }
  };

  handleSignatureSave = () => {
    this.signaturePad.current?.isEmpty()
      ? alert(this.props.t('authorizationPanel.provideSignature'))
      : this.signConsentRequest();
  };

  modelContent = () => {
    const {
      authState,
      email,
      password,
      errors,
      success,
      loginSubmitError
    } = this.state;
    switch (authState) {
      case 'no-auth':
        return <NoAuthModalContent showLogIn={this.showLogIn} />;
      case 'login':
        return (
          <LoginModalContent
            handleChange={this.handleChange}
            email={email}
            password={password}
            errors={errors}
            success={success}
            signButtonClick={this.signButtonClick}
            submitError={loginSubmitError}
            clearSubmitError={this.clearLoginError}
          />
        );
      case 'loggedIn':
        return (
          <LoggedInModalContent
            closeModal={this.closeModal}
            handleSignatureSave={this.handleSignatureSave}
            handleClear={this.handleClear}
            ref={this.signaturePad}
          />
        );
      default:
        return null;
    }
  };
  renderPanel = type => {
    const {
      consent,
      activeUserConsent,
      needsToSign,
      signature,
      history,
      id: signedConsentId
    } = this.props;
    const { readSteps, step } = this.state;
    return (
      <div className={`auth-panel-container auth-panel-${type}`}>
        <Content
          consent={consent}
          readSteps={readSteps}
          setReadSteps={this.setReadSteps}
          setStep={this.setStep}
          step={step}
          stepsNumber={consent?.sections?.length}
          history={history}
          activeUserConsent={activeUserConsent}
          needsToSign={needsToSign}
          signature={signature}
          signedConsentId={signedConsentId}
        />
        <Footer />
      </div>
    );
  };
  render() {
    return (
      <div>
        {this.renderPanel('desktop')}
        {this.renderPanel('mobile')}
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            {this.modelContent()}
          </div>
        </div>
      </div>
    );
  }
}

AuthorizationPanel.propTypes = {
  activeUserConsent: PropTypes.any,
  history: PropTypes.any,
  id: PropTypes.any,
  updateConsents: PropTypes.any,
  consent: PropTypes.any,
  needsToSign: PropTypes.any,
  signature: PropTypes.any,
  t: PropTypes.func.isRequired
};

export default withTranslation()(AuthorizationPanel);
