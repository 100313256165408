import React from 'react';

import { useTheme } from '../../ThemeProvider';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const {
    theme: { supportPhoneNumber, scheduleCallLink }
  } = useTheme();

  const { t } = useTranslation();

  return (
    <div className="auth-footer-container">
      {(supportPhoneNumber || scheduleCallLink) && (
        <div className="bottom">
          <div className="left">
            <div>
              <i className="material-icons">live_help</i>
              <span>{t('authorizationPanel.needHelp')}</span>
            </div>
          </div>
          <div className="right">
            {supportPhoneNumber && (
              <div className="contact-us">
                {t('authorizationPanel.contact')} {supportPhoneNumber}
              </div>
            )}
            {scheduleCallLink && (
              <>
                <div className="or">or</div>
                <div className="demo">
                  <a
                    href={scheduleCallLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('authorizationPanel.scheduleHelp')}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
