import React from 'react';
import PropTypes from 'prop-types';

function Button({
  label,
  onClick,
  isVisible,
  rightIcon,
  leftIcon,
  wrapperClassName,
  className,
  testId
}) {
  return (
    <button
      onClick={onClick}
      className={`${wrapperClassName || ''} ${
        isVisible ? 'visible' : 'hidden'
      }`}
      data-testid={testId}
    >
      <span className={className}>
        {leftIcon}
        <span>{label}</span>
        {rightIcon}
      </span>
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string
};

export default Button;
