import React from 'react';
import PropTypes from 'prop-types';
import useFeatureConfiguration from '../../../hooks/useFeatureConfiguration';

function NoAuthModalContent({ showLogIn }) {
  const config = useFeatureConfiguration();
  return (
    <div className="modal-content re-auth">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {config?.confirmAccountOwnershipModalTitle ||
            'Confirming account ownership'}
        </h5>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>
          {config?.confirmAccountOwnershipModalBody ||
            `In order to electronically sign this form, you must confirm you are
          the account owner by logging in again with your account email and
          password.`}
        </p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-dark"
          data-bs-dismiss="modal"
        >
          {config?.modalsCloseButton || 'Close'}
        </button>
        <button
          type="button"
          onClick={showLogIn}
          className="btn btn-monarc-orange"
        >
          {config?.confirmAccountOwnershipModalLogIn || 'Log In'}
        </button>
      </div>
    </div>
  );
}

NoAuthModalContent.propTypes = {
  showLogIn: PropTypes.func
};

export default NoAuthModalContent;
