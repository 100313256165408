import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const ButtonOutline = ({
  buttonStyle = 'primary',
  action,
  dataCy,
  title,
  block,
  testId
}) => {
  const btnClass = useMemo(() => {
    const baseClass = `${block ? 'btn-block' : ''} mx-2 btn btn-`;
    if (buttonStyle.includes('monarc')) {
      return `${baseClass}${buttonStyle}`;
    }
    return `${baseClass}outline-${buttonStyle}`;
  }, [buttonStyle, block]);

  return (
    <button
      type="button"
      className={btnClass}
      onClick={action || null}
      data-cy={dataCy}
      data-testid={testId}
    >
      {title}
    </button>
  );
};

ButtonOutline.propTypes = {
  buttonStyle: PropTypes.string,
  block: PropTypes.bool,
  action: PropTypes.func,
  href: PropTypes.string,
  title: PropTypes.string,
  testId: PropTypes.string
};

export default ButtonOutline;
