import React from 'react';
import PropTypes from 'prop-types';

export default function Option(props) {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps
  } = props;
  return (
    <div
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      {children}
      {isSelected ? <i className="fas fa-check" /> : null}
    </div>
  );
}

Option.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  cx: PropTypes.any,
  getStyles: PropTypes.any,
  isDisabled: PropTypes.any,
  isFocused: PropTypes.any,
  isSelected: PropTypes.any,
  innerRef: PropTypes.any,
  innerProps: PropTypes.any
};
