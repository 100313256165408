import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MenuIcon from 'mdi-react/MenuIcon';

import { useTheme } from '../ThemeProvider.js';
import SessionButton from './SessionButton.js';

const navStyles = {
  backgroundColor: 'white',
  boxShadow: '1px 0 5px rgba(25,25,25,0.25)'
};

export default function MobileNavBar({
  linkTo = '/',
  className,
  isLanding,
  toggleSideMenuMobile,
  profileSession,
  needsToSign,
  logout,
  authToken
}) {
  const { theme: { mainLogo } = {} } = useTheme();
  return (
    <nav
      id="navbar"
      className={`${className ||
        ''} navbar navbar-light navbar-expand-lg sticky-top flex-md-nowrap justify-content-between mobile-nav`}
      style={navStyles}
    >
      {!isLanding && (
        <div className="menu-icon-container navbar-container">
          <MenuIcon color="#80868B" onClick={toggleSideMenuMobile} />
        </div>
      )}
      <div
        className={`logo-container navbar-container ${
          isLanding ? 'landing-logo' : ''
        }`}
      >
        <Link to={linkTo} className="navbar-brand">
          <img
            src={mainLogo?.src}
            height="60"
            alt={mainLogo?.alt}
            style={{ objectFit: 'contain' }}
          />
        </Link>
      </div>
      <div
        className={`action-icon-container navbar-container ${
          isLanding ? 'landing-container' : ''
        }`}
      >
        <SessionButton
          isMobile
          profileSession={profileSession}
          needsToSign={needsToSign}
          logout={logout}
          authToken={authToken}
        />
      </div>
    </nav>
  );
}

MobileNavBar.propTypes = {
  linkTo: PropTypes.string,
  profileSession: PropTypes.object,
  needsToSign: PropTypes.bool,
  className: PropTypes.string,
  isLanding: PropTypes.bool,
  logout: PropTypes.func,
  authToken: PropTypes.string,
  toggleSideMenuMobile: PropTypes.func
};
