import { toUpper } from 'lodash';
import config from '../config/config';
import { logout } from './OneVueAPI';

const { BASE_URL } = config;

const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const noCacheHeaders = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Expires: 0
};

function getQueryString(opts) {
  let query = '';
  if (opts.queryParams || opts.queryString) {
    query = '?';
    const queryParams = new URLSearchParams(opts.queryParams);
    query += `${queryParams}`;
    if (opts.queryString) {
      if (queryParams) query += '&';
      query += `${opts.queryString}`;
    }
  }
  return query;
}

function getFetchParams(opts) {
  const params = {
    headers: {
      ...baseHeaders,
      ...(opts.excludeNoCacheHeaders ? {} : noCacheHeaders),
      ...opts.headers
    },
    method: opts.method || 'GET'
  };
  if (opts.method) {
    opts.method = toUpper(opts.method);
    if (['POST', 'PUT'].includes(opts.method) && opts.data) {
      params.body = JSON.stringify(opts.data);
    }
  }
  return params;
}

function handleNotOkResponse(res) {
  if (res.ok) return res;
  if (res.status === 401) {
    // Unauthorized
    logout(true);
    if (!res.url.includes('/logout')) {
      window.location = '/login';
    }
  }
  const error = new Error(res.data.error || res.statusText || res.status);
  error.data = res.data;
  throw error;
}

async function request(opts = {}) {
  const baseURL = opts.baseURL || BASE_URL;
  const query = getQueryString(opts);
  const url = `${baseURL}/${opts.url}${query}`;
  const params = getFetchParams(opts);
  const response = await fetch(url, params);
  if (response.headers.get('Content-Type') === 'application/json') {
    const json = await response.json();
    response.data = json;
  } else if (response.headers.get('Content-Type') === 'application/pdf') {
    const blob = await response.blob();
    response.data = blob;
  } else {
    const text = await response.text();
    response.data = text;
  }
  return handleNotOkResponse(response);
}

export default request;
