import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeConsumer } from './ThemeProvider';

const HelpModalStyle = {
  modalDialog: {
    position: 'fixed',
    right: '25px',
    bottom: '80px',
    zIndex: '10040',
    width: '250px',
    display: 'none',
    borderRadius: '60px'
  },
  ModalContainer: {
    bottom: '100px',
    position: 'absolute'
  },
  panel: {
    backgroundColor: '#fff',
    border: '1px solid transparent',
    borderRadius: '4px',
    borderColor: '#ddd'
  },
  panelTop: {
    padding: '15px',
    backgroundColor: 'transparent'
  },
  pannelBody: {
    padding: '15px',
    height: '100%',
    backgroundColor: 'transparent'
  },
  title: {
    fontWeight: '200',
    textAlign: 'center',
    fontSize: '15px'
  },
  subttitle: {
    fontWeight: '200',
    textAlign: 'center',
    fontSize: '12px'
  },
  icon: {
    color: 'rgb(32,55,92)'
  },
  supportText: {
    fontSize: '13px',
    fontWeight: '800'
  },
  liaisonTitle: {
    fontWeight: '800',
    color: 'rgba(25,25,25,0.5)',
    fontSize: '10px',
    marginBottom: '0',
    marginLeft: '-10px'
  },
  calendarIcon: {
    color: 'rgb(32,55,92)',
    transform: 'scale(1.143,1)',
    paddingLeft: '1px'
  }
};

class HelpModal extends Component {
  render() {
    return (
      <ThemeConsumer>
        {({ theme }) => (
          <div className="help-modal-container">
            <div
              id="helpModal"
              tabIndex="-1"
              role="dialog"
              data-bs-backdrop="false"
              aria-labelledby=""
              aria-hidden="true"
              style={HelpModalStyle.modalDialog}
              className="modal-dialog"
            >
              <div className="modal-content help">
                <div
                  style={HelpModalStyle.panel}
                  className="panel panel-default panel-profile m-b-0"
                >
                  {theme?.liaisonPhoto?.src && (
                    <div
                      style={HelpModalStyle.panelTop}
                      className="panel-top text-center"
                    >
                      <picture>
                        <img
                          className="panel-profile-img"
                          src={theme?.liaisonPhoto?.src}
                          alt={theme?.liaisonPhoto?.alt}
                        />
                      </picture>
                      <img />
                      <button
                        type="button"
                        className="close float-right"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={this.props.onClick}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                  <div style={HelpModalStyle.pannelBody} className="panel-body">
                    <h2 className="have-questions">{theme?.helpQuestion}</h2>
                    <hr />
                    <h3 className="contact-team">{theme?.helpSubTitle}</h3>
                    {/* <div className="row justify-content-center">
                      <div className="col-2">
                        <a href={`mailto:${theme?.supportEmail}`}>
                          <i
                            style={HelpModalStyle.icon}
                            className="material-icons mail-container"
                          >
                            mail_outline
                          </i>
                        </a>
                      </div>
                      <div style={HelpModalStyle.supportText} className="col-9">
                        <a href={`mailto:${theme?.supportEmail}`}>
                          {theme?.supportEmail}
                        </a>
                      </div>
                    </div> */}
                    {theme?.supportPhoneNumber && (
                      <div className="row justify-content-center">
                        <div className="col-2">
                          <a href={`tel:${theme?.supportPhoneNumber}`}>
                            <i
                              style={HelpModalStyle.icon}
                              className="material-icons phone-container"
                            >
                              call
                            </i>
                          </a>
                        </div>
                        <div
                          style={HelpModalStyle.supportText}
                          className="col-9"
                        >
                          <a href={`tel:${theme?.supportPhoneNumber}`}>
                            {theme?.supportPhoneNumber}
                          </a>
                        </div>
                      </div>
                    )}
                    {theme?.scheduleCallLink && (
                      <div className="justify-content-center">
                        <a
                          href={theme?.scheduleCallLink}
                          target="_blank"
                          className="schedule-call"
                          rel="noopener noreferrer"
                          data-cy="calendly-link"
                        >
                          {theme?.helpButtonText}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="arrow" />
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

HelpModal.propTypes = {
  onClick: PropTypes.any
};
export default HelpModal;
