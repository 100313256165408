import React from 'react';
import { useTheme } from './ThemeProvider';

const LandingPageSupport = () => {
  const {
    theme: {
      supportSectionTitle,
      supportSectionSubtitle,
      supportEmail,
      supportPhoneNumber,
      scheduleCallLink,
      scheduleCallLinkText
    }
  } = useTheme();

  return (
    <div id="landing-page-support" className="landing-page-support" data-aos="fade-up">
      <div className="container justify-content-center">
        <div className="text-center">
          <h2>{supportSectionTitle || 'Questions?'}</h2>
          <h3>{supportSectionSubtitle || 'Contact our team.'}</h3>
          {supportEmail ? (
            <h4>
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </h4>
          ) : null}
          {supportPhoneNumber && (
            <h4>
              <a href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</a>
            </h4>
          )}
          {scheduleCallLink && (
            <div className="schedule-container">
              <a
                href={scheduleCallLink}
                target="_blank"
                className="schedule-call"
                rel="noopener noreferrer"
                data-cy="calendly-link"
              >
                {scheduleCallLinkText || 'Schedule a call'}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPageSupport;
