export const SUPPORTED_QUESTION_TYPES = {
  text: 'text',
  radio: 'radio',
  number: 'number',
  date: 'date',
  time: 'time'
};

export const QUESTION_TYPES = {
  ...SUPPORTED_QUESTION_TYPES,
  slider: 'slider',
  image: 'image',
  multipleChoice: 'multiple choice',
  composedAnswer: 'composed answer'
};
