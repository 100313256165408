import { NavLink, Link } from 'react-router-dom-v5-compat';
import styled, { css } from 'styled-components';

export const GhostBtn = styled.button`
  min-width: 160px;
  padding: 12px 16px;
  background-color: transparent;
  box-shadow: 0;
  border: 0;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  margin-top: 8px;
`;

export const DismissAlertBtn = styled.button`
  margin: 0;
  border: 0;
  margin-left: 8px;
`;

const buttonStyles = css`
  display: flex;
  height: 44px;
  width: fit-content;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border-style: none;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
`;

export const PrimaryButton = styled.button<{
  $type?: 'primary' | 'alert';
  $minWidth?: string;
}>`
  ${buttonStyles}
  background-color: ${({ $type = 'primary', theme }) =>
    theme.colors.action[$type]};
  color: ${({ theme }) => theme.colors.text.inverse};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: ${({ theme }) => theme.font.lineHeight.sm};
  min-width: ${({ $minWidth = '100px' }) => $minWidth};

  &:hover {
  background-color: ${({ $type = 'primary', theme }) =>
    theme.colors.action[$type]};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
    background-color: ${({ theme }) => theme.colors.action.disabled.primary};
  }
`;

export const SecondaryButton = styled.button`
  ${buttonStyles}
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.action.primary};
  background-color: ${({ theme }) => theme.colors.action.secondary};
  color: ${({ theme }) => theme.colors.action.primary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: ${({ theme }) => theme.font.lineHeight.sm};
  min-width: 100px;

  .sc-icon {
    background-color: ${({ theme }) => theme.colors.action.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.action.hover.primary};
    border-color: ${({ theme }) => theme.colors.action.hover.primary};
    background-color: ${({ theme }) => theme.colors.action.hover.secondary};
    .sc-icon {
      background-color: ${({ theme }) => theme.colors.action.hover.primary};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
    border-color: ${({ theme }) => theme.colors.action.disabled.primary};
    background-color: ${({ theme }) => theme.colors.action.disabled.secondary};
    .sc-icon {
      background-color: ${({ theme }) => theme.colors.text.disabled};
    }
  }
`;

export const GhostButton = styled.button`
  ${buttonStyles}
  color: ${({ theme }) => theme.colors.action.primary};
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: ${({ theme }) => theme.font.lineHeight.sm};
`;

const linkButtonStyles = css`
  ${buttonStyles}
  color: ${({ theme }) => theme.colors.action.primary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: ${({ theme }) => theme.font.lineHeight.sm};

  &:hover {
    color: ${({ theme }) => theme.colors.action.hover.primary};
  }
`;

export const TextButton = styled.button`
  ${buttonStyles}
  background-color: transparent;
  color: ${({ theme }) => theme.colors.action.secondary};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const TextLinkButton = styled.button<{
  $removePadding?: 'block-start' | 'inline-end' | 'block-end' | 'inline-start';
}>`
  ${linkButtonStyles}
  background-color: transparent;
  ${({ $removePadding }) =>
    $removePadding ? `padding-${$removePadding}: 0;` : ''}
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const LinkButton = styled(Link)`
  ${linkButtonStyles}
`;

export const NavLinkButton = styled(NavLink)`
  ${linkButtonStyles}
`;

export const IconButton = styled.button`
  ${buttonStyles}
  align-self: center;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  .sc-icon {
    background-color: ${({ theme }) => theme.colors.action.secondary};
  }
`;

export const AlertButton = styled.button`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.action.alert};
  color: ${({ theme }) => theme.colors.text.inverse};
`;

export const ResendInviteButton = styled.button`
  ${linkButtonStyles}
  padding-top: 0;
  padding-bottom: 0;
  height: auto;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
