import CryptoJS from 'crypto-js';
import config from '../config/config';

/**
 * Decrypt a data using crypto.
 * @param {*} data The data to decrypt.
 * @returns {string} The data decrypted.
 */
export function decrypt(data) {
  const key = atob(config.HRE_SALT);
  return CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
}

export default undefined;
