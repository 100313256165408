import styled, { css } from 'styled-components';

type TextProps = {
  $fontWeight?: number;
  $color?: string;
  $align?: 'left' | 'center' | 'right';
  $margin?: string;
};

const commonTextStyles = css<TextProps>`
  font-family: ${({ theme }) => theme.font.family};
  color: ${({ theme, $color }) => $color || theme.colors.text.primary};
  text-align: ${({ $align = 'left' }) => $align};
  margin: ${({ $margin = '0' }) => $margin};
  font-style: normal;
`;

const commonHeaderStyles = css`
  margin: initial;
`;

export const H1 = styled.h1<TextProps & { $textTransform?: string }>`
  ${commonHeaderStyles}
  ${commonTextStyles}
  font-weight: ${({ $fontWeight }) => $fontWeight || 700};
  font-size: ${({ theme }) => theme.font.size.xxl};
  line-height: ${({ theme }) => theme.font.lineHeight.xxl};
  text-transform: ${({ $textTransform = 'capitalize' }) => $textTransform};
`;

export const H2 = styled.h2<TextProps & { $textTransform?: string }>`
  ${commonHeaderStyles}
  ${commonTextStyles}
  font-weight: ${({ $fontWeight }) => $fontWeight || 400};
  font-size: ${({ theme }) => theme.font.size.xl};
  line-height: ${({ theme }) => theme.font.lineHeight.xl};
  text-transform: ${({ $textTransform = 'capitalize' }) => $textTransform};
`;

export const H3 = styled.h3<TextProps>`
  ${commonHeaderStyles}
  ${commonTextStyles}
  font-weight: ${({ $fontWeight }) => $fontWeight || 700};
  font-size: ${({ theme }) => theme.font.size.lg};
  line-height: ${({ theme }) => theme.font.lineHeight.lg};
`;

export const H4 = styled.h4<TextProps>`
  ${commonHeaderStyles}
  ${commonTextStyles}
  font-weight: ${({ $fontWeight }) => $fontWeight || 700};
  font-size: ${({ theme }) => theme.font.size.md};
  line-height: ${({ theme }) => theme.font.lineHeight.md};
`;

export const Body1Styles = css`
  ${commonTextStyles}
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: ${({ theme }) => theme.font.lineHeight.sm};
`;

export const Body2Styles = css`
  ${commonTextStyles}
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: ${({ theme }) => theme.font.lineHeight.xs};
  letter-spacing: 0.12px;
`;

export const Body3Styles = css`
  ${commonTextStyles}
  font-size: ${({ theme }) => theme.font.size.xxs};
  line-height: ${({ theme }) => theme.font.lineHeight.xxs};
  letter-spacing: 0.12px;
`;

type BodyProps = {
  $bold?: boolean;
};

export const Body1Div = styled.div<BodyProps & Omit<TextProps, '$fontWeight'>>`
  ${Body1Styles}
  font-weight: ${({ $bold }) => ($bold ? '700' : '400')};
`;

export const Body2Div = styled.div<BodyProps & Omit<TextProps, '$fontWeight'>>`
  ${Body2Styles}
  font-weight: ${({ $bold }) => ($bold ? '700' : '400')};
`;

export const Body3Div = styled.div<BodyProps & Omit<TextProps, '$fontWeight'>>`
  ${Body3Styles}
  font-weight: ${({ $bold }) => ($bold ? '700' : '400')};
`;
