import { flattenModel } from '../../../actions/utils';
import { SUPPORTED_QUESTION_TYPES } from '../../../common';
import { formatShortDateServer } from '../../../utils/date';
import {
  INPUT_ID_KEYS,
  USER_INFORMATION_KEYS
} from '../../UserInformationForm/mappers';
import { exists } from '../../../utils/existsValidation';
import { trimToLowerCase } from '../../../utils/strings';

const mapToUserInformationViewModel = prnOption => {
  const { registrationPage, prnOptionId } = prnOption;
  const registrationFields = registrationPage?.fields;
  const userInformationFields = registrationFields?.userInformation;
  const relationshipOptionFields = registrationFields?.relationshipOptions?.[0];
  if (!userInformationFields || !relationshipOptionFields) return {};

  return {
    userInformation: flattenModel(userInformationFields),
    relationshipOption: flattenModel(relationshipOptionFields),
    prnOptionId
  };
};

const mapToScreenTextsViewModel = prnOption => {
  const { registrationPage } = prnOption;
  const registrationFields = registrationPage?.fields;
  if (!registrationFields) return {};

  return {
    qualificationMessage: registrationFields.qualificationMessage,
    disqualificationMessage: registrationFields.disqualificationMessage,
    headerText: registrationFields.headerText,
    submitDataErrorMessageTitle: registrationFields.submitDataErrorMessageTitle,
    submitDataButtonText: registrationFields.submitDataButtonText
  };
};

export const mapToQuestionsViewModel = (prnOption, fieldName) => {
  const { registrationPage } = prnOption;
  const rawQuestions = registrationPage?.fields?.[fieldName];
  if (!rawQuestions) return {};

  const questions = rawQuestions
    .map(question => flattenModel(question, { includeSysVersion: true }))
    .filter(question => {
      return Object.values(SUPPORTED_QUESTION_TYPES).includes(
        question.questionType
      );
    });
  return { [fieldName]: questions };
};

export const mapToRegistrationViewModel = prnOption => {
  if (!prnOption) return {};

  return {
    ...mapToUserInformationViewModel(prnOption),
    ...mapToQuestionsViewModel(prnOption, 'preScreeningQuestions'),
    ...mapToScreenTextsViewModel(prnOption)
  };
};

export function splitUserInformationViews(userInformation) {
  const demographicUserInformation = {};
  const credentialsUserInformation = {};
  Object.entries(userInformation).forEach(([key, value]) => {
    if (
      [
        USER_INFORMATION_KEYS.emailField,
        USER_INFORMATION_KEYS.passwordField,
        USER_INFORMATION_KEYS.confirmPasswordField
      ].includes(key)
    ) {
      credentialsUserInformation[key] = value;
    } else {
      demographicUserInformation[key] = value;
    }
  });
  return { demographicUserInformation, credentialsUserInformation };
}

export const splitFormDataByPrefix = (data, prefix) => {
  const { dataWithoutPrefix, dataWithPrefix } = Object.entries(data).reduce(
    (acc, [key, value]) => {
      if (key.startsWith(prefix)) {
        acc.dataWithPrefix[key.replace(prefix, '')] = value;
      } else {
        acc.dataWithoutPrefix[key] = value;
      }
      return acc;
    },
    { dataWithoutPrefix: {}, dataWithPrefix: {} }
  );
  return { dataWithoutPrefix, dataWithPrefix };
};

const formatAnswerToApi = (answer, question) => {
  if (question.questionType === SUPPORTED_QUESTION_TYPES.date) {
    return formatShortDateServer(answer);
  }
  return answer;
};

export const mapToQuestionsPayload = (
  questionsFormSubmissionData = {},
  allQuestions = []
) => {
  return allQuestions
    .filter(question => exists(questionsFormSubmissionData[question.id]))
    .map(question => {
      const questionAnswer = formatAnswerToApi(
        questionsFormSubmissionData[question.id],
        question
      );
      const questionOption = question.questionOptions?.find(
        option => option.value === questionAnswer
      );
      return {
        sysId: question.sysId,
        version: question.sysVersion,
        id: question.id,
        question: question.label,
        description: question.description,
        optionSysId: questionOption?.sysId,
        optionVersion: questionOption?.sysVersion,
        answer: questionOption?.value || questionAnswer,
        answerText: questionOption?.label || questionAnswer
      };
    });
};

export const mapToRegistrationPayload = (formSubmissionData, allQuestions) => {
  const {
    programName,
    diseaseCode,
    programType,
    prnOptionId,
    relationshipOption,
    [INPUT_ID_KEYS.email]: email,
    [INPUT_ID_KEYS.phoneNumber]: phoneNumber,
    [INPUT_ID_KEYS.birthday]: birthday,
    [INPUT_ID_KEYS.diagnosisDate]: diagnosisDate,
    preScreeningQuestionsFormData,
    ...restData
  } = formSubmissionData;
  const userInformationPayload = {
    ...restData,
    [INPUT_ID_KEYS.email]: email && trimToLowerCase(email),
    [INPUT_ID_KEYS.phoneNumber]:
      phoneNumber && `+1${phoneNumber.replace(/\D/g, '').trim()}`,
    [INPUT_ID_KEYS.birthday]: birthday && formatShortDateServer(birthday),
    [INPUT_ID_KEYS.diagnosisDate]:
      diagnosisDate && formatShortDateServer(diagnosisDate)
  };
  const screeningQuestions = mapToQuestionsPayload(
    preScreeningQuestionsFormData,
    allQuestions
  );

  const payload = {
    ...userInformationPayload,
    screeningQuestions,
    program: diseaseCode && programType && `${diseaseCode}__${programType}`,
    brand: programName,
    disease: diseaseCode,
    prnOption: prnOptionId,
    userType: relationshipOption?.value,
    source: 'web'
  };

  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([, value]) => value !== undefined)
  );
  return filteredPayload;
};
