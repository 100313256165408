import { mapValues } from 'lodash';

// Authentication Action Types
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// User Action Types
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const SET_PROFILE = 'SET_PROFILE';
//Get Consent by ID
export const FETCH_CONSENT_START = 'FETCH_CONSENT_START';
export const FETCH_CONSENT_SUCCESS = 'FETCH_CONSENT_SUCCESS';
export const FETCH_CONSENT_FAILURE = 'FETCH_CONSENT_FAILURE';

//Get Consent from contentful
export const FETCH_CONTENTFUL_CONSENT_START = 'FETCH_CONTENTFUL_CONSENT_START';
export const FETCH_CONTENTFUL_CONSENT_SUCCESS =
  'FETCH_CONTENTFUL_CONSENT_SUCCESS';
export const FETCH_CONTENTFUL_CONSENT_FAILURE =
  'FETCH_CONTENTFUL_CONSENT_FAILURE';

// Consents Action Types
export const FETCH_CONSENTS_START = 'FETCH_CONSENTS_START';
export const FETCH_CONSENTS_SUCCESS = 'FETCH_CONSENTS_SUCCESS';
export const FETCH_CONSENTS_FAILURE = 'FETCH_CONSENTS_FAILURE';

// Consents Action Types
export const FETCH_STATUS_START = 'FETCH_STATUS_START';
export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_FAILURE = 'FETCH_STATUS_FAILURE';

// Inbox Action Types
export const LOAD_INBOX_START = 'LOAD_INBOX_START';
export const LOAD_INBOX_SUCCESS = 'LOAD_INBOX_SUCCESS';
export const LOAD_INBOX_FAILURE = 'LOAD_INBOX_FAILURE';

// Inbox Action Types
export const UPDATE_INBOX_ITEM_START = 'UPDATE_INBOX_ITEM_START';
export const UPDATE_INBOX_ITEM_SUCCESS = 'UPDATE_INBOX_ITEM_SUCCESS';
export const UPDATE_INBOX_ITEM_FAILURE = 'UPDATE_INBOX_ITEM_FAILURE';

// Patients Action Types
export const FETCH_PATIENTS_START = 'FETCH_PATIENTS_START';
export const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS';
export const FETCH_PATIENTS_FAILURE = 'FETCH_PATIENTS_FAILURE';

// Patients by Doctor Action Types
export const FETCH_PATIENTS_BY_DOCTOR_START = 'FETCH_PATIENTS_BY_DOCTOR_START';
export const FETCH_PATIENTS_BY_DOCTOR_SUCCESS =
  'FETCH_PATIENTS_BY_DOCTOR_SUCCESS';
export const FETCH_PATIENTS_BY_DOCTOR_FAILURE =
  'FETCH_PATIENTS_BY_DOCTOR_FAILURE';

// Single Patient Action Types
export const FETCH_SINGLE_PATIENT_START = 'FETCH_SINGLE_PATIENT_START';
export const FETCH_SINGLE_PATIENT_SUCCESS = 'FETCH_SINGLE_PATIENT_SUCCESS';
export const FETCH_SINGLE_PATIENT_FAILURE = 'FETCH_SINGLE_PATIENT_FAILURE';

// Providers Action Types
export const FETCH_PROVIDERS_START = 'FETCH_PROVIDERS_START';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_FAILURE = 'FETCH_PROVIDERS_FAILURE';

// Records Action Types
export const INIT_RECORDS_SUCCESS = 'INIT_RECORDS_SUCCESS';
export const INIT_RECORDS_FAILURE = 'INIT_RECORDS_FAILURE';

// Record invitations Action Types
export const FETCH_RECORDS_INVITATIONS_START =
  'FETCH_RECORDS_INVITATIONS_START';
export const FETCH_RECORDS_INVITATIONS_SUCCESS =
  'FETCH_RECORDS_INVITATIONS_SUCCESS';
export const FETCH_RECORDS_INVITATIONS_FAILURE =
  'FETCH_RECORDS_INVITATIONS_FAILURE';

// Set Selected Request Action Types
export const SET_SELECTED_REQUEST_SUCCESS = 'SET_SELECTED_REQUEST_SUCCESS';
export const SET_SELECTED_REQUEST_FAILURE = 'SET_SELECTED_REQUEST_FAILURE';

export const FETCH_CONSENT_TEMPLATES_START = 'FETCH_CONSENT_TEMPLATES_START';
export const FETCH_CONSENT_TEMPLATES_SUCCESS =
  'FETCH_CONSENT_TEMPLATES_SUCCESS';
export const FETCH_CONSENT_TEMPLATES_FAILURE =
  'FETCH_CONSENT_TEMPLATES_FAILURE';

// User Index Action Types
export const FETCH_USER_INDEX_START = 'FETCH_USER_INDEX_START';
export const FETCH_USER_INDEX_SUCCESS = 'FETCH_USER_INDEX_SUCCESS';
export const FETCH_USER_INDEX_FAILURE = 'FETCH_USER_INDEX_FAILURE';

// Doctors Action Types
export const FETCH_RECORDS_BY_TYPE_START = 'FETCH_RECORDS_BY_TYPE_START';
export const FETCH_RECORDS_BY_TYPE_SUCCESS = 'FETCH_RECORDS_BY_TYPE_SUCCESS';
export const FETCH_RECORDS_BY_TYPE_FAILURE = 'FETCH_RECORDS_BY_TYPE_FAILURE';

// Doctor by Id Action Types
export const FETCH_DOCTOR_BY_ID_START = 'FETCH_DOCTOR_BY_ID_START';
export const FETCH_DOCTOR_BY_ID_SUCCESS = 'FETCH_DOCTOR_BY_ID_SUCCESS';
export const FETCH_DOCTOR_BY_ID_FAILURE = 'FETCH_DOCTOR_BY_ID_FAILURE';

// Providers by patient Action Types
export const FETCH_PROVIDERS_BY_PATIENT_START =
  'FETCH_PROVIDERS_BY_PATIENT_START';
export const FETCH_PROVIDERS_BY_PATIENT_SUCCESS =
  'FETCH_PROVIDERS_BY_PATIENT_SUCCESS';
export const FETCH_PROVIDERS_BY_PATIENT_FAILURE =
  'FETCH_PROVIDERS_BY_PATIENT_FAILURE';

const START = 'START';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
export const CLEAR = 'CLEAR';

export function createFetchActionTypes(name) {
  return mapValues({ START, SUCCESS, FAILURE, CLEAR }, type => {
    return `FETCH_${name}_${type}`;
  });
}

export const SET_USER_LOCALE = 'SET_USER_LOCALE';
