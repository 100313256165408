import React, { useCallback, useMemo, useState } from 'react';
import useLocale from '@src/hooks/useLocale';
import { cleanLocaleName } from '@src/Components/LocalePicker/utils';
import {
  Icon,
  LocaleOption,
  OptionsContainer,
  SelectorContainer
} from '../atoms/localeSelector';

type Locale = {
  code: string;
  default: boolean;
  fallbackCode: string;
  name: string;
};

const LocaleSelector = () => {
  const { locales, userLocale, selectUserLocale } = useLocale();
  const [open, setOpen] = useState(false);

  const handleLocaleSelect = useCallback(
    (locale: Locale) => selectUserLocale(locale.code),
    [selectUserLocale]
  );

  const toggleOpen = useCallback(() => setOpen(prev => !prev), []);

  const selectedLocale = useMemo(() => {
    return (
      locales.find((locale: Locale) => locale.code === userLocale) || {
        code: 'en-US',
        name: 'English'
      }
    );
  }, [userLocale, locales]);

  return (
    <SelectorContainer
      onClick={toggleOpen}
      $open={open}
      data-testid="locale-selector-container"
    >
      <Icon $url={'/portal/planet.svg'} />
      {cleanLocaleName(selectedLocale, true)}
      <Icon $url={'/portal/chevron-down.svg'} $open={open} />
      {open && (
        <OptionsContainer data-testid="locale-selector-options-container">
          {locales.map((locale: Locale) => {
            const selected = locale.code === userLocale;
            return (
              <LocaleOption
                key={locale.code}
                onClick={() => handleLocaleSelect(locale)}
                $selected={selected}
              >
                {cleanLocaleName(locale, true)}
                {selected && <Icon $url={'/portal/done.svg'} $size={16} />}
              </LocaleOption>
            );
          })}
        </OptionsContainer>
      )}
    </SelectorContainer>
  );
};
export default LocaleSelector;
