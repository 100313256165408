import * as types from '../constants/actionTypes';
import StateLoader from './StateLoader';
const stateLoader = new StateLoader();

export default function userReducer(
  state = stateLoader.initializeState().user,
  action
) {
  switch (action.type) {
    case types.FETCH_CONSENTS_START: {
      return {
        ...state,
        consents: { ...state.consents, consents: [], isLoading: true }
      };
    }
    case types.FETCH_CONSENTS_SUCCESS: {
      return {
        ...state,
        consents: { ...state.consents, ...action.response, isLoading: false }
      };
    }
    case types.FETCH_CONSENTS_FAILURE:
      return {
        ...state,
        consents: { ...state.consents, consents: [], isLoading: false }
      };
    case types.FETCH_CONSENT_START: {
      return {
        ...state,
        consents: {
          ...state.consents,
          activeUserConsent: null,
          isLoadingConsent: true
        }
      };
    }
    case types.FETCH_CONSENT_SUCCESS: {
      return {
        ...state,
        consents: {
          ...state.consents,
          activeUserConsent: action.response,
          isLoadingConsent: false
        }
      };
    }
    case types.FETCH_CONSENT_FAILURE:
      return {
        ...state,
        consents: {
          ...state.consents,
          activeUserConsent: null,
          isLoadingConsent: false
        }
      };
    case types.FETCH_CONTENTFUL_CONSENT_START: {
      return {
        ...state,
        consents: {
          ...state.consents,
          contentfulConsent: null,
          isLoadingConsent: true
        }
      };
    }
    case types.FETCH_CONTENTFUL_CONSENT_SUCCESS: {
      return {
        ...state,
        consents: {
          ...state.consents,
          contentfulConsent: action.response,
          isLoadingConsent: false
        }
      };
    }
    case types.FETCH_CONTENTFUL_CONSENT_FAILURE:
      return {
        ...state,
        consents: {
          ...state.consents,
          contentfulConsent: null,
          isLoadingConsent: false
        }
      };
    case types.FETCH_STATUS_START: {
      return {
        ...state
      };
    }
    case types.FETCH_STATUS_SUCCESS: {
      return {
        ...state,
        shrStatus: action.response.status
      };
    }
    case types.FETCH_STATUS_FAILURE:
      return {
        ...state
      };
    case types.LOAD_INBOX_START: {
      return {
        ...state,
        inbox: { inbox: [], isLoading: true }
      };
    }
    case types.LOAD_INBOX_SUCCESS: {
      return {
        ...state,
        inbox: { inbox: action.response.data, isLoading: false }
      };
    }
    case types.LOAD_INBOX_FAILURE:
      return {
        ...state,
        inbox: { inbox: [], isLoading: false }
      };
    case types.FETCH_DOCTOR_BY_ID_START: {
      return {
        ...state,
        doctor: {
          data: {},
          isLoading: true
        }
      };
    }
    case types.FETCH_DOCTOR_BY_ID_SUCCESS: {
      return {
        ...state,
        doctor: {
          data: action.response,
          isLoading: false
        }
      };
    }
    case types.FETCH_DOCTOR_BY_ID_FAILURE:
      return {
        ...state,
        doctor: {
          data: {},
          isLoading: false,
          loadingError: action.response
        }
      };
    default:
      return state;
  }
}
