import { useReducer, useEffect, useCallback } from 'react';
import { getUserStudyFromProgram } from '../utils/cms';

const ACTIONS = {
  FETCH_PRN_OPTION_INIT: 'FETCH_PRN_OPTION_INIT',
  FETCH_PRN_OPTION_SUCCESS: 'FETCH_PRN_OPTION_SUCCESS',
  FETCH_PRN_OPTION_FAILURE: 'FETCH_PRN_OPTION_FAILURE'
};

const STATUS = {
  IDLE: 'idle',
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading'
};

const initialState = { status: STATUS.IDLE, error: null, data: null };

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_PRN_OPTION_INIT:
      return { ...initialState, status: STATUS.LOADING };
    case ACTIONS.FETCH_PRN_OPTION_SUCCESS:
      return {
        ...state,
        status: STATUS.SUCCESS,
        data: action.payload,
        error: null
      };
    case ACTIONS.FETCH_PRN_OPTION_FAILURE:
      return { ...state, status: STATUS.ERROR, error: action.payload };
    default:
      return state;
  }
};

const usePRNOption = ({ diseaseCode, programType }) => {
  const [{ status, error, data }, dispatch] = useReducer(reducer, initialState);

  const fetchPrnOption = useCallback(async () => {
    dispatch({ type: ACTIONS.FETCH_PRN_OPTION_INIT });
    try {
      const prnOptions = await getUserStudyFromProgram(
        `${diseaseCode}__${programType}`
      );
      if (prnOptions.length === 0) {
        throw new Error(
          `PRN Option with disease code ${diseaseCode} and program type ${programType} not found`
        );
      }

      const data = prnOptions[0]?.fields;
      dispatch({ type: ACTIONS.FETCH_PRN_OPTION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: ACTIONS.FETCH_PRN_OPTION_FAILURE, payload: error });
    }
  }, [diseaseCode, programType]);

  useEffect(() => {
    fetchPrnOption();
  }, [fetchPrnOption]);

  return {
    isLoading: status === STATUS.LOADING || status === STATUS.IDLE,
    error,
    data,
    fetchPrnOption
  };
};

export default usePRNOption;
