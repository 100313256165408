import React, { Component } from 'react';
import BaseContainer from './BaseContainer';
import DashboardMyProviders from './../pages/DashboardMyProviders';
import PropTypes from 'prop-types';

class DashboardMyProvidersContainer extends Component {
  render() {
    const { history } = this.props;
    return (
      <BaseContainer {...this.props}>
        <DashboardMyProviders history={history} {...this.props} />
      </BaseContainer>
    );
  }
}

DashboardMyProvidersContainer.propTypes = {
  history: PropTypes.any
};

export default DashboardMyProvidersContainer;
