import Joi from 'joi';
import { createUserInformationSchemaObject } from './userInformationSchema';
import { createQuestionsSchemaObject } from './questionSchema';

export const createRegistrationFormSchema = ({
  userInformation = {},
  preScreeningQuestions = [],
  preScreeningQuestionsInputIdPrefix
}) => {
  const userInformationSchemaObject = createUserInformationSchemaObject(
    userInformation
  );
  const questionsSchemaObject = createQuestionsSchemaObject(
    preScreeningQuestions,
    { inputIdPrefix: preScreeningQuestionsInputIdPrefix }
  );
  return {
    schema: Joi.object({
      ...userInformationSchemaObject,
      ...questionsSchemaObject
    })
  };
};
