/* eslint-disable */
import { createSelector } from 'reselect';
import { getOr } from 'lodash/fp';

export const rootUserSelector = getOr({}, 'user');
export const selectUserConsents = createSelector(
  rootUserSelector,
  getOr({}, 'consents')
);

export const selectSignedConsents = createSelector(
  selectUserConsents,
  getOr([], 'signedConsents')
);

export const selectActiveUserConsentLoading = createSelector(
  selectUserConsents,
  getOr([], 'isLoading')
);

export const selectActiveUserConsent = createSelector(
  selectUserConsents,
  getOr({}, 'activeUserConsent')
);

export const selectActiveUserConsentSignature = createSelector(
  selectActiveUserConsent,
  getOr({}, 'signature')
);

export const selectSignedConsentId = createSelector(
  selectActiveUserConsent,
  getOr({}, 'signedConsentId')
);

export const selectActiveUserConsentContent = createSelector(
  selectActiveUserConsent,
  getOr(null, 'consent')
);

export const selectContentfulConsent = createSelector(
  selectUserConsents,
  getOr({}, 'contentfulConsent')
);

export const selectUnsignedConsents = createSelector(
  selectSignedConsents,
  selectContentfulConsent,
  (signedConsents, contentfulConsent) => {
    if (!contentfulConsent) {
      return [];
    }
    const signedVersions = signedConsents.map(getOr(0, 'icfVersion'));
    return signedVersions.includes(contentfulConsent.icfVersionNumber)
      ? []
      : [contentfulConsent];
  }
);
