import styled from 'styled-components';
import { Body1Styles } from './typography';

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ToggleVisibilityBtn = styled.button`
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: 700;
  margin: 0;
  border: 0;
  padding: 0;
  box-shadow: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.action.primary};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  width: 100%;
`;

export const FormInputWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 6px;
`;

type FormInputProps = { $icon?: string; $hasError?: boolean };
export const FormInput = styled.input<FormInputProps>`
  display: flex;
  min-height: 44px;
  padding: 3px 16px;
  align-items: center;
  align-self: stretch;
  outline-width: 1px;
  outline-color: ${({ theme }) => theme.colors.action.primary};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  ${({ $icon }) =>
    $icon
      ? `background: url(${$icon}) no-repeat 16px 8px;
    padding-left: 48px;`
      : ''}
  background-color: ${({ theme }) => theme.colors.surface.background.primary};
  flex: 1 0 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.font.lineHeight.sm};
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
    background-color: ${({ theme }) => theme.colors.action.disabled.secondary};
  }
  ${({ theme, $hasError }) =>
    $hasError ? `border-color: ${theme.colors.border.negative};` : ''};
`;

export const ClearInputBtn = styled.button`
  display: flex;
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 16px;
  top: 10px;
  background: url(/portal/clear.svg) no-repeat center;
  border: 0;
  &:focus {
    outline: none;
  }
`;

export const FormTextarea = styled.textarea`
  display: flex;
  min-height: 176px;
  padding: 16px 15px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  background: ${({ theme }) => theme.colors.surface.background.primary};
  ${Body1Styles}

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

export const FormInputLabel = styled.label`
  display: flex;
  align-items: center;
  align-self: stretch;
  margin: 0;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.font.lineHeight.sm};
`;

export const FormInputError = styled.div`
  align-self: stretch;
  color: ${({ theme }) => theme.colors.text.negative};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.font.lineHeight.txs};
  letter-spacing: 0.12px;
`;

export const DropdownInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const DropdownInputSelectedItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type CheckboxInputProps = { $isSelected?: boolean };
export const CheckboxInput = styled.input.attrs<CheckboxInputProps>(
  ({ $isSelected }) => ({
    type: 'checkbox',
    checked: $isSelected,
    onChange: () => {}
  })
)`
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};

  &::before {
    content: '';
    width: 1.125rem;
    height: 1.125rem;

    clip-path: path('M 13.5 7.5 L 8 13 L 4.5 9.5 L 5.5 8.5 L 8 11 L 12.5 6.5');
    transform: scale(0);
    background-color: ${({ theme }) => theme.colors.action.secondary};
  }
  &:checked {
    background-color: ${({ theme }) => theme.colors.action.primary};
  }
  &:checked::before {
    transform: scale(1);
  }
`;
