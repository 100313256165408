import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { useTheme } from '../ThemeProvider';

function JoinNowBtn({
  history,
  diseaseCode,
  programType,
  referral,
  scrollRef,
  action = () => {}
}) {
  const { theme, referToken } = useTheme();

  const joinNow = useCallback(() => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Join Now Button'
    });
    if (diseaseCode) {
      const token = referral || referToken;
      const nextPath = `/policies/${diseaseCode}/${programType}?r=${token}`;
      history.push(nextPath);
    } else {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth'
      });
      action();
    }
  }, [
    diseaseCode,
    programType,
    referral,
    referToken,
    history,
    scrollRef,
    action
  ]);
  return (
    <button className="join-now" onClick={joinNow} data-testid="join-now-btn">
      {theme?.landingPage?.joinButtonTitle}
    </button>
  );
}

JoinNowBtn.propTypes = {
  action: PropTypes.any,
  history: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  referral: PropTypes.string,
  diseaseCode: PropTypes.string,
  programType: PropTypes.string,
  scrollRef: PropTypes.any
};
export default JoinNowBtn;
