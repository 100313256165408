import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import Nav from './Nav';
import { client } from '../utils/cms.js';

class Privacy extends Component {
  state = {
    policy: null
  };

  componentDidMount() {
    client
      .getEntries({
        content_type: 'policy',
        'fields.types': this.props.type,
        include: 10
      })
      .then(entries => {
        let policy = entries[0];
        this.setState({
          policy: policy
        });
      });
  }

  render() {
    if (this.state.policy) {
      return (
        <div>
          <Nav linkTo="/" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-9 mt-5">
                <h1>{this.state.policy.fields.title}</h1>
                <ReactMarkdown>
                  {this.state.policy.fields.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default Privacy;
