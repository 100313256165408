import PropTypes from 'prop-types';
import React from 'react';

export default function ErrorPlaceholder({
  title = 'Error!',
  subTitle,
  errorMessage,
  retryButtonText = 'Try again',
  onRetryClick = () => {}
}) {
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <h2>{title}</h2>
      {subTitle ? <p>{subTitle}</p> : null}
      {errorMessage ? <p>{errorMessage}</p> : null}
      <button onClick={onRetryClick}>{retryButtonText}</button>
    </div>
  );
}

ErrorPlaceholder.propTypes = {
  title: PropTypes.string,
  errorMessage: PropTypes.string,
  retryButtonText: PropTypes.string,
  subTitle: PropTypes.string,
  onRetryClick: PropTypes.func
};
