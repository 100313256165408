import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import useFeatureConfiguration from '../../hooks/useFeatureConfiguration.js';
import { useTheme } from '../ThemeProvider.js';
import MedfusionButton from './MedfusionButton.js';
import SHRLoadingError from './SHRLoadingError.js';

const messageStyle = { fontWeight: '200', fontSize: '25px' };
const loadingStyle = {
  borderRadius: '150px',
  width: '150px',
  height: '150px',
  backgroundImage: "url('/LoadGif.gif')",
  backgroundSize: 'cover',
  backgroundPosition: 'center'
};

export default function SHRLoading(props) {
  const {
    theme: { secondaryColor }
  } = useTheme();
  const config = useFeatureConfiguration();
  const { shrStatus } = props;
  const title =
    config.shrLoadingPage?.title || 'We Are Building Your Smart Health Record!';
  const renderConfig = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="col-10" style={messageStyle}>
          {children}
        </p>
      )
    }
  };
  if (shrStatus === 3) {
    return <SHRLoadingError />;
  }
  return (
    <div className="my-3 p-3 bg-white rounded box-shadow ml-15">
      <div
        className="dashboard-item-banner"
        style={{ backgroundColor: secondaryColor }}
      >
        <h2 style={{ color: 'white', fontWeight: '200' }}>{title}</h2>
      </div>
      <div className="row justify-content-center">
        <div
          className="my-5"
          style={{
            ...loadingStyle,
            backgroundImage: `url('${config.shrLoadingPage?.image?.src ||
              '/LoadGif.gif'}')`
          }}
        />
        {config.shrLoadingPage?.content ? (
          documentToReactComponents(config.shrLoadingPage.content, renderConfig)
        ) : (
          <p className="col-10" style={messageStyle}>
            We are in the process of compiling your medical records and building
            your Smart Health Record. Once this process is complete we will
            notify you. Feel free to continue adding more of your doctors or
            providers using the &ldquo;Import Health Records&rdquo; button
            below.
          </p>
        )}
        <div className="col-12 justify-content-center text-center my-4">
          <MedfusionButton />
        </div>
      </div>
    </div>
  );
}

SHRLoading.propTypes = {
  shrStatus: PropTypes.any
};
