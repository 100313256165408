import React, { Component } from 'react';
import PropTypes from 'prop-types';

const messageStyle = { fontWeight: '200' };
class SHRInstructionStep extends Component {
  render() {
    const { modal } = this.props;
    if (modal) {
      return (
        <div className="col-12">
          <a
            role="button"
            data-bs-toggle="modal"
            data-bs-target={`#${modal}`}
            href="/home/onboarding"
          >
            <h5 className="medfusion-modal">{this.props.title}</h5>
          </a>
          <p style={messageStyle}>{this.props.message}</p>
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <h5>{this.props.title}</h5>
          <p
            style={messageStyle}
            dangerouslySetInnerHTML={{ __html: this.props.message }}
          />
        </div>
      );
    }
  }
}

SHRInstructionStep.propTypes = {
  message: PropTypes.any,
  modal: PropTypes.any,
  title: PropTypes.any
};
export default SHRInstructionStep;
