import React from 'react';
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, history, persistor } from './store/configureStore';
import Root from './Root';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

try {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store} history={history} key="provider">
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <CompatRouter>
                <Root />
              </CompatRouter>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  );

  if (window.Cypress) {
    window.store = store;
  }
} catch (e) {
  localStorage.removeItem('iPEP:state');
  window.location = '/';
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
