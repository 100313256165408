import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  upperFirst,
  toLower,
  flow,
  filter,
  includes,
  matches,
  some
} from 'lodash/fp';
import PropTypes from 'prop-types';

import { getEntries } from '../utils/cms.js';
import ResourcesList from './ResourcesList/ResourcesList.js';
import ReactGA from 'react-ga';
import useFeatureConfiguration from '../hooks/useFeatureConfiguration.js';

const sanitize = flow(toLower, upperFirst);

const getFormControlStyles = width => ({
  width: `${width}px`,
  border: 'none',
  height: '30px',
  borderRadius: '4px',
  fontSize: '12px',
  lineHeight: '16px',
  color: 'rgba(0,0,0,0.87)'
});

const filterByStyle = {
  height: '17px',
  width: '45px',
  color: '#fff',
  fontFamily: '"Helvetica Neue"',
  fontSize: '12px',
  lineHeight: '16px',
  textAlign: 'right',
  display: 'inline-block',
  bottom: '5px',
  position: 'relative',
  fontWeight: 'normal'
};

const includesTerm = (string, term) => includes(term, toLower(string));

const resourcesIncludesSearchTerm = (
  { title = '', newsdescription = '' },
  searchTerm
) =>
  includesTerm(title, searchTerm) || includesTerm(newsdescription, searchTerm);

const defaultAll = 'All Categories';

const DashboardResources = props => {
  const { profileSession: session, type } = props;
  const config = useFeatureConfiguration();
  const allCategories = useMemo(
    () => config.resourcesAllCategoriesItemLabel || defaultAll,
    [config.resourcesAllCategoriesItemLabel]
  );
  const [filterCategory, setFilterCategory] = useState(allCategories);
  const [featuredResources, setFeaturedResources] = useState([]);
  const [resources, setResources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { filteredResources, filteredFeaturedResources } = useMemo(() => {
    let filteredResources = resources;
    let filteredFeaturedResources = featuredResources;

    if (filterCategory !== allCategories) {
      const categoryFilterer = filter(
        resource => sanitize(resource.fields.category) === filterCategory
      );

      filteredResources = categoryFilterer(filteredResources);
      filteredFeaturedResources = categoryFilterer(filteredFeaturedResources);
    }

    if (searchTerm.length > 0) {
      const searchFilterer = filter(resource =>
        resourcesIncludesSearchTerm(resource.fields, searchTerm)
      );

      filteredResources = searchFilterer(filteredResources);
      filteredFeaturedResources = searchFilterer(filteredFeaturedResources);
    }
    return {
      filteredResources,
      filteredFeaturedResources
    };
  }, [resources, featuredResources, filterCategory, searchTerm, allCategories]);

  const updateSearchTerm = useCallback(e => setSearchTerm(e.target.value), []);

  const getResources = useCallback(async () => {
    if (!session.user) {
      return;
    }
    setIsLoading(true);
    let resources = [];
    let featuredResources = [];
    let categories = new Set();
    categories.add(allCategories);
    const { program } = session.user;
    const [diseaseCode, programId] = program.split('__');

    const entries = await getEntries({
      content_type: 'news',
      include: 10
    });

    const items = entries.filter(({ fields: { prnOptions } }) => {
      if (!prnOptions || prnOptions.length === 0) {
        return true;
      }
      return some(
        matches({
          fields: {
            disease: { fields: { diseaseCode } },
            program: { fields: { programId } }
          }
        })
      )(prnOptions);
    });

    items.forEach(entry => {
      categories.add(sanitize(entry.fields.category));
      if (entry.fields.featured) {
        featuredResources.push(entry);
      } else {
        resources.push(entry);
      }
    });
    setFeaturedResources(featuredResources);
    setResources(resources);
    setCategories(Array.from(categories));
    setIsLoading(false);
  }, [session.user, allCategories]);

  useEffect(() => {
    getResources();
  }, [type, getResources]);

  useEffect(() => {
    if (filterCategory === defaultAll && allCategories !== defaultAll) {
      setFilterCategory(allCategories);
    }
  }, [filterCategory, allCategories]);

  useEffect(() => {
    ReactGA.event({
      category: 'User',
      action: 'Resources Viewed'
    });
  }, []);

  return (
    <div className="my-3 mx-3 dashboard-item">
      <div
        className="card-body dashboard-item-banner resources-header"
        style={{ margin: '0px' }}
      >
        <div className="record-title">
          <span className="pl-14">
            {config.resourcesPageTitle || 'Resources'}
          </span>
        </div>
        <div className="record-filters resource-filters">
          <div className={`dropdown header-filters date-dropdown filter-by`}>
            <label className="mr-2 label" style={filterByStyle}>
              {config.resourcesFilterLabel || 'Filter by'}
            </label>
            <button
              className="btn form-control mr-2 facilities-btn dropdown-toggle filter-by-btn"
              type="button"
              id="dropdownMenu3"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={getFormControlStyles(160)}
              data-testid="dashboard-resources-filter-category-btn"
            >
              <span className="filter-category">{filterCategory}</span>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
              {categories.map((item, index) => (
                <button
                  key={item}
                  className={`dropdown-item`}
                  type="button"
                  onClick={() => setFilterCategory(item)}
                  data-testid={'dashboard-resources-category-btn-' + index}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <div className="input-container">
            <i className="icon icon-icn-zoom-2 search-icon" />
            <input
              type="text"
              style={{ ...getFormControlStyles(164), paddingLeft: '30px' }}
              value={searchTerm}
              data-testid="dashboard-resources-search-input"
              onChange={updateSearchTerm}
              className="form-control mr-2"
              placeholder={config.resourcesSearchPlaceholder || 'Search'}
            />
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="list-group resources-list">
          <ResourcesList
            isLoading={isLoading}
            resources={filteredResources}
            featuredResources={filteredFeaturedResources}
          />
        </div>
      </div>
    </div>
  );
};

DashboardResources.propTypes = {
  profileSession: PropTypes.any,
  type: PropTypes.any
};

export default DashboardResources;
