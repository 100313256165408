import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import useLocale from '../../hooks/useLocale';
import LocaleModal from './LocaleModal';
import valueContainerCreator from './ValueContainer';
import Option from './Option';
import { cleanLocaleName } from './utils';

function LocalePicker({ isMobile }) {
  const { locales, userLocale, selectUserLocale } = useLocale();
  const handleLocaleSelect = useCallback(
    locale => selectUserLocale(locale.value),
    [selectUserLocale]
  );
  const mappedLocales = useMemo(
    () =>
      locales.map(locale => ({
        value: locale.code,
        label: isMobile ? locale.code : cleanLocaleName(locale, true)
      })),
    [locales, isMobile]
  );
  return userLocale ? (
    <Select
      isSearchable={false}
      value={mappedLocales.find(({ value }) => value === userLocale)}
      onChange={handleLocaleSelect}
      options={mappedLocales}
      className={`custom-select locale-picker-select ${
        isMobile ? '' : 'd-block'
      }`}
      placeholder=""
      classNamePrefix="custom-select"
      controlShouldRenderValue={!isMobile}
      components={{
        Option,
        ValueContainer: valueContainerCreator(isMobile)
      }}
    />
  ) : (
    <LocaleModal locales={locales} onLocaleSelect={selectUserLocale} />
  );
}

LocalePicker.propTypes = {
  isMobile: PropTypes.bool
};

export default LocalePicker;
