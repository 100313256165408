import React from 'react';
import PropTypes from 'prop-types';

export default function valueContainerCreator(isMobile) {
  function ValueContainer(props) {
    const { children, className, cx, isMulti, getStyles, hasValue } = props;
    const style = getStyles('valueContainer', props);
    if (isMobile) {
      style.justifyContent = 'center';
    }
    return (
      <div
        style={style}
        className={cx(
          {
            'value-container': true,
            'value-container--is-multi': isMulti,
            'value-container--has-value': hasValue
          },
          className
        )}
      >
        <i className="fas fa-globe" />
        {children}
      </div>
    );
  }

  ValueContainer.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    cx: PropTypes.any,
    isMulti: PropTypes.any,
    getStyles: PropTypes.any,
    hasValue: PropTypes.any
  };

  return ValueContainer;
}
