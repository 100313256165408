import {
  FIELDS_CONFIG_MAPPING,
  INPUT_ID_KEYS
} from '../../UserInformationForm/mappers';
import Joi from 'joi';
import states from '../../../constants/states';
import zipCode from '../../../ts/utils/schemas/zipCode';

export const USER_INFORMATION_INPUTS_SCHEMA_RULES = {
  [INPUT_ID_KEYS.firstName]: Joi.string()
    .min(2)
    .max(40)
    .required(),
  [INPUT_ID_KEYS.lastName]: Joi.string()
    .min(2)
    .max(40)
    .required(),
  [INPUT_ID_KEYS.birthday]: Joi.date()
    .custom((value, helper) => {
      const date = new Date(value);
      const isValidDate =
        date.getTime() >= new Date('01/01/1900').getTime() &&
        new Date().getFullYear() - date.getFullYear() >= 18;
      return isValidDate ? value : helper.message('any.invalid');
    })
    .required(),
  [INPUT_ID_KEYS.diagnosisDate]: Joi.date()
    .custom((value, helper) => {
      const date = new Date(value);
      const isValidDate = date.getTime() >= new Date('01/01/1900').getTime();
      return isValidDate ? value : helper.message('any.invalid');
    })
    .required(),
  [INPUT_ID_KEYS.phoneNumber]: Joi.string()
    .pattern(/^\([0-9]{3}\)\s?[0-9]{3}-[0-9]{4}$/)
    .required(),
  [INPUT_ID_KEYS.zipCode]: zipCode.required(),
  [INPUT_ID_KEYS.state]: Joi.string()
    .valid(...states)
    .required(),
  [INPUT_ID_KEYS.email]: Joi.string()
    .email({ tlds: false })
    .required(),
  [INPUT_ID_KEYS.password]: Joi.string()
    .min(8)
    .max(20)
    .pattern(/[a-z]/)
    .message('Password must contain at least one lowercase letter')
    .pattern(/[A-Z]/)
    .message('Password must contain at least one uppercase letter')
    .pattern(/[0-9]/)
    .message('Password must contain at least one number')
    .pattern(/\W/)
    .message('Password must contain at least one symbol')
    .required(),
  [INPUT_ID_KEYS.confirmPassword]: Joi.string()
    .valid(Joi.ref(INPUT_ID_KEYS.password))
    .messages({ 'any.only': 'Passwords do not match' })
    .required()
};

export const createUserInformationSchemaObject = userInformation => {
  const userInformationSchemaObject = Object.keys(userInformation).reduce(
    (acc, userInformationFieldKey) => {
      const inputID = FIELDS_CONFIG_MAPPING[userInformationFieldKey]?.inputID;
      const rule = USER_INFORMATION_INPUTS_SCHEMA_RULES[inputID];
      if (!inputID || !rule) return acc;

      return { ...acc, [inputID]: rule };
    },
    {}
  );
  return userInformationSchemaObject;
};
