import reduce from 'lodash/reduce';

export default function replaceTemplatesInString(
  stringWithTemplate,
  replacements = {}
) {
  if (!stringWithTemplate) return null;

  return reduce(
    replacements,
    (prevString, value = null, key) => {
      const templateRegex = new RegExp(String.raw`\{${key}\}`, 'g');
      return prevString.replace(templateRegex, value);
    },
    stringWithTemplate
  );
}
