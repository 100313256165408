import React, { Component } from 'react';
import MedfusionButton from './MedfusionButton.js';

const messageStyle = { fontWeight: '200', fontSize: '25px' };
const headerStyle = {
  fontWeight: '100',
  fontSize: '34px',
  marginBottom: '20px'
};

const fontIconError = {
  fontSize: '100px',
  textAlign: 'center',
  color: 'rgb(205,75,71)'
};

class SHRLoadingError extends Component {
  render() {
    return (
      <div className="my-3 p-3 bg-white rounded box-shadow">
        <div className="col-12">
          <h1 className="text-monarc-orange" style={headerStyle}>
            Error in Building Your Smart Health Record
          </h1>
          <hr />
        </div>

        <div className="row justify-content-center">
          <div className="col-12 text-center my-4">
            <i className="fas fa-exclamation-circle" style={fontIconError} />
          </div>
          <div className="col-12 justify-content-center text-center my-4">
            <MedfusionButton />
          </div>
          <p className="col-10" style={messageStyle}>
            We are in the process of compiling your medical records and building
            your Smart Health Record. However, there is an error or additional
            step you need to take to complete the process, click the "Import
            Health Records" button and look for any entries with an "!" next to
            them. Click on those entries and they should have instructions for
            any next steps.
          </p>
        </div>
      </div>
    );
  }
}

export default SHRLoadingError;
