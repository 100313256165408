import React, { useCallback } from 'react';

export default function useOnEnterKeyUp(
  callback: () => void,
  validated?: boolean
) {
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter' || !validated) return;
      callback();
    },
    [callback, validated]
  );
  return {
    handleKeyUp
  };
}
