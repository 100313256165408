import React, { Component } from 'react';

import ReactGA from 'react-ga';
import ScrollspyContent from './ScrollspyContent';
import AuthorizationsHeader from './AuthorizationsHeader';
import * as userActions from '../../../../actions/user';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectActiveUserConsentContent,
  selectActiveUserConsentSignature,
  selectSignedConsentId
} from '../../../../selectors/user';

class Consent extends Component {
  componentDidMount() {
    const { userActions, id, authToken } = this.props;
    if (id !== 0 && !isNaN(Number(id))) {
      userActions.getConsent(authToken, id);
    } else {
      userActions.getContentfulConsent();
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  updateConsents = async () => {
    const { authToken, userActions } = this.props;
    await userActions.getConsents(authToken);
  };

  render() {
    const { consent, signedConsentId } = this.props;
    if (!consent) return null;
    return (
      <div className="container center-container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-12">
            <AuthorizationsHeader />
            <ScrollspyContent
              {...this.props}
              title={consent.title}
              content={consent}
              signedConsentId={signedConsentId}
              updateConsents={this.updateConsents}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  };
}

function mapStateToProps(state, { consent }) {
  const { auth, user } = state;
  return {
    authToken: auth.authToken,
    profileSession: auth.profileSession,
    consent: consent || selectActiveUserConsentContent(state),
    signature: selectActiveUserConsentSignature(state),
    signedConsentId: selectSignedConsentId(state),
    needsToSign: user.consents.needsToSign,
    isLoadingConsent: user.consents.isLoadingConsent
  };
}
Consent.propTypes = {
  authToken: PropTypes.string,
  profileSession: PropTypes.object,
  userActions: PropTypes.object,
  id: PropTypes.any,
  consent: PropTypes.object,
  signedConsentId: PropTypes.any
};
export default connect(mapStateToProps, mapDispatchToProps)(Consent);
