import * as types from '../constants/actionTypes';
import request from '../utils/request';
import config from '../config/config';

const { JSON_URL } = config;

const getProvidersStart = () => {
  return {
    type: types.FETCH_PROVIDERS_START
  };
};

const getProvidersSuccess = response => {
  return {
    type: types.FETCH_PROVIDERS_SUCCESS,
    response
  };
};

const getProvidersFailure = error => {
  return {
    type: types.FETCH_PROVIDERS_FAILURE,
    error
  };
};

const getProvidersByPatientStart = () => {
  return {
    type: types.FETCH_PROVIDERS_BY_PATIENT_START
  };
};

const getProvidersByPatientSuccess = response => {
  return {
    type: types.FETCH_PROVIDERS_BY_PATIENT_SUCCESS,
    response
  };
};

const getProvidersByPatientFailure = error => {
  return {
    type: types.FETCH_PROVIDERS_BY_PATIENT_FAILURE,
    error
  };
};

export const getProviders = (accessToken, patientId) => {
  return async dispatch => {
    try {
      dispatch(getProvidersStart());

      const response = await request({
        url: `patients/${patientId}/providers`,
        headers: {
          'Session-Key': accessToken
        }
      });

      const { providers } = response.data;
      dispatch(getProvidersSuccess(providers));
    } catch (err) {
      dispatch(getProvidersFailure(err));
    }
  };
};

export const getProvidersByPatient = (accessToken, patientId) => {
  return async dispatch => {
    try {
      dispatch(getProvidersByPatientStart());

      const response = await request({
        url: 'providersByPatient',
        baseURL: JSON_URL,
        queryParams: {
          patientId
        },
        headers: {
          'Session-Key': accessToken
        }
      });

      dispatch(getProvidersByPatientSuccess(response.data));
    } catch (err) {
      dispatch(getProvidersByPatientFailure(err));
    }
  };
};
