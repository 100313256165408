import React, { useEffect, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { CompatRoute as Route } from 'react-router-dom-v5-compat';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { useTheme } from './ThemeProvider.js';

// Hooks
import { useCustomAuthentication } from '../hooks/authentication';

// Actions
import { logout } from '../actions/auth.js';
import { PRE_SCREENING_PASSED } from '../constants/screeningPassedTypes.js';

const PrivateRoute = props => {
  const {
    renderComponent: RenderComponent,
    authToken,
    logout,
    needsToSign,
    needsScreening
  } = props;
  const { pathname } = useLocation();
  const performingCustomAuth = useCustomAuthentication();
  const {
    theme: { webAppTimeout }
  } = useTheme();
  useEffect(() => {
    const handleLogout = () => {
      logout(authToken);
    };

    window.addEventListener('onunload', handleLogout);
    return () => window.removeEventListener('onunload', handleLogout);
  }, [logout, authToken]);

  const handleOnIdle = useCallback(() => {
    if (!webAppTimeout) {
      return;
    }
    logout(authToken, true);
  }, [logout, webAppTimeout, authToken]);

  useIdleTimer({
    timeout: 1000 * 60 * webAppTimeout,
    onIdle: handleOnIdle,
    debounce: 500
  });

  if (performingCustomAuth) {
    return null;
  }

  return (
    <Route
      {...props}
      render={renderProps => {
        if (!authToken) return <Redirect to="/login/" />;
        if (
          (needsToSign || needsScreening) &&
          pathname !== '/home/onboarding'
        ) {
          return <Redirect to="/home/onboarding" />;
        }
        return <RenderComponent {...props} {...renderProps} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  renderComponent: PropTypes.any,
  authToken: PropTypes.any,
  profileSession: PropTypes.any,
  logout: PropTypes.func,
  needsToSign: PropTypes.bool,
  needsScreening: PropTypes.bool
};

function mapStateToProps({ auth, providers: { providers }, user }) {
  return {
    authToken: auth.authToken,
    profileSession: auth.profileSession,
    providers,
    needsToSign: user?.consents?.needsToSign,
    needsScreening:
      auth.profileSession?.user?.screeningPassed === PRE_SCREENING_PASSED
  };
}

export default connect(mapStateToProps, { logout })(PrivateRoute);
