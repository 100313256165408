import React from 'react';
import PropTypes from 'prop-types';

export default function PolicyLabel({ policy, onOpenModal }) {
  const { checkboxItemPrefix, title, content } = policy;
  return (
    <>
      {checkboxItemPrefix || ''}{' '}
      {content ? (
        <a href="#" data-testid="modal-title-btn" onClick={onOpenModal}>
          {title}
        </a>
      ) : (
        title
      )}
    </>
  );
}

PolicyLabel.propTypes = {
  onOpenModal: PropTypes.func,
  policy: PropTypes.any.isRequired
};
