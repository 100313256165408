import React from 'react';
import PropTypes from 'prop-types';
import SideMenuItem from './SideMenuItem';
import useFeatureConfiguration from '../../hooks/useFeatureConfiguration';

export default function AuthorizationsMenuItem(props) {
  const { closeSideMenu, url, unsignedBadge } = props;
  const config = useFeatureConfiguration();
  return (
    <SideMenuItem
      onClick={closeSideMenu}
      url={url}
      link="/home/authorizations"
      title={config.sideMenuAuthorizationsLabel || 'Authorizations'}
      icon="fas fa-file-alt"
      badge={unsignedBadge}
      {...props}
    />
  );
}

AuthorizationsMenuItem.propTypes = {
  closeSideMenu: PropTypes.func,
  url: PropTypes.string,
  unsignedBadge: PropTypes.any
};
