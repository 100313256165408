import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useTheme } from '../ThemeProvider.js';
import LocalePicker from '../LocalePicker/index.js';

export default function LogInButton({ isMobile }) {
  const {
    theme: {
      screenAndPagesContentConfiguration: {
        sessionContentConfiguration: config
      } = {}
    } = {}
  } = useTheme();
  const { pathname } = window.location;
  const isPathNotAllowed =
    pathname.includes('register') ||
    pathname.includes('policies') ||
    pathname.includes('login') ||
    pathname.includes('forgot-password');
  if (isPathNotAllowed) {
    return (
      <div className="float-right landing-login">
        <LocalePicker isMobile={isMobile} />
      </div>
    );
  }
  return (
    <div className="float-right landing-login">
      <LocalePicker isMobile={isMobile} />
      <Link
        to="/login"
        className="btn-nav login login-btn-spec"
        data-cy="log-in-btn"
      >
        {config?.navBarLogInButton || 'Log In'}
      </Link>
    </div>
  );
}

LogInButton.propTypes = {
  isMobile: PropTypes.bool
};
