import React, { Component } from 'react';
import ImportRecords from './../ImportRecords';
import ReactGA from 'react-ga';

class DashboardSHR extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="container center-container">
        <ImportRecords {...this.props} />
      </div>
    );
  }
}

export default DashboardSHR;
