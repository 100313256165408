import React from 'react';
import PropTypes from 'prop-types';

export default function RegisterSuccess({
  screeningPassed,
  qualificationMessage,
  disqualificationMessage
}) {
  const message = screeningPassed
    ? qualificationMessage ||
      'Please check your email for a link to activate your account.'
    : disqualificationMessage || 'Disqualified';

  return (
    <div className="register-v2 success">
      <div className="top container">
        <h2>{message}</h2>
      </div>
    </div>
  );
}

RegisterSuccess.propTypes = {
  screeningPassed: PropTypes.bool.isRequired,
  qualificationMessage: PropTypes.string,
  disqualificationMessage: PropTypes.string
};
