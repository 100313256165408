import { Rule } from '@ts/components/molecules/PasswordStrength';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import zxcvbn from 'zxcvbn';

export default function usePasswordRules(password: string) {
  const {
    screenAndPagesContentConfiguration: {
      passwordMgtContentConfiguration: config
    } = {}
  } = useTheme();

  const rules: Rule[] = useMemo(() => {
    const result = zxcvbn(password);
    return [
      {
        name: 'min_length',
        validate: password => password.length >= 8,
        message:
          config?.passwordLengthRequirementMessage || 'At least 8 characters'
      },
      {
        name: 'strength',
        validate: () => result.score >= 2,
        message:
          config?.passwordStrengthRequirementMessage ||
          'At least ‘Fair’ strength'
      }
    ];
  }, [
    password,
    config?.passwordStrengthRequirementMessage,
    config?.passwordLengthRequirementMessage
  ]);

  const validated = useMemo(() => {
    let v = true;
    rules.forEach(rule => {
      if (!rule.validate(password)) {
        v = false;
      }
    });
    return v;
  }, [password, rules]);
  return {
    validated,
    rules
  };
}
