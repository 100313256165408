import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import moment from 'moment';
import { get } from 'lodash';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import ConsentDescription from './ConsentDescription';
import { useTheme } from '../../ThemeProvider';
import PagerButton from './PagerButton';
import useFeatureConfiguration from '../../../hooks/useFeatureConfiguration';
import { getConsent } from '../../../utils/OneVueAPI.js';
import { useTranslation } from 'react-i18next';

const Content = props => {
  const {
    consent,
    readSteps,
    setReadSteps,
    setStep,
    step,
    stepsNumber,
    needsToSign,
    signature,
    signedConsentId
  } = props;
  const { theme } = useTheme();
  const { t } = useTranslation();
  const config = useFeatureConfiguration();
  const [isMobile, setIsMobile] = useState(false);

  const mobileCheck = useCallback(() => {
    setIsMobile(window.innerWidth <= 790);
  }, []);

  const titles = useMemo(
    () => consent.sections.map(({ title }, index) => `${index + 1}. ${title}`),
    [consent]
  );

  useEffect(() => {
    window.addEventListener('resize', mobileCheck);
  }, [mobileCheck]);

  const handleNext = useCallback(() => {
    if (!readSteps.includes(step)) {
      setReadSteps([...readSteps, step]);
    }
    setStep(step + 1);
  }, [readSteps, step, setReadSteps, setStep]);

  const renderOptionSelector = useCallback(
    dropdownType => (
      <>
        <button
          className={`btn form-control mr-2 filter-btn dropdown-toggle sort-by-btn ${dropdownType}`}
          type="button"
          id="dropdownMenu3"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-cy="filter-btn"
          data-testid={`filter-btn-${dropdownType || 'not-mobile'}`}
        >
          {titles[step] || ''}
        </button>
        <div
          className="dropdown-menu filter-by"
          aria-labelledby="dropdownMenu3"
        >
          {titles.map((title, index) => (
            <button
              key={index}
              id="auth-dropdown"
              data-testid={`title-step-selector-${index}`}
              className={`step-${index + 1} ${
                index === step ? 'active' : ''
              } dropdown-item`}
              type="button"
              onClick={() => setStep(index)}
            >
              {title}
            </button>
          ))}
        </div>
      </>
    ),
    [step, titles, setStep]
  );

  const navContainer = useMemo(() => {
    const isBackVisible = step > 0;
    const isNextVisible = step !== stepsNumber - 1;
    return (
      (isBackVisible || isNextVisible) && (
        <div className="auth-nav-container">
          <PagerButton
            label={config?.previousPageButtonLabel || 'Back'}
            onClick={() => setStep(step - 1)}
            isVisible={isBackVisible}
            leftIcon={<ArrowLeftIcon />}
            wrapperClassName="auth-back-btn"
            className="back-btn-text"
            testId="previous-btn"
          />
          {!isMobile && renderOptionSelector()}
          <PagerButton
            label={config?.nextPageButtonLabel || 'Next'}
            onClick={handleNext}
            isVisible={isNextVisible}
            rightIcon={<ArrowRightIcon />}
            wrapperClassName="auth-next-btn"
            className="next-btn-text"
            testId="next-btn"
          />
        </div>
      )
    );
  }, [
    step,
    stepsNumber,
    isMobile,
    handleNext,
    renderOptionSelector,
    setStep,
    config?.previousPageButtonLabel,
    config?.nextPageButtonLabel
  ]);

  const progressBarStyle = useMemo(
    () => ({
      margin: 0,
      height: '3px',
      backgroundColor: theme.secondaryColor,
      width: (step * 100) / (consent.sections.length - 1) + '%',
      transition: 'width 500ms'
    }),
    [theme, consent, step]
  );

  const redirectToSignedPdf = useCallback(() => {
    if (signedConsentId) {
      getConsent(signedConsentId)
        .then(({ data }) => {
          window.open(URL.createObjectURL(data), '_blank');
        })
        .catch(() => {
          alert(config?.signedConsentPDFFailureMessage || 'File not found.');
        });
    }
  }, [signedConsentId, config?.signedConsentPDFFailureMessage]);

  const signButton = useMemo(() => {
    return (
      step === stepsNumber - 1 && (
        <div id="authButton">
          {needsToSign ? (
            <button
              type="button"
              className="btn btn-lg btn-block btn-monarc-orange sign-authorization click-to-sign"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalCenter"
            >
              {config?.signConsentButtonLabel || 'SIGN AUTHORIZATION'}
            </button>
          ) : (
            <button
              type="button"
              data-testid="download-pdf-button"
              className="btn btn-lg btn-block btn-monarc-orange sign-authorization"
              onClick={redirectToSignedPdf}
            >
              {t('authorizationPanel.download')}
            </button>
          )}
        </div>
      )
    );
  }, [
    step,
    stepsNumber,
    needsToSign,
    redirectToSignedPdf,
    config?.signConsentButtonLabel,
    t
  ]);

  const dateToFromNowDaily = useMemo(() => {
    const myDate = get(signature, 'signatureTs');
    return (
      myDate &&
      moment(myDate).calendar(null, {
        lastWeek: `[${t('authorizationPanel.last')}] dddd`,
        lastDay: `[${t('authorizationPanel.yesterday')}]`,
        sameDay: `[${t('authorizationPanel.today')}]`,
        sameElse: function() {
          return '[' + moment(myDate).format('MM/DD/YYYY hh:mm:ss a') + ']';
        }
      })
    );
  }, [signature, t]);

  const section = useMemo(() => {
    const activeSection = consent.sections[step];
    if (!activeSection) return null;
    const siggy = get(signature, 'signature');
    return (
      <>
        {step === 0 && <ConsentDescription consent={consent} />}
        <h5 className="bold consent-header">
          {step + 1}. {activeSection.title}
        </h5>
        {documentToReactComponents(get(activeSection, 'section'))}
        {step === stepsNumber - 1 && siggy && (
          <div className="signature-container">
            <img className="consent-signature" src={siggy} alt="signature" />
            <div className="timestamp-container">
              {t('authorizationPanel.signed')} {dateToFromNowDaily}
            </div>
          </div>
        )}
        {signButton}
      </>
    );
  }, [
    consent,
    step,
    stepsNumber,
    signature,
    dateToFromNowDaily,
    signButton,
    t
  ]);

  return (
    <div>
      <div className="auth-panel-desktop">
        {navContainer}
        <hr style={progressBarStyle} />
        <div className="auth-content">
          <div>{section}</div>
        </div>
        {navContainer}
      </div>
      <div className="auth-panel-mobile">
        {isMobile && renderOptionSelector('mobile')}
        <div className="auth-content">
          <div>{section}</div>
        </div>
        {navContainer}
      </div>
    </div>
  );
};

Content.propTypes = {
  consent: PropTypes.any.isRequired,
  history: PropTypes.any,
  readSteps: PropTypes.arrayOf(PropTypes.number),
  setReadSteps: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
  stepsNumber: PropTypes.number,
  needsToSign: PropTypes.bool,
  signature: PropTypes.any,
  signedConsentId: PropTypes.any
};

export default Content;
