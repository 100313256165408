import { Audit } from '@ts/models';
import { ColumnConfig } from 'theme';
import i18n from '../../i18n';

const columnConfigs: Array<ColumnConfig<Audit>> = [
  { id: 'date', label: i18n.t('auditContent.columnDate'), sortable: true },
  { id: 'site', label: i18n.t('auditContent.site'), sortable: true },
  { id: 'user', label: i18n.t('auditContent.user'), sortable: true },
  { id: 'subject', label: i18n.t('auditContent.subject'), sortable: true },
  { id: 'action', label: i18n.t('auditContent.action'), sortable: true },
  { id: 'reasonForChange', label: i18n.t('auditContent.reasonForChange') }
];

export default {
  columnConfigs,
  displayingAuditsLabel: i18n.t('auditContent.auditsLabel'),
  auditsLabel: i18n.t('auditContent.audits')
};
