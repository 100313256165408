import React, { Component } from 'react';
import BaseContainer from './BaseContainer';
import Profile from './../Profile/Profile';
import PropTypes from 'prop-types';
class DashboardProfileContainer extends Component {
  render() {
    const { history } = this.props;
    return (
      <BaseContainer {...this.props}>
        <Profile history={history} {...this.props} />
      </BaseContainer>
    );
  }
}
DashboardProfileContainer.propTypes = {
  history: PropTypes.any
};

export default DashboardProfileContainer;
