import { useMemo } from 'react';
import usePRNOption from '../../../hooks/usePRNOption';
import { mapToRegistrationViewModel } from '../utils/mappers';

const useRegistrationFetchData = ({ diseaseCode, programType }) => {
  const {
    data: prnOption,
    isLoading,
    fetchPrnOption: refetch,
    error
  } = usePRNOption({
    diseaseCode,
    programType
  });

  const data = useMemo(() => {
    if (!prnOption) return null;

    const prnOptionDataForRegistration = mapToRegistrationViewModel(prnOption);
    return prnOptionDataForRegistration;
  }, [prnOption]);

  return {
    data,
    isLoading,
    refetch,
    error
  };
};

export default useRegistrationFetchData;
