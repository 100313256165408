import { ColumnConfig, ParticipantsContentConfiguration } from 'theme';
import { GetParticipant } from '@ts/models';
import i18n from '../../i18n';

const columnConfigs: ColumnConfig<GetParticipant>[] = [
  {
    id: 'name',
    label: i18n.t('participantContent.columnConfigs.name'),
    sortable: true
  },
  {
    id: 'site',
    label: i18n.t('participantContent.columnConfigs.site'),
    sortable: true
  },
  {
    id: 'status',
    label: i18n.t('participantContent.columnConfigs.status'),
    sortable: true
  },
  { id: 'flags', label: i18n.t('participantContent.columnConfigs.flags') },
  { id: 'tags', label: i18n.t('participantContent.columnConfigs.tags') }
];

const participantDetails = {
  participantList: i18n.t('participantDetailsPage.participantList'),
  participantId: i18n.t('participantDetailsPage.participantId'),
  participantStatus: i18n.t('participantDetailsPage.participantStatus'),
  participantFlags: i18n.t('participantDetailsPage.participantFlags'),
  participantTags: i18n.t('participantDetailsPage.participantTags'),
  participant: i18n.t('participantDetailsPage.participant')
};

const config: ParticipantsContentConfiguration = {
  searchInputPlaceholder: i18n.t('participantContent.searchInputPlaceholder'),
  sitesFilterLabel: i18n.t('participantContent.sitesFilterLabel'),
  statusFilterLabel: i18n.t('participantContent.statusFilterLabel'),
  flagsFilterLabel: i18n.t('participantContent.flagsFilterLabel'),
  tagsFilterLabel: i18n.t('participantContent.tagsFilterLabel'),
  displayingParticipantsLabel: i18n.t(
    'participantContent.displayingParticipantsLabel'
  ),
  participantsLabel: i18n.t('participantContent.participantsLabel'),
  participantsCreateButtonLabel: i18n.t(
    'participantContent.participantsCreateButtonLabel'
  ),
  columnConfigs,
  statusLabelActive: i18n.t('participantContent.statusLabelActive'),
  statusLabelInactive: i18n.t('participantContent.statusLabelInactive'),
  statusLabelInvited: i18n.t('participantContent.statusLabelInvited'),
  statusLabelLocked: i18n.t('participantContent.statusLabelLocked'),
  searchEmptyListMessage: i18n.t('participantContent.searchEmptyListMessage'),
  filtersEmptyListMessage: i18n.t('participantContent.filtersEmptyListMessage'),
  emptyListMessage: i18n.t('participantContent.emptyListMessage'),
  errorListMessage: i18n.t('participantContent.errorListMessage'),
  createModalTitle: i18n.t('participantContent.createModalTitle'),
  createModalDisclaimer: i18n.t('participantContent.createModalDisclaimer'),
  formFields: {
    site: {
      label: i18n.t('participantContent.formFields.site.label'),
      placeholder: i18n.t('participantContent.formFields.site.placeholder')
    },
    participantId: {
      label: i18n.t('participantContent.formFields.participantId.label'),
      placeholder: i18n.t(
        'participantContent.formFields.participantId.placeholder'
      )
    },
    contactMethod: {
      label: i18n.t('participantContent.formFields.contactMethod.label'),
      placeholder: i18n.t(
        'participantContent.formFields.contactMethod.placeholder'
      )
    },
    email: {
      label: i18n.t('participantContent.formFields.email.label'),
      placeholder: i18n.t('participantContent.formFields.email.placeholder')
    },
    phoneNumber: {
      label: i18n.t('participantContent.formFields.phoneNumber.label'),
      placeholder: i18n.t(
        'participantContent.formFields.phoneNumber.placeholder'
      )
    },
    dateInformedConsent: {
      label: i18n.t('participantContent.formFields.dateInformedConsent.label'),
      placeholder: i18n.t(
        'participantContent.formFields.dateInformedConsent.placeholder'
      )
    },
    state: {
      label: i18n.t('participantContent.formFields.state.label'),
      placeholder: i18n.t('participantContent.formFields.state.placeholder')
    },
    zipCode: {
      label: i18n.t('participantContent.formFields.zipCode.label'),
      placeholder: i18n.t('participantContent.formFields.zipCode.placeholder')
    },
    birthday: {
      label: i18n.t('participantContent.formFields.birthday.label'),
      placeholder: i18n.t('participantContent.formFields.birthday.placeholder')
    }
  },
  contactMethodLabelEmail: i18n.t('participantContent.contactMethodLabelEmail'),
  contactMethodLabelPhone: i18n.t('participantContent.contactMethodLabelPhone'),
  createModalCancelButtonLabel: i18n.t(
    'participantContent.createModalCancelButtonLabel'
  ),
  createModalSubmitButtonLabel: i18n.t(
    'participantContent.createModalSubmitButtonLabel'
  ),
  errorModalDisclaimer: i18n.t('participantContent.errorModalDisclaimer'),
  ...participantDetails
};

export default config;
