import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Nav from '../Nav';
import { useTheme as useThemeOriginal } from '../ThemeProvider';
import UserInformationForm from '../UserInformationForm';
import UserQuestionsForm from '../UserQuestionsForm';
import useRegistrationFetchDataOriginal from './hooks/useRegistrationFetchData';
import useRegistrationDataSubmissionOriginal from './hooks/useRegistrationDataSubmission';
import LoadingSpinner from '../shared/LoadingSpinner';
import { createRegistrationFormSchema } from './utils/schema';
import ErrorPlaceholder from './components/ErrorPlaceholder';
import FooterButton from './components/FooterButton';
import AlertBanner from '../../Components/AlertBanner';
import RegisterSuccess from './components/RegisterSuccess';
import {
  splitFormDataByPrefix,
  splitUserInformationViews
} from './utils/mappers';

const PageContent = ({ children, ...restProps }) => (
  <div className="register-v2" {...restProps}>
    <Nav />
    {children}
  </div>
);
PageContent.propTypes = {
  children: PropTypes.node,
  containerClassName: PropTypes.string
};

const Register = ({
  match,
  useRegistrationFetchData = useRegistrationFetchDataOriginal,
  useRegistrationDataSubmission = useRegistrationDataSubmissionOriginal,
  useTheme = useThemeOriginal
}) => {
  const preScreeningQuestionsInputIdPrefix = `preScreeningQuestions_`;
  const { diseaseCode, programType } = match.params;
  const {
    theme: { programName }
  } = useTheme();
  const {
    data,
    isLoading: isLoadingFetchData,
    error: errorFetchData,
    refetch
  } = useRegistrationFetchData({
    diseaseCode,
    programType
  });
  const {
    isLoading: isSubmittingRegistrationData,
    isSuccess: isUserRegisteredSuccessfully,
    screeningPassed,
    error: userRegisterError,
    registerUser
  } = useRegistrationDataSubmission();
  const {
    userInformation,
    relationshipOption,
    prnOptionId,
    preScreeningQuestions
  } = data || {};
  const {
    demographicUserInformation,
    credentialsUserInformation
  } = useMemo(() => {
    return userInformation ? splitUserInformationViews(userInformation) : {};
  }, [userInformation]);
  const { schema } = createRegistrationFormSchema({
    userInformation,
    preScreeningQuestions,
    preScreeningQuestionsInputIdPrefix
  });

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit
  } = useForm({
    mode: 'onChange',
    resolver: joiResolver(schema)
  });

  const success = Object.keys(dirtyFields)
    .filter(field => !errors[field])
    .reduce((acc, field) => {
      acc[field] = dirtyFields[field];
      return acc;
    }, {});

  const onSubmit = async data => {
    const policiesUserStr = localStorage.getItem('policies_user');
    const policiesUser = policiesUserStr && JSON.parse(policiesUserStr);
    const {
      dataWithoutPrefix: userInformationFormData,
      dataWithPrefix: preScreeningQuestionsFormData
    } = splitFormDataByPrefix(data, preScreeningQuestionsInputIdPrefix);

    registerUser(
      {
        ...policiesUser,
        ...userInformationFormData,
        preScreeningQuestionsFormData: preScreeningQuestionsFormData,
        diseaseCode,
        programType,
        programName,
        prnOptionId,
        relationshipOption
      },
      preScreeningQuestions
    );
  };

  if (isLoadingFetchData) {
    return (
      <PageContent data-test="register-loading">
        <div className="container p-0 d-flex flex-column justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      </PageContent>
    );
  }

  if (errorFetchData) {
    return (
      <PageContent>
        <ErrorPlaceholder
          errorMessage={errorFetchData.message}
          subTitle="There was an error fetching data"
          onRetryClick={refetch}
        />
      </PageContent>
    );
  }

  if (isUserRegisteredSuccessfully) {
    const { qualificationMessage, disqualificationMessage } = data;
    return (
      <PageContent>
        <RegisterSuccess
          screeningPassed={screeningPassed}
          qualificationMessage={qualificationMessage}
          disqualificationMessage={disqualificationMessage}
        />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <div className="container">
        {data?.headerText ? <h2>{data.headerText}</h2> : null}
        {demographicUserInformation ? (
          <UserInformationForm
            control={control}
            errors={errors}
            success={success}
            userInformation={demographicUserInformation}
          />
        ) : null}
        {preScreeningQuestions ? (
          <UserQuestionsForm
            control={control}
            errors={errors}
            success={success}
            questions={preScreeningQuestions}
            inputIdPrefix={preScreeningQuestionsInputIdPrefix}
          />
        ) : null}
        {credentialsUserInformation ? (
          <UserInformationForm
            control={control}
            errors={errors}
            success={success}
            userInformation={credentialsUserInformation}
          />
        ) : null}
        {userRegisterError ? (
          <AlertBanner
            className="mt-4"
            type="error"
            title={
              data?.submitDataErrorMessageTitle ||
              'There was an error creating your account!'
            }
            errorMessage={userRegisterError}
          />
        ) : null}
        <FooterButton
          title={data?.submitDataButtonText || 'Finish'}
          onNextClick={handleSubmit(onSubmit)}
          isDisabled={isSubmittingRegistrationData || !isDirty}
        />
      </div>
    </PageContent>
  );
};

Register.propTypes = {
  match: PropTypes.any,
  useTheme: PropTypes.func,
  useRegistrationFetchData: PropTypes.func,
  useRegistrationDataSubmission: PropTypes.func
};

export default Register;
