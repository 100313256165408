import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../services/store';

interface ChangePasswordModalState {
  isOpen: boolean;
}

const initialState: ChangePasswordModalState = { isOpen: false };

const changePasswordModalSlice = createSlice({
  name: 'changePasswordModal',
  initialState,
  reducers: {
    open: state => {
      state.isOpen = true;
    },
    close: state => {
      state.isOpen = false;
    },
    toggle: state => {
      state.isOpen = !state.isOpen;
    }
  }
});

export const { open, close, toggle } = changePasswordModalSlice.actions;

export const getIsOpen = (state: RootState): boolean => {
  return state.changePasswordModal?.isOpen;
};

export default changePasswordModalSlice;
