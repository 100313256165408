import {
  FETCH_RECORDS_INVITATIONS_START,
  FETCH_RECORDS_INVITATIONS_SUCCESS,
  FETCH_RECORDS_INVITATIONS_FAILURE
} from '../constants/actionTypes';
import StateLoader from './StateLoader';
const stateLoader = new StateLoader();

export default function invitationsReducer(
  state = stateLoader.initializeState().recordsInvitations,
  action
) {
  switch (action.type) {
    case FETCH_RECORDS_INVITATIONS_START: {
      return {
        ...state,
        isLoading: true,
        loadingError: '',
        recordsInvitations: {}
      };
    }
    case FETCH_RECORDS_INVITATIONS_SUCCESS: {
      return {
        ...state,
        recordsInvitations: action.response,
        isLoading: false
      };
    }
    case FETCH_RECORDS_INVITATIONS_FAILURE:
      return {
        ...state,
        recordsInvitations: {},
        isLoading: false,
        loadingError: action.response
      };
    default:
      return state;
  }
}
