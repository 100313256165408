import { useCallback, useReducer } from 'react';
import { confirmToken } from '../../utils/OneVueAPI';
import { createFetchActions } from '../../actions/utils';

export const { types, actions } = createFetchActions('CONFIRM_TOKEN_SUBMIT');

const initialState = {
  isLoading: false,
  didLoad: false,
  error: null,
  data: null
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.START:
      return { ...state, isLoading: true };
    case types.SUCCESS:
      return {
        ...state,
        isLoading: false,
        didLoad: true,
        data: action.payload
      };
    case types.FAILURE:
      return {
        ...state,
        isLoading: false,
        didLoad: true,
        error: action.payload
      };
    case types.CLEAR:
      return { ...initialState };
  }
}

export default function useResetPasswordSubmission(token) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const confirmTokenSubmit = useCallback(async () => {
    try {
      dispatch(actions.START());
      const response = await confirmToken(token);
      dispatch(actions.SUCCESS(response.data));
    } catch (error) {
      dispatch(actions.FAILURE(error.message));
    }
  }, [dispatch, token]);
  function clear() {
    dispatch(actions.CLEAR());
  }
  return {
    confirmTokenSubmit,
    clear,
    ...state
  };
}
