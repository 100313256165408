import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function getAlertClassName(type) {
  const ALERT_TYPE_MAP = {
    error: 'alert-danger',
    info: 'alert-info',
    success: 'alert-success'
  };
  return ALERT_TYPE_MAP[type] || ALERT_TYPE_MAP.info;
}

export default function AlertBanner({
  title,
  errorMessage,
  onCloseClick,
  type = 'info',
  className: additionalClassName,
  ...restProps
}) {
  const mergedClassName = useMemo(
    () =>
      `alert fade show ${getAlertClassName(type)} ${additionalClassName ?? ''}`,
    [type, additionalClassName]
  );

  return (
    <div className={mergedClassName} {...restProps} role="alert">
      <p>{title}</p>
      {errorMessage ? <strong>{errorMessage}</strong> : null}
      {onCloseClick ? (
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={onCloseClick}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      ) : null}
    </div>
  );
}

AlertBanner.propTypes = {
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  onCloseClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'success'])
};
