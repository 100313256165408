import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchLocales, setUserLocale } from '../actions/locales';
import { getUserLocale } from '../utils/cms';

export default function useLocale() {
  const dispatch = useDispatch();
  const { didLoad, isLoading, locales = [], userLocale } = useSelector(
    state => state.cmsLocales
  ) || { locales: [] };
  const selectUserLocale = useCallback(
    locale => {
      dispatch(setUserLocale(locale));
      if (locale !== userLocale) {
        window.location.reload();
      }
    },
    [dispatch, userLocale]
  );
  useEffect(() => {
    if (!didLoad && !isLoading) {
      dispatch(fetchLocales());
    }
    const storedLocale = getUserLocale();
    if (!userLocale && storedLocale) {
      dispatch(setUserLocale(storedLocale));
    }
  }, [dispatch, didLoad, isLoading, userLocale]);

  return { locales, userLocale, didLoad, isLoading, selectUserLocale };
}
