import React from 'react';
import AuthorizationsMenuItem from './AuthorizationsMenuItem';
import SHRMenuItem from './SHRMenuItem';

export default function PatientMenuItems(props) {
  return (
    <>
      <AuthorizationsMenuItem {...props} />
      <SHRMenuItem {...props} />
    </>
  );
}

PatientMenuItems.propTypes = {};
