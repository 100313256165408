import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultKeyLabelMap = {
  title: 'Title',
  sponsor: 'Sponsor',
  irbName: 'IRB Name',
  protocolNumber: 'Protocol Number',
  protocolVersion: 'Protocol Version',
  protocolVersionDate: 'Protocol Version Date',
  irbApprovalDate: 'Protocol IRB Approval Date',
  icfVersionNumber: 'Version Number',
  icfIrbApprovalDate: 'Approval Date',
  coInvestigators: 'Co-Investigators',
  telephone: 'Telephone',
  address: 'Address'
};

const ConsentDescription = ({ consent }) => {
  const items = useMemo(() => {
    return [
      'title',
      'sponsor',
      'irbName',
      'protocolNumber',
      'protocolVersion',
      'protocolVersionDate',
      'coInvestigators',
      'irbApprovalDate',
      'icfVersionNumber',
      'icfIrbApprovalDate',
      'telephone',
      'address'
    ].map(key => ({
      label: consent[`${key}InfoLabel`] || defaultKeyLabelMap[key],
      value: consent[key]
    }));
  }, [consent]);
  return (
    <div>
      <table className="consent-table">
        <tbody>
          {items.map(({ label, value }, parentIndex) =>
            Array.isArray(value) ? (
              <tr key={parentIndex}>
                <td>{label}</td>
                <td>
                  {value.map((itemValue, index) => (
                    <div key={index}>{itemValue}</div>
                  ))}
                </td>
              </tr>
            ) : (
              value && (
                <tr key={parentIndex}>
                  <td>{label}</td>
                  <td>{value}</td>
                </tr>
              )
            )
          )}
        </tbody>
      </table>
      <hr />
    </div>
  );
};

ConsentDescription.propTypes = {
  consent: PropTypes.any
};

export default ConsentDescription;
