import React from 'react';
import ConsentList from './components/ConsentList.js';
import Consent from './components/Consent.js';
import PropTypes from 'prop-types';

const DashboardConsent = props => {
  const { type } = props;
  const CONSENT_VIEWS = {
    list: <ConsentList {...props} />,
    sign: <Consent {...props} />
  };
  return (
    <div className="center-container" style={{ height: '100%' }}>
      {CONSENT_VIEWS[type]}
    </div>
  );
};

DashboardConsent.propTypes = {
  id: PropTypes.any,
  type: PropTypes.string,
  profileSession: PropTypes.any
};

export default DashboardConsent;
