import React from 'react';
import { Asset } from 'theme';
import { SessionMgtFeedbackContainer } from '../atoms/containers';
import { SessionMgtFeedbackIcon } from '../atoms/images';

type SessionManagementFeedbackContainerProps = {
  image?: Asset;
  fallbackIconSrc: string;
  message: string;
};

export function SessionManagementFeedback({
  image,
  fallbackIconSrc,
  message
}: Readonly<SessionManagementFeedbackContainerProps>) {
  return (
    <SessionMgtFeedbackContainer data-testid="success-container">
      {image ? (
        <img
          src={image.src}
          width={image.width}
          height={image.height}
          alt="success"
        />
      ) : (
        <SessionMgtFeedbackIcon
          src={fallbackIconSrc}
          width={100}
          height={104}
        />
      )}
      {message}
    </SessionMgtFeedbackContainer>
  );
}
