import * as types from '../constants/actionTypes';
import request from '../utils/request';
import config from '../config/config';
import { format } from 'date-fns';
import { getRecordCreatedTime } from '../Components/shared/getRecordDetails';

const { JSON_URL } = config;

const initRecordsSuccess = () => {
  return {
    type: types.INIT_RECORDS_SUCCESS
  };
};

const initRecordsFailure = error => {
  return {
    type: types.INIT_RECORDS_FAILURE,
    error
  };
};

const getRecordsInvitationsStart = () => {
  return {
    type: types.FETCH_RECORDS_INVITATIONS_START
  };
};

const getRecordsInvitationsSuccess = response => {
  return {
    type: types.FETCH_RECORDS_INVITATIONS_SUCCESS,
    response
  };
};

const getRecordsInvitationsFailure = error => {
  return {
    type: types.FETCH_RECORDS_INVITATIONS_FAILURE,
    error
  };
};

const getRecordsByTypeStart = (recordType, countOnly) => {
  return {
    type: types.FETCH_RECORDS_BY_TYPE_START,
    recordType,
    countOnly
  };
};

const getRecordsByTypeSuccess = (response, recordType, countOnly) => {
  return {
    type: types.FETCH_RECORDS_BY_TYPE_SUCCESS,
    response,
    recordType,
    countOnly
  };
};

const getRecordsByTypeFailure = (error, recordType, countOnly) => {
  return {
    type: types.FETCH_RECORDS_BY_TYPE_FAILURE,
    recordType,
    countOnly,
    error
  };
};

const clearRecordsAction = () => {
  return {
    type: types.CLEAR
  };
};

export const clearRecords = () => {
  return dispatch => {
    dispatch(clearRecordsAction());
  };
};

export const initRecords = callback => {
  return async dispatch => {
    try {
      dispatch(initRecordsSuccess());
    } catch (err) {
      dispatch(initRecordsFailure(err));
    } finally {
      callback?.();
    }
  };
};

export const getRecordInvitations = accessToken => {
  return async dispatch => {
    try {
      dispatch(getRecordsInvitationsStart());

      const response = await request({
        url: 'records_invitations',
        baseURL: JSON_URL,
        headers: {
          'Session-Key': accessToken
        }
      });
      dispatch(getRecordsInvitationsSuccess(response.data));
    } catch (err) {
      dispatch(getRecordsInvitationsFailure(err));
    }
  };
};
const getFilterString = ({ searchValue, facility, doctor, sortBy, date }) => {
  let string = '';
  if (searchValue) {
    string += `&name=${searchValue}`;
  }
  if (facility) {
    string += `&facility=${facility}`;
  }
  if (doctor) {
    string += `&doctor=${doctor}`;
  }
  if (sortBy) {
    string += `&sort=${sortBy}`;
  }
  if (date) {
    string += `&date=${date}`;
  }
  return string;
};
const checkForFilterString = filter => {
  let filterString = '&sort=dsc';
  if (filter) {
    filterString = getFilterString(filter);
    if (filter.countOnly) filterString += '&countOnly=yes';
  }
  return filterString;
};
const checkForOffset = (page, count, pageSize, filterString) => {
  let offset;
  if (!page) page = 1;

  if (page && !count) {
    offset = page === 1 ? 1 : (page - 1) * 10 + 1;
    filterString += `&pageSize=${pageSize || 2}&offset=${offset}`;
  }
  return filterString;
};
const fetchRecords = async (
  recordType,
  requestId,
  filterString,
  accessToken,
  patientId
) =>
  await request({
    url: `patients/${patientId}/records/${requestId}`,
    queryParams: { resourceType: recordType },
    queryString: filterString,
    headers: {
      'Session-Key': accessToken
    }
  });

function paginate(collection, numOffset = 1, numItems = 10) {
  const perPage = +numItems;
  const offset = numOffset - 1;
  return collection.slice(offset, offset + perPage);
}

function dateFilter(data, date, recordType) {
  return data.filter(record => {
    const recordDate = format(
      getRecordCreatedTime(record, recordType),
      'MM-DD-YYYY'
    );
    return recordDate === date;
  });
}

function sortByDate(data, type = 'dsc', recordType) {
  return data.sort((obj1, obj2) => {
    const date1 = format(
      getRecordCreatedTime(obj1, recordType),
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );
    const date2 = format(
      getRecordCreatedTime(obj2, recordType),
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );
    if (type === 'dsc') {
      return date1 < date2 ? 1 : -1;
    }
    return date1 < date2 ? -1 : 1;
  });
}

function search(data, searchString) {
  return data.filter(record => {
    // Because I like to see the world burn!
    return JSON.stringify(record).search(new RegExp(searchString, 'i')) >= 0;
  });
}

function facility(data, facility, providers) {
  return data.filter(record => {
    const [sourcePortalId] = record.sourcePortalIds;
    const provider = providers.find(({ facilityName, portalName }) => {
      return [decodeURI(facilityName), decodeURI(portalName)].includes(
        decodeURI(facility)
      );
    });
    return provider?.portalId === sourcePortalId;
  });
}

/* eslint-disable complexity */
function handleFilters(data = [], filterString, recordType, providers) {
  let resultingData = [...data];
  const filters = new URLSearchParams(filterString);
  if (filters.has('date')) {
    resultingData = dateFilter(resultingData, filters.get('date'), recordType);
  }
  if (filters.has('name')) {
    resultingData = search(resultingData, filters.get('name'));
  }
  if (filters.has('facility')) {
    resultingData = facility(resultingData, filters.get('facility'), providers);
  }
  if (filters.has('doctor')) {
    resultingData = facility(resultingData, filters.get('doctor'), providers);
  }
  if (filters.has('sort')) {
    resultingData = sortByDate(resultingData, filters.get('sort'), recordType);
  }
  if (!filters.has('offset') || !filters.has('pageSize')) {
    return resultingData;
  }
  return paginate(
    resultingData,
    filters.get('offset'),
    filters.get('pageSize')
  );
}

/* eslint-disable complexity */
const getRecordsByTypeFunc = async (
  dispatch,
  accessToken,
  patientId,
  requestId,
  recordType,
  filter,
  page,
  pageSize,
  providers,
  recordInState
) => {
  const count = filter ? !!filter.countOnly : false;
  dispatch(getRecordsByTypeStart(recordType, count));
  let filterString = checkForFilterString(filter);

  filterString = checkForOffset(page, count, pageSize, filterString);

  // If it's just counting fetch from server
  if (filterString === '&countOnly=yes') {
    const result = await fetchRecords(
      recordType,
      requestId,
      filterString,
      accessToken,
      patientId
    );

    return {
      status: result?.status,
      data: result?.data
    };
  }

  // If we don't have local data, fetch it from server
  const localRecordLength = recordInState.fullData?.length || 0;
  const recorderCount = recordInState.recordCount;

  if (localRecordLength < recorderCount) {
    const newStr = new URLSearchParams(filterString);
    newStr.delete('pageSize');
    newStr.delete('offset');

    const result = await fetchRecords(
      recordType,
      requestId,
      `&${newStr}`,
      accessToken,
      patientId
    );

    return {
      status: result?.status,
      fullData: [...(result?.data || [])],
      data: handleFilters(result?.data, filterString, recordType)
    };
  }

  // If we are counting with filters then use local data
  if (filter?.countOnly) {
    return {
      status: 200,
      fullData: recordInState.fullData,
      data: {
        count: handleFilters(
          recordInState.fullData,
          filterString,
          recordType,
          providers
        ).length
      }
    };
  }
  return {
    status: 200,
    fullData: recordInState.fullData,
    data: handleFilters(
      recordInState.fullData,
      filterString,
      recordType,
      providers
    )
  };
};

export const getRecordsByType = (
  accessToken,
  patientId,
  requestId,
  recordType,
  filter,
  page,
  pageSize
) => {
  return async (dispatch, getState) => {
    try {
      const recordInState = getState().records.recordCards[recordType];
      const providers = getState().providers.providers;
      const response = await getRecordsByTypeFunc(
        dispatch,
        accessToken,
        patientId,
        requestId,
        recordType,
        filter,
        page,
        pageSize,
        providers,
        recordInState
      );

      const count = filter ? !!filter.countOnly : false;

      response.status >= 400
        ? dispatch(getRecordsByTypeFailure(response, recordType, count))
        : dispatch(getRecordsByTypeSuccess(response, recordType, count));
    } catch (err) {
      const count = filter ? !!filter.countOnly : false;
      dispatch(getRecordsByTypeFailure(err, recordType, count));
    }
  };
};

const setSelectedRequestSuccess = requestId => {
  return {
    type: types.SET_SELECTED_REQUEST_SUCCESS,
    requestId
  };
};

const setSelectedRequestFailure = error => {
  return {
    type: types.SET_SELECTED_REQUEST_FAILURE,
    error
  };
};

export const setSelectedRequest = requestId => {
  return async dispatch => {
    try {
      dispatch(setSelectedRequestSuccess(requestId));
    } catch (err) {
      dispatch(setSelectedRequestFailure(err));
    }
  };
};
