import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useTheme } from '../ThemeProvider.js';
import useFeatureConfiguration from '../../hooks/useFeatureConfiguration.js';
import MedfusionButton from './MedfusionButton.js';
import SHRInstructionStep from './SHRInstructionStep';

const headerStyle = {
  fontWeight: '100',
  fontSize: '34px',
  marginBottom: '20px'
};

export default function SHROnboarding() {
  const {
    theme: { supportEmail, supportPhoneNumber, primaryColor }
  } = useTheme();
  const config = useFeatureConfiguration();
  const renderConfig = {
    renderNode: {
      [BLOCKS.HEADING_5]: (node, children) => (
        <div className="col-12">
          <h5>{children}</h5>
        </div>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className="col-12">
          <p style={{ fontWeight: '200' }}>{children}</p>
        </div>
      )
    }
  };
  return (
    <div className="my-3 p-3 bg-white rounded box-shadow">
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className="dashboard-item-banner"
            style={{ backgroundColor: primaryColor, width: '100%' }}
          >
            <h2 style={{ color: 'white', fontWeight: 200 }}>
              {config.shrInitialPage?.title ||
                "Here's how to consolidate your electronic health records:"}
            </h2>
          </div>
          <div className="row p-5">
            {config.shrInitialPage?.content ? (
              documentToReactComponents(
                config.shrInitialPage.content,
                renderConfig
              )
            ) : (
              <>
                <SHRInstructionStep
                  title="1. Gather Your Doctor's Information"
                  message="For each of your providers, collect their clinic name, zip code where they practice, your username and password for their online portal. If you don't know your password, contact your provider's office to receive a password before starting."
                />
                <SHRInstructionStep
                  title="2. Search For Your Provider"
                  message="Select the 'Import Health Records' button and search for your provider using their name (or clinic name) and their zip code."
                />
                <SHRInstructionStep
                  title="3. Connect Your Portal"
                  message="Enter your username and password for your provider or facility’s patient portal and hit the “Connect” Button. If you do not know this information, please contact your provider or facility for more information."
                />
                <SHRInstructionStep
                  title="Can't Find All Your Doctors?"
                  message="If you can't find any of your doctors, when you exit the Import Health Records pop-up, you have the opportunity to add the information for any of your doctors you could not add."
                  modal="addProviderModal"
                />
                <SHRInstructionStep
                  title="Not Sure What A Patient Portal Is?"
                  message="If you are not sure what a Patient Portal is, learn more <a href='https://www.healthit.gov/faq/what-patient-portal'>here</a>."
                />
                <SHRInstructionStep
                  title="Require Assistance?"
                  message={`If you require any assistance, please contact us at ${supportEmail}${
                    supportPhoneNumber ? ` or ${supportPhoneNumber}` : ''
                  }.`}
                />
              </>
            )}
          </div>
          <div className="row pw-5 pb-5">
            <div className="col-12 justify-content-center text-center">
              <h2 className="text-monarc-orange" style={headerStyle}>
                {config.shrIntegrationTitle ||
                  'Take Control! Get Started By Clicking Below:'}
              </h2>
              <MedfusionButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
