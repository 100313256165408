export const validateEmail = value =>
  value.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
export const validatePassword = value =>
  value.length >= 8 &&
  value.length <= 20 &&
  /[a-z]/.test(value) &&
  /[A-Z]/.test(value) &&
  /\d/.test(value) &&
  /\W/.test(value);
export const validateConfirmPassword = (value, current) =>
  value === current.password;
