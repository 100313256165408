import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-pad-wrapper';
import useFeatureConfiguration from '../../../hooks/useFeatureConfiguration';

const LoggedInModalContent = forwardRef(function LoggedInModalContent(
  { closeModal, handleSignatureSave, handleClear },
  ref
) {
  const config = useFeatureConfiguration();
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {config?.signModalTitle || 'Apply Your Signature'}
        </h5>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body signature-modal-body">
        <img
          src={config?.signatureBackgroundImage?.src || '/Signature_image.png'}
          alt={config?.signatureBackgroundImage?.alt || ''}
        />
        <SignaturePad
          ref={ref}
          redrawOnResize
          options={{
            backgroundColor: 'rgba(255, 255, 255, 0)',
            maxHeight: '100'
          }}
        />
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={closeModal}
          data-testid="close-modal-btn"
          data-bs-dismiss="modal"
        >
          {config?.modalsCloseButton || 'Close'}
        </button>
        <button
          type="button"
          id="clear"
          onClick={handleClear}
          data-testid="clear-btn"
          className="btn btn-outline-dark"
        >
          {config?.signModalClearButton || 'Clear Signature'}
        </button>
        <button
          type="button"
          id="sign"
          onClick={handleSignatureSave}
          data-testid="sign-btn"
          className="btn btn-monarc-orange"
        >
          {config?.signConsentButtonLabel || 'Sign'}
        </button>
      </div>
    </div>
  );
});

LoggedInModalContent.propTypes = {
  closeModal: PropTypes.func,
  handleSignatureSave: PropTypes.func,
  handleClear: PropTypes.func
};

export default LoggedInModalContent;
