import { types } from '../actions/prnOptions';
import StateLoader from './StateLoader';

const stateLoader = new StateLoader();
const initialState = stateLoader.initializeState().prnOptions;

export default function prnOptionsReducer(state = initialState, action) {
  switch (action.type) {
    case types.START:
      return {
        ...state,
        didLoad: false,
        isLoading: true
      };
    case types.SUCCESS:
      return {
        ...state,
        items: action.payload,
        didLoad: true,
        isLoading: false
      };
    case types.FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case types.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}
