import React from 'react';
import PropTypes from 'prop-types';
import SHROnboarding from '../Components/Providers/SHROnboarding.js';
import SHRLoading from '../Components/Providers/SHRLoading.js';
import SHRLoadingError from '../Components/Providers/SHRLoadingError.js';

export default function ImportRecords(props) {
  const SHR_VIEWS = {
    null: <div />,
    0: <SHROnboarding {...props} />,
    1: <SHROnboarding {...props} />,
    2: <SHRLoading {...props} />,
    3: <SHRLoadingError {...props} />
  };
  return <div>{SHR_VIEWS[props.shrStatus]}</div>;
}

ImportRecords.propTypes = {
  shrStatus: PropTypes.any
};
