import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import { isValid } from 'date-fns';
class DateInput extends Component {
  state = {
    className: 'form-control',
    errorContainer: '',
    value: this.props.value || null
  };
  setClassState = () => {
    if (this.props.errors && this.props.errors[this.props.inputID]) {
      this.setState({
        className: 'form-control is-invalid',
        errorContainer: 'show-invalid-feedback'
      });
    }
    if (this.props.success && this.props.success[this.props.inputID]) {
      this.setState({
        className: 'form-control is-valid',
        errorContainer: ''
      });
    }
  };
  componentDidMount() {
    this.setClassState();
  }
  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps, this.props)) this.setClassState();
  }
  onChange = value => {
    if (value === null) {
      this.setState({
        className: 'form-control is-invalid',
        errorContainer: 'show-invalid-feedback'
      });
    } else {
      value = new Date(value);
      if (!isValid(value)) {
        return;
      }
    }
    this.setState({
      value: value
    });
    this.props.onChange(value, this.props.inputID);
  };
  render() {
    const { placeholder, required } = this.props;
    return (
      <div className={this.state.errorContainer}>
        <DatePicker
          autoComplete="on"
          className={this.state.className}
          type={this.props.inputType}
          id={this.props.inputID}
          selected={this.state.value}
          onChange={this.onChange}
          onChangeRaw={event => this.onChange(event.target.value)}
          dateFormat="MM/dd/yyyy"
          placeholderText={placeholder || 'MM/DD/YYYY'}
          required={required}
        />
      </div>
    );
  }
}

DateInput.propTypes = {
  errors: PropTypes.any,
  inputID: PropTypes.any,
  inputType: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  required: PropTypes.any,
  success: PropTypes.any,
  value: PropTypes.any
};
export default DateInput;
