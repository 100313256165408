/* eslint-disable */
import React, { Component } from 'react';
import BaseContainer from './BaseContainer';
import DashboardConsent from './../pages/DashboardConsent/index';
import * as providerActions from '../../actions/providers';
import * as userActions from '../../actions/user';
import * as recordActions from '../../actions/records';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectSignedConsents,
  selectUnsignedConsents
} from '../../selectors/user';
class DashboardConsentContainer extends Component {
  render() {
    const {
      match: {
        params: { type, id }
      },
      history,
      signedConsents,
      unsignedConsents
    } = this.props;

    return (
      <BaseContainer {...this.props}>
        <DashboardConsent
          history={history}
          type={type || 'list'}
          id={id}
          consent={unsignedConsents[0]}
          signedAuths={signedConsents}
          unsignedAuths={unsignedConsents}
          {...this.props}
        />
      </BaseContainer>
    );
  }
}

function mapStateToProps(state) {
  const {
    auth: { authToken, profileSession },
    providers: { providers, isLoading },
    records
  } = state;

  return {
    authToken,
    profileSession,
    signedConsents: selectSignedConsents(state),
    unsignedConsents: selectUnsignedConsents(state),
    providers,
    isLoadingProviders: isLoading
  };
}
function mapDispatchToProps(dispatch) {
  return {
    providerActions: bindActionCreators(providerActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    recordActions: bindActionCreators(recordActions, dispatch)
  };
}

DashboardConsentContainer.propTypes = {
  authToken: PropTypes.string,
  consents: PropTypes.array,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  medfusionE: PropTypes.object,
  match: PropTypes.any,
  profileSession: PropTypes.object,
  providerActions: PropTypes.object,
  providers: PropTypes.array,
  userActions: PropTypes.object
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardConsentContainer);
