import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { min, reduce } from 'lodash';
import { useTheme } from '../../Components/ThemeProvider.js';

const parseRichText = (acc = '', { content, value } = {}) => {
  if (content) {
    return reduce(content, parseRichText, acc);
  }
  return acc + (value || '');
};

const StickyFooter = ({ scrollContainerRef }) => {
  const { theme } = useTheme();
  const containerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const collapsedHeight = theme.isiConfiguration?.collapsedHeight || 190;
  const onScroll = useCallback(
    ({ target }) => {
      const scrollContainer = scrollContainerRef?.current;
      const container = containerRef?.current;
      const scroll =
        (target?.scrollTop || 0) + (scrollContainer?.offsetHeight || 0);
      const containerTop = container?.offsetTop || 0;
      const shouldBeSticky = containerTop + collapsedHeight >= scroll;
      setIsSticky(shouldBeSticky);
    },
    [scrollContainerRef, containerRef, collapsedHeight]
  );
  useEffect(() => {
    const scrollContainer = scrollContainerRef?.current;
    scrollContainer?.addEventListener('scroll', onScroll);
    return () => scrollContainer?.removeEventListener('scroll', onScroll);
  }, [scrollContainerRef, onScroll]);

  const onToggleExpand = () => setIsExpanded(current => !current);
  const __html = useMemo(
    () => parseRichText('', theme.isiConfiguration?.contentHTMLandCSS),
    [theme.isiConfiguration?.contentHTMLandCSS]
  );
  const topBarAndFooterHeight =
    document.documentElement.clientWidth <= 960 ? 117 : 141;
  const maxHeight =
    document.documentElement.clientHeight - topBarAndFooterHeight;
  const expandedHeight = theme.isiConfiguration?.expandedHeight
    ? min(theme.isiConfiguration?.expandedHeight, maxHeight)
    : undefined;
  return (
    <div className="sticky-footer-section container" ref={containerRef}>
      <div
        className={`${isSticky ? 'sticky-footer' : ''} ${
          isExpanded ? 'expanded' : 'collapsed'
        }`}
        style={{
          height: (isExpanded ? expandedHeight : collapsedHeight) || undefined
        }}
      >
        <div className="container">
          {isSticky ? (
            <button
              data-testid="sticky-footer-expand-btn"
              type="button"
              className="expand-button"
              onClick={onToggleExpand}
            >
              {isExpanded
                ? theme.isiConfiguration?.collapseText || 'Collapse'
                : theme.isiConfiguration?.expandText || 'Expand'}
            </button>
          ) : null}
          <div dangerouslySetInnerHTML={{ __html }} />
        </div>
      </div>
    </div>
  );
};

StickyFooter.propTypes = {
  scrollContainerRef: PropTypes.any
};

export default StickyFooter;
