import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
class TextInput extends Component {
  state = {
    className: 'form-control',
    passwordHidden: true,
    password: ''
  };
  setClassState = () => {
    this.setState({ password: this.props.value, className: 'form-control' });
    if (
      this.props.errors &&
      this.props.errors[this.props.inputID] &&
      this.props.value
    ) {
      this.setState({ className: 'form-control is-invalid' });
    }
    if (this.props.success && this.props.success[this.props.inputID]) {
      this.setState({ className: 'form-control is-valid' });
    }
  };
  togglePasswordVisbility = () => {
    this.setState({
      passwordHidden: !this.state.passwordHidden
    });
  };
  componentDidMount() {
    this.setClassState();
  }
  componentDidUpdate(oldProps) {
    if (!_.isEqual(oldProps, this.props)) this.setClassState();
  }
  render() {
    return (
      <div className="input-group">
        <input
          autoComplete="on"
          className={this.state.className}
          type={this.state.passwordHidden ? 'password' : 'text'}
          id={this.props.inputID}
          data-testid={this.props['data-testid']}
          onInput={this.props.onChange}
          onBlur={this.props.onBlur}
          key={this.props.key}
          placeholder={this.props.placeholder}
          pattern={this.props.pattern}
          value={this.state.password}
          required={this.props.required}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          onKeyUp={this.props.onKeyUp}
          onKeyPressCapture={this.props.onKeyPress}
        />
        {this.state.passwordHidden && (
          <div className="input-group-append">
            <button
              type="button"
              className="input-group-text show-password"
              onClick={this.togglePasswordVisbility}
            >
              <i id="toggle-password-icon" className="fas fa-eye" />
            </button>
          </div>
        )}
        {!this.state.passwordHidden && (
          <div className="input-group-append">
            <button
              type="button"
              className="input-group-text show-password"
              onClick={this.togglePasswordVisbility}
            >
              <i id="toggle-password-icon" className="fas fa-eye-slash" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

TextInput.propTypes = {
  errors: PropTypes.any,
  inputID: PropTypes.any,
  inputType: PropTypes.any,
  key: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  onFocus: PropTypes.any,
  onKeyPress: PropTypes.any,
  onKeyUp: PropTypes.any,
  pattern: PropTypes.any,
  placeholder: PropTypes.any,
  required: PropTypes.any,
  success: PropTypes.any,
  value: PropTypes.any,
  passwordHidden: PropTypes.any,
  password: PropTypes.any,
  'data-testid': PropTypes.string
};
export default TextInput;
