import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  handleOutsideClick = () => {
    if (this.props.closeOnOutsideClick) {
      this.props.onClose();
    }
  };
  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div
        data-testid="modal-backdrop"
        className={`backdrop ${this.props.className}`}
        onClick={this.handleOutsideClick}
      >
        <div className="modal-content">{this.props.children}</div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
  closeOnOutsideClick: PropTypes.bool,
  className: PropTypes.string
};

export default Modal;
