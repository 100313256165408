import React, { useState, useEffect } from 'react';
import { useTheme } from '../../../ThemeProvider';
import HeaderWithBackground from '../../../HeaderWithBackground';
import useFeatureConfiguration from '../../../../hooks/useFeatureConfiguration';
import replaceTemplatesInString from '../../../../utils/strings/replaceTemplatesInString';
import { isString, pickBy } from 'lodash';

function getTitleContent(config, theme, display) {
  if (!display) return '';
  const stringWithTemplate =
    config?.consentBannerText || `Welcome to the {companyName} {programName}`;
  const onlyTextsFromTheme = pickBy(theme, isString);
  return replaceTemplatesInString(stringWithTemplate, onlyTextsFromTheme);
}

function AuthorizationsHeader() {
  const { theme, refetchTheme } = useTheme();
  const config = useFeatureConfiguration();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    // Had to refetch theme because on certain cases programName wasn't updated.
    refetchTheme().then(() => {
      setTimeout(() => {
        setDisplay(true);
      }, 500);
    });
  }, [refetchTheme]);

  return (
    <HeaderWithBackground
      title={getTitleContent(config, theme, display)}
      backgroundImage={
        config?.consentBannerImage || { src: '/header-banner-img.jpg' }
      }
    />
  );
}

export default AuthorizationsHeader;
