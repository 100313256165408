import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
class ProfileAlert extends Component {
  render() {
    let title = '';

    if (this.props.type === 'alert-warning') {
      title = this.props.t('profile.alert.error');
    } else if (this.props.title === 'updateProfile') {
      title = this.props.t('profile.alert.profileUpdated');
    } else if (this.props.title === 'changePassword') {
      title = this.props.t('profile.alert.passwordUpdated');
    }
    return (
      <div
        className={'alert alert-dismissible fade show ' + this.props.type}
        role="alert"
      >
        <strong>{title}</strong> {this.props.message}
        <button type="button" className="close" onClick={this.props.closeAlert}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}
ProfileAlert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  closeAlert: PropTypes.func,
  title: PropTypes.string,
  t: PropTypes.func.isRequired
};
export default withTranslation()(ProfileAlert);
