import * as types from '../constants/actionTypes';
import StateLoader from './StateLoader';
const stateLoader = new StateLoader();

export default function invitationsReducer(
  state = stateLoader.initializeState().records,
  action
) {
  switch (action.type) {
    case types.INIT_RECORDS_SUCCESS: {
      {
        const loadingState = { data: null, isLoading: true };
        return {
          ...state,
          recordCards: {
            ...state.recordCards,
            MEDICATIONS: {
              ...state.recordCards.MEDICATIONS,
              ...loadingState,
              fullData: [],
              data: []
            },
            CONDITIONS: {
              ...state.recordCards.CONDITIONS,
              ...loadingState,
              fullData: [],
              data: []
            },
            IMMUNIZATIONS: {
              ...state.recordCards.IMMUNIZATIONS,
              ...loadingState,
              fullData: [],
              data: []
            },
            VITAL_SIGNS: {
              ...state.recordCards.VITAL_SIGNS,
              ...loadingState,
              fullData: [],
              data: []
            },
            RESULTS: {
              ...state.recordCards.RESULTS,
              ...loadingState,
              fullData: [],
              data: []
            },
            PROCEDURES: {
              ...state.recordCards.PROCEDURES,
              ...loadingState,
              fullData: [],
              data: []
            },
            ALERTS: {
              ...state.recordCards.ALERTS,
              ...loadingState,
              fullData: [],
              data: []
            },
            APPOINTMENTS: {
              ...state.recordCards.APPOINTMENTS,
              ...loadingState,
              fullData: [],
              data: []
            },
            IMAGING: {
              ...state.recordCards.IMAGING,
              ...loadingState,
              fullData: [],
              data: []
            },
            GENETIC_TESTING: {
              ...state.recordCards.GENETIC_TESTING,
              ...loadingState,
              fullData: [],
              data: []
            },
            OFFICE_VISIT: {
              ...state.recordCards.OFFICE_VISIT,
              ...loadingState,
              fullData: [],
              data: []
            },
            ALLERGIES: {
              ...state.recordCards.ALLERGIES,
              ...loadingState,
              fullData: [],
              data: []
            },
            HOSPITALIZATION: {
              ...state.recordCards.HOSPITALIZATION,
              ...loadingState,
              fullData: [],
              data: []
            },
            PATHOLOGY: {
              ...state.recordCards.PATHOLOGY,
              ...loadingState,
              fullData: [],
              data: []
            },
            DEMOGRAPHICS: {
              ...state.recordCards.DEMOGRAPHICS,
              ...loadingState,
              fullData: [],
              data: []
            },
            isLoading: true
          },
          isLoading: false,
          loadingError: '',
          records: {},
          selectedRequest: '',
          mfcRecords: {}
        };
      }
    }
    case types.INIT_RECORDS_FAILURE:
      return {
        ...state,
        records: {},
        selectedRequest: '',
        isLoading: false,
        loadingError: action.error.reason ? action.error.reason : 'error'
      };
    case types.CLEAR:
      return {
        ...state,
        records: {},
        selectedRequest: '',
        isLoading: true,
        recordCards: {
          isLoading: true
        },
        loadingError: action.response
      };
    case types.FETCH_RECORDS_BY_TYPE_START:
      if (action.countOnly) {
        return {
          ...state,
          recordCount: 0,
          isLoading: true
        };
      } else {
        return {
          ...state,
          loadingError: '',
          recordCards: {
            ...state.recordCards,
            [action.recordType]: {
              fullData: [],
              ...state.recordCards[action.recordType],
              data: null,
              isLoading: true
            },
            isLoading: true
          }
        };
      }
    case types.FETCH_RECORDS_BY_TYPE_SUCCESS:
      if (action.countOnly) {
        return {
          ...state,
          recordCards: {
            ...state.recordCards,
            [action.recordType]: {
              ...state.recordCards[action.recordType],
              recordCount: parseInt(action.response.data.count)
            },
            isLoading: false
          }
        };
      } else {
        return {
          ...state,
          recordCards: {
            ...state.recordCards,
            [action.recordType]: {
              ...state.recordCards[action.recordType],
              data: action.response.data,
              fullData: action.response.fullData,
              isLoading: false
            },
            isLoading: false
          }
        };
      }

    case types.FETCH_RECORDS_BY_TYPE_FAILURE:
      if (action.countOnly) {
        return {
          ...state,
          recordCount: 0,
          isLoading: true
        };
      } else {
        return {
          ...state,
          recordCards: {
            ...state.recordCards,
            [action.recordType]: { data: null, isLoading: false },
            isLoading: true
          },
          loadingError: action.response
        };
      }

    case types.SET_SELECTED_REQUEST_SUCCESS: {
      return {
        ...state,
        recordCards: {},
        selectedRequest: action.requestId
      };
    }

    case types.SET_SELECTED_REQUEST_FAILURE: {
      return {
        ...state,
        recordCards: {},
        selectedRequest: ''
      };
    }

    case 'SET_LOADING_FALSE': {
      const loadingState = { data: null, isLoading: false };
      return {
        ...state,
        recordCards: {
          ...state.recordCards,
          MEDICATIONS: {
            ...state.recordCards.MEDICATIONS,
            ...loadingState
          },
          CONDITIONS: {
            ...state.recordCards.CONDITIONS,
            ...loadingState
          },
          IMMUNIZATIONS: {
            ...state.recordCards.IMMUNIZATIONS,
            ...loadingState
          },
          VITAL_SIGNS: {
            ...state.recordCards.VITAL_SIGNS,
            ...loadingState
          },
          RESULTS: {
            ...state.recordCards.RESULTS,
            ...loadingState
          },
          PROCEDURES: {
            ...state.recordCards.PROCEDURES,
            ...loadingState
          },
          ALERTS: {
            ...state.recordCards.ALERTS,
            ...loadingState
          },
          APPOINTMENTS: {
            ...state.recordCards.APPOINTMENTS,
            ...loadingState
          },
          IMAGING: {
            ...state.recordCards.IMAGING,
            ...loadingState
          },
          GENETIC_TESTING: {
            ...state.recordCards.GENETIC_TESTING,
            ...loadingState
          },
          OFFICE_VISIT: {
            ...state.recordCards.OFFICE_VISIT,
            ...loadingState
          },
          ALLERGIES: {
            ...state.recordCards.ALLERGIES,
            ...loadingState
          },
          HOSPITALIZATION: {
            ...state.recordCards.HOSPITALIZATION,
            ...loadingState
          },
          PATHOLOGY: {
            ...state.recordCards.PATHOLOGY,
            ...loadingState
          },
          DEMOGRAPHICS: {
            ...state.recordCards.DEMOGRAPHICS,
            ...loadingState
          },
          isLoading: false
        }
      };
    }

    default:
      return state;
  }
}
