import React from 'react';
import PropTypes from 'prop-types';
import AuthorizationPanel from '../../../Authorization/AuthorizationPanel';
import { useTheme } from '../../../ThemeProvider';

const ScrollspyContent = props => {
  const { title } = props;
  const {
    theme: {
      smallLogo: { src, alt }
    }
  } = useTheme();
  return title ? (
    <div id="authPanelContainer">
      <AuthorizationPanel {...props} />
    </div>
  ) : (
    <div className="authorizations-container-loading">
      <img
        className="animated infinite pulse loading-logo col-2"
        src={src}
        alt={alt}
        style={{ height: 144, objectFit: 'contain' }}
      />
    </div>
  );
};

ScrollspyContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object
};

export default ScrollspyContent;
