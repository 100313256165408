import React from 'react';
import PropTypes from 'prop-types';
import Nav from './Nav';

const outerStyle = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '40px',
  paddingBottom: '40px'
};

const formSignin = {
  width: '100%',
  maxWidth: '330px',
  padding: '15px',
  borderRadius: '5px',
  backgroundColor: '#f5f5f5',
  boxShadow: '0 0 5px rgba(25,25,25,0.25)',
  overflow: 'hidden'
};

const wrapperStyle = { background: 'var(--color-secondary)', height: '100%' };

function NoAuthPagesWrapper({ children, ...props }) {
  return (
    <div style={wrapperStyle}>
      <div className="login-form">
        <Nav isLoggedIn={!!props.authToken} isLanding {...props} />
        <div className="text-center" style={outerStyle}>
          <form className="form-signin" style={formSignin}>
            {children}
          </form>
        </div>
      </div>
    </div>
  );
}

NoAuthPagesWrapper.propTypes = {
  authToken: PropTypes.string,
  children: PropTypes.node
};

export default NoAuthPagesWrapper;
