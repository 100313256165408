import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as styles from './styles';
import { parse, stringify } from 'query-string';

class Pagination extends Component {
  state = {
    innerPage: 1,
    pageSize: 0,
    render404: false
  };
  setPageSizeAndInitialPage = changed => {
    const { data, rowsPerPage } = this.props;
    const dataDivided = data.length / rowsPerPage;
    let pageSize =
      dataDivided % 1 === 0 ? dataDivided : parseInt(dataDivided, 10) + 1;

    this.setState({ pageSize }, () => {
      if (changed) return null;
      const urlParams = new URLSearchParams(window.location.search);
      const initialPage = parseInt(urlParams.get('page'), 10);
      if (initialPage <= pageSize) this.handlePageChange(initialPage);
      else if (initialPage > pageSize) this.render404();
    });
  };
  render404 = () => {
    this.setState({ render404: true });
  };
  componentDidMount = () => {
    if (this.props.data && this.props.data.length > 0) {
      this.setPageSizeAndInitialPage();
    }
  };
  componentDidUpdate = oldProps => {
    if (
      (this.props.data.length > 0 &&
        oldProps.data.length !== this.props.data.length) ||
      (this.props.page === 1 && oldProps.page !== 1)
    ) {
      this.setState({ innerPage: 1 });
      let isFiltered = oldProps.data.length > 0;
      this.setPageSizeAndInitialPage(isFiltered);
    }
  };
  handlePageChange = innerPage => {
    this.addQueryParam({ page: innerPage });
    const { data, rowsPerPage } = this.props;
    const { pageSize } = this.state;
    if (innerPage < 6 || innerPage >= data.length / rowsPerPage - 3) {
      if (innerPage > 9)
        this.setState({
          innerPage: pageSize - 9
        });
      else if (innerPage <= 5) {
        this.setState({
          innerPage: 1
        });
      }
      this.props.setPage(innerPage);
      return null;
    }
    this.setState({ innerPage: innerPage - 5 }, () => {
      this.props.setPage(innerPage);
    });
  };

  addQueryParam = params => {
    const {
      history,
      history: { location }
    } = this.props;
    const search = stringify(Object.assign({}, parse(location.search), params));

    history.replace({ search });
    this.setState(params);
  };
  setNumbersArray = dataLength => {
    const { innerPage } = this.state;
    const { rowsPerPage, page } = this.props;
    const limit = dataLength / rowsPerPage < 10 ? dataLength / rowsPerPage : 10;
    let numbersArray = [];
    const { pageSize } = this.state;

    for (let i = 0; i < limit; i++) {
      const className =
        page === innerPage + i ? 'active page-item' : 'page-item';
      innerPage + i <= pageSize &&
        numbersArray.push(this.renderPaginationItem(className, innerPage + i));
    }
    return numbersArray;
  };
  renderPaginationItem = (className, value, display) => (
    <li
      key={value}
      className={className}
      onClick={() => {
        this.handlePageChange(value);
      }}
      data-testid={`pagination-item-${value}`}
    >
      <span className="page-link" href="#">
        {display || value}
      </span>
    </li>
  );
  renderListContent = (numbersArray, page, pageSize) => (
    <React.Fragment>
      {this.renderPaginationItem(
        page === 1 ? 'page-item disabled' : 'page-item',
        page - 1,
        '«'
      )}
      {numbersArray}
      {numbersArray.length > 1 &&
        this.renderPaginationItem(
          page === pageSize ? 'page-item disabled' : 'page-item',
          page + 1,
          '»'
        )}
    </React.Fragment>
  );
  _renderPagination = () => {
    const { data, page } = this.props;
    const { pageSize } = this.state;
    const dataLength = data ? data.length : 0;
    const numbersArray = this.setNumbersArray(dataLength);

    return data && numbersArray.length > 1 ? (
      <ul
        className="d-flex flex-row flex-wrap align-items-center m-0 p-0 pagination-items pagination"
        style={styles.pagesContainerStyle}
      >
        {this.renderListContent(numbersArray, page, pageSize)}
      </ul>
    ) : null;
  };
  render() {
    return this.state.render404 ? (
      <Redirect to="/404" />
    ) : (
      <div className="py-2">{this._renderPagination()}</div>
    );
  }
}
Pagination.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  data: PropTypes.array,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func,
  page: PropTypes.number
};
export default Pagination;
