import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '../actions/auth';
import { decrypt } from '../utils/cypher';
import { useSearchQuery } from '../hooks/navigation';

/**
 * Custom hook to use the custom authentication for private route using params.
 * @returns {boolean} The flag to indicate if the custom authentication is performing.
 */
export function useCustomAuthentication() {
  const searchParams = useSearchQuery();
  const authParam = searchParams.get('auth');

  const { pathname } = useLocation();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const [performingAuth, setPerformingAuth] = useState(!!authParam);
  useEffect(() => {
    if (performingAuth) {
      const data = atob(authParam);
      const dataDecrypted = decrypt(data);
      const { email, ps: psEncrypted } = JSON.parse(dataDecrypted);
      const ps = decrypt(psEncrypted);

      dispatch(actions.login(email, ps)).then(() => {
        setPerformingAuth(false);
        replace(pathname);
      });
    }
  }, [authParam, performingAuth, replace, pathname, dispatch]);

  return performingAuth;
}
