import i18n from '../i18n';

/* eslint-disable complexity */
export const MEDICATIONS = 'MEDICATIONS';
export const CONDITIONS = 'CONDITIONS';
export const IMMUNIZATIONS = 'IMMUNIZATIONS';
export const VITALS = 'VITAL_SIGNS';
export const LABS_AND_TEST_RESULTS = 'RESULTS';
export const PROCEDURES = 'PROCEDURES';
export const ALERTS = 'ALERTS';
export const APPOINTMENTS = 'APPOINTMENTS';
export const IMAGING = 'IMAGING';
export const GENETIC_TESTING = 'GENETIC_TESTING';
export const OFFICE_VISIT = 'OFFICE_VISIT';
export const ALLERGIES = 'ALLERGIES';
export const HOSPITALIZATION = 'HOSPITALIZATION';
export const PATHOLOGY = 'PATHOLOGY';
export const DEMOGRAPHICS = 'DEMOGRAPHICS';

export function getDisplayText(recordType) {
  switch (recordType) {
    case MEDICATIONS: {
      return i18n.t('recordTypes.medications');
    }
    case CONDITIONS: {
      return i18n.t('recordTypes.conditions');
    }
    case IMMUNIZATIONS: {
      return i18n.t('recordTypes.immunizations');
    }
    case VITALS: {
      return i18n.t('recordTypes.vitals');
    }
    case LABS_AND_TEST_RESULTS: {
      return i18n.t('recordTypes.labsAndTestResults');
    }
    case PROCEDURES: {
      return i18n.t('recordTypes.procedures');
    }
    case ALERTS: {
      return i18n.t('recordTypes.alerts');
    }
    case APPOINTMENTS: {
      return i18n.t('recordTypes.appointments');
    }
    case IMAGING: {
      return i18n.t('recordTypes.imaging');
    }
    case GENETIC_TESTING: {
      return i18n.t('recordTypes.geneticTesting');
    }
    case OFFICE_VISIT: {
      return i18n.t('recordTypes.officeVisit');
    }
    case ALLERGIES: {
      return i18n.t('recordTypes.allergies');
    }
    case HOSPITALIZATION: {
      return i18n.t('recordTypes.hospitalization');
    }
    case PATHOLOGY: {
      return i18n.t('recordTypes.pathology');
    }
    case DEMOGRAPHICS: {
      return i18n.t('recordTypes.demographics');
    }
    default:
      return '';
  }
}
