import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions/records';
import * as allProviderActions from '../../../actions/providers';
import useFeatureConfiguration from '../../../hooks/useFeatureConfiguration';
import { useTheme } from '../../ThemeProvider';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

function DashboardShrMyRecord({ authToken, profileSession, providerActions }) {
  const {
    theme: { secondaryColor }
  } = useTheme();
  const config = useFeatureConfiguration();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    providerActions.getProviders(authToken, profileSession.user.id);
  }, [profileSession, authToken, providerActions]);

  return (
    <div className="providers-container dashboard-item shr-container">
      <div
        className="d-flex flex-column p-3 shr-inner-container"
        style={{
          background: '#fff'
        }}
      >
        <div
          className="dashboard-item-banner"
          style={{ backgroundColor: secondaryColor }}
        >
          <h2 style={{ color: 'white', fontWeight: '200' }}>
            {config.shrRecordsPage?.title}
          </h2>
        </div>
        <div className="row justify-content-center">
          <div
            className="my-5"
            style={{
              borderRadius: '150px',
              width: '150px',
              height: '150px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url('${config.shrRecordsPage?.image?.src ||
                '/LoadGif.gif'}')`
            }}
          />
          <div className="col-10">
            {documentToReactComponents(config.shrRecordsPage?.content)}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ auth }) {
  return {
    authToken: auth.authToken,
    profileSession: auth.profileSession
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    providerActions: bindActionCreators(allProviderActions, dispatch)
  };
}
DashboardShrMyRecord.propTypes = {
  authToken: PropTypes.string,
  profileSession: PropTypes.object,
  actions: PropTypes.object,
  providerActions: PropTypes.object
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardShrMyRecord);
