import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { inRange } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changePassword } from '../../utils/OneVueAPI.js';
import FormInput from '../../Components/FormInput/FormInput.js';
import ProfileAlert from './ProfileAlert';
import * as actions from '../../actions/user';
import ButtonOutline from '../ButtonOutline.js';
import { featureConfigurationHOC } from '../../hooks/useFeatureConfiguration.js';
import { withTranslation } from 'react-i18next';

class Profile extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    initialDob: '',
    state: '',
    phone: '',
    zip: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    passwordErrors: {},
    initialUser: {},
    alertType: null,
    alertMessage: null,
    alertSection: ''
  };
  componentDidMount = () => {
    this.props.actions.getProfile(this.props.authToken);
  };

  componentDidUpdate = ({ isProfileLoading: oldProfileLoading }, prevState) => {
    const { isProfileLoading } = this.props;
    if (oldProfileLoading !== isProfileLoading && oldProfileLoading) {
      this.setProfileSession();
    }
    if (this.state !== prevState) {
      this.forceUpdate();
    }
  };

  setProfileSession = () => {
    const {
      profileSession: { user }
    } = this.props;
    const dob = format(user.birthday, 'MM/DD/YYYY');
    this.setState({
      ...user,
      initialUser: user,
      initialDob: dob,
      dob,
      isProfileLoading: false,
      phone: user.phone?.replace?.('+1', '')
    });
  };
  onChange = event => {
    let name = event.target.id;
    let value = event.target.value;

    this.setState(
      {
        [name]: value
      },
      () => {
        this.validateChangePasswordFields(name, value);
      }
    );
  };

  validatePassword(password) {
    const minLen = 8;
    const maxLen = 20;
    const validations = [
      /[a-z]/, // lower case character;
      /[A-Z]/, // upper case character
      /\d/ // digit character
    ];
    return (
      !password ||
      !inRange(password.length, minLen, maxLen + 1) ||
      !validations.every(validation => validation.test(password))
    );
  }

  validatePasswordAdditional(password) {
    const symbol = /[\W]/; // any symbol
    return !symbol.test(password);
  }
  caseOldPassword = (password, fieldName) => {
    let passwordValidationErrors = {};
    let passwordSuccess = {};

    if (this.validatePassword(password)) {
      passwordValidationErrors[fieldName] = true;
    } else {
      passwordValidationErrors[fieldName] = null;
      passwordSuccess[fieldName] = true;
    }
    return { passwordValidationErrors, passwordSuccess };
  };
  newPasswordCase = (password, fieldName) => {
    let passwordValidationErrors = {};
    let passwordSuccess = {};

    if (
      this.validatePassword(password) ||
      this.validatePasswordAdditional(password)
    ) {
      passwordValidationErrors[fieldName] = true;
      this.setState({
        passwordInvalid: true
      });
    } else {
      passwordValidationErrors[fieldName] = null;
      passwordSuccess[fieldName] = true;
      this.setState({
        passwordInvalid: false
      });
    }
    return { passwordValidationErrors, passwordSuccess };
  };
  confirmPasswordCase = (password, fieldName) => {
    const { t } = this.props;

    let passwordValidationErrors = {};
    let passwordSuccess = {};
    if (password !== this.state.newPassword || password.length < 1) {
      passwordValidationErrors[fieldName] = true;
      this.setState({
        confirmPasswordError: t('profile.passwordNotMatch')
      });
    } else if (this.state.passwordInvalid) {
      passwordValidationErrors[fieldName] = true;
      this.setState({
        confirmPasswordError: t('profile.passwordRules')
      });
    } else {
      passwordValidationErrors[fieldName] = null;
      passwordSuccess[fieldName] = true;
    }
    return { passwordValidationErrors, passwordSuccess };
  };
  validateChangePasswordFields(fieldName, password) {
    let validation;
    switch (fieldName) {
      case 'oldPassword':
        validation = this.caseOldPassword(password, fieldName);
        break;
      case 'newPassword':
        validation = this.newPasswordCase(password, fieldName);
        break;
      case 'confirmPassword':
        validation = this.confirmPasswordCase(password, fieldName);
        break;
      default:
        break;
    }
    const { passwordValidationErrors, passwordSuccess } = validation;
    this.setState({
      passwordErrors: passwordValidationErrors,
      success: passwordSuccess
    });
  }
  updatePassword = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    let formObject = {
      oldPassword,
      newPassword,
      confirmPassword
    };
    this.setState({
      ...formObject
    });
    changePassword(formObject, this.submitPasswordResponse);
    this.resetState();
  };
  resetState = () => {
    this.setState({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordErrors: {},
      alertType: null,
      alertMessage: null,
      alertSection: '',
      success: ''
    });
  };
  resetAlerts = () =>
    this.setState({
      loading: true,
      alertType: null,
      alertSection: null,
      alertMessage: null
    });
  validateNewPassword = (oldPassword, newPassword, confirmPassword) => {
    let alertMessage;
    if (newPassword !== confirmPassword)
      alertMessage = this.props.t('profile.passwordsShouldMatch');

    if (!oldPassword.trim() || !newPassword.trim() || !confirmPassword.trim()) {
      alertMessage = this.props.t('profile.allRequiredFields');
    }
    return alertMessage;
  };
  changePasswordCase = (oldPassword, newPassword, confirmPassword) => {
    let alertMessage;
    if (this.validatePassword(oldPassword))
      alertMessage = this.props.t('profile.incorrectOldPassword');

    if (
      this.validatePassword(newPassword) ||
      this.validatePasswordAdditional(newPassword)
    )
      alertMessage = this.props.t('profile.invalidNewPassword');

    alertMessage = this.validateNewPassword(
      oldPassword,
      newPassword,
      confirmPassword
    );

    return alertMessage;
  };
  getAlertMessage = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    return this.changePasswordCase(oldPassword, newPassword, confirmPassword);
  };
  setAlertWarning = (alertMessage, alertSection, isSuccess) => {
    this.setState({
      loading: false,
      alertType: !isSuccess ? 'alert-warning' : 'alert-success',
      alertSection: alertSection,
      alertMessage: !isSuccess ? alertMessage : undefined
    });
  };
  handleChangePassword = () => {
    this.resetAlerts();
    const alertMessage = this.getAlertMessage();
    alertMessage
      ? this.setAlertWarning(alertMessage, 'changePassword')
      : this.updatePassword(this.submitPasswordResponse);
  };

  submitPasswordResponse = response => {
    this.setAlertWarning(response.error, 'changePassword', response.success);
    this.toggleChangePasswordButton(false);
  };

  toggleChangePasswordButton = isCancel => {
    if (isCancel) {
      this.resetState();
    }
  };
  cancelAlert = () =>
    this.setState({
      alertType: null,
      alertSection: null,
      alertMessage: null
    });

  renderPlaceholder = value => {
    return !this.props.isProfileLoading ? (
      value
    ) : (
      <div className="ph-item profile">
        <div className={`ph-col-12`}>
          <div className="ph-row">
            <div className="ph-col-6 big" />
          </div>
        </div>
      </div>
    );
  };
  renderProfileContainer = () => {
    const { featureConfiguration } = this.props;
    const { email, firstName, lastName, dob, state, zip, phone } = this.state;
    const profileItems = [
      {
        display: featureConfiguration.userEmailFieldLabel || 'Email',
        value: email
      },
      {
        display: featureConfiguration.userNameFieldLabel || 'Name',
        value: firstName + ' ' + lastName
      }
    ];
    if (dob) {
      profileItems.push({
        display:
          featureConfiguration.userDateOfBirthFieldLabel || 'Date of Birth',
        value: dob
      });
    }
    if (state) {
      profileItems.push({
        display: featureConfiguration.userStateFieldLabel || 'State',
        value: state
      });
    }
    if (zip) {
      profileItems.push({
        display: featureConfiguration.userZipFieldLabel || 'Zip',
        value: zip
      });
    }
    if (phone) {
      profileItems.push({
        display: featureConfiguration.userPhoneFieldLabel || 'Phone',
        value: phone
      });
    }

    return (
      <div id="profileContainer">
        <div className="table-responsive">
          <table className="table p-3 my-3">
            <tbody>
              {profileItems.map(item => (
                <tr key={item.display}>
                  <th scope="row">{item.display}:</th>
                  <td>{this.renderPlaceholder(item.value)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  renderChangePasswordForm = () => {
    const { featureConfiguration } = this.props;
    const elem = document.querySelector('#changePassword');
    if (elem) elem.style.display = 'none';
    return (
      <div className="justify-content-center profile-change-password">
        <div className="col-12">
          <form noValidate className="needs-validation" id="password-form">
            <div className="mb-3">
              <FormInput
                label={
                  featureConfiguration.oldPasswordFieldLabel || 'Old Password*'
                }
                inputType="password"
                data-testid={'profile-oldPassword-input'}
                inputID="oldPassword"
                placeholder=""
                value={this.state.oldPassword}
                required={true}
                onChange={this.onChange}
                invalidFeedback="Please enter in a password that is at least 8 characters long with at least one letter and one number"
                errors={this.state.passwordErrors}
                success={this.state.success}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label={
                  featureConfiguration.newPasswordFieldLabel || 'New Password*'
                }
                inputType="password"
                data-testid={'profile-newPassword-input'}
                inputID="newPassword"
                placeholder=""
                value={this.state.newPassword}
                required={true}
                onChange={this.onChange}
                invalidFeedback="Please enter a password that is at least 8 characters long with at least one uppercase letter, one lowercase letter, and one number"
                errors={this.state.passwordErrors}
                success={this.state.success}
              />
            </div>
            <div className="mb-3">
              <FormInput
                label={
                  featureConfiguration.confirmPasswordFieldLabel ||
                  'Confirm New Password*'
                }
                inputType="password"
                data-testid={'profile-confirmPassword-input'}
                inputID="confirmPassword"
                placeholder=""
                value={this.state.confirmPassword}
                required={true}
                onChange={this.onChange}
                invalidFeedback={this.state.confirmPasswordError}
                errors={this.state.passwordErrors}
                success={this.state.success}
              />
            </div>
          </form>
        </div>
      </div>
    );
  };
  displayUpdateButtons = () => {
    const { featureConfiguration } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <ButtonOutline
          title={featureConfiguration.updatePasswordButton || 'Save'}
          buttonStyle="monarc-orange"
          action={this.handleChangePassword}
          testId="change-password-btn"
        />
        <ButtonOutline
          title={featureConfiguration.clearPasswordButton || 'Clear'}
          buttonStyle="dark"
          action={() => this.toggleChangePasswordButton(true, false)}
          testId="clear-password-btn"
        />
      </div>
    );
  };
  renderDashboardItem = () => {
    const { featureConfiguration } = this.props;
    return (
      <div className="my-3 mx-3 p-3 bg-white rounded box-shadow dashboard-item">
        <div data-cy="my-profile" className="dashboard-item-banner">
          <h2 style={{ color: 'white' }}>
            {featureConfiguration.profileSectionTitle || 'My Profile'}
          </h2>
        </div>
        {this.renderProfileContainer()}
      </div>
    );
  };
  render() {
    const { featureConfiguration } = this.props;
    const { alertType, alertSection, alertMessage } = this.state;
    const profileAlert = alertType ? (
      <ProfileAlert
        type={alertType}
        message={alertMessage}
        closeAlert={this.cancelAlert}
        title={alertSection}
      />
    ) : null;

    return (
      <div>
        {this.renderDashboardItem()}
        <div className="my-3 mx-3 p-3 bg-white rounded box-shadow dashboard-item">
          <div data-cy="change-password" className="dashboard-item-banner">
            <h2 style={{ color: 'white' }}>
              {featureConfiguration.passwordSectionTitle || 'Change Password'}
            </h2>
          </div>
          {alertSection === 'changePassword' && profileAlert}
          {this.displayUpdateButtons()}
          {this.renderChangePasswordForm()}
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  auth: { authToken, profileSession, isProfileLoading }
}) {
  return {
    authToken,
    profileSession,
    isProfileLoading
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

Profile.propTypes = {
  authToken: PropTypes.string,
  profileSession: PropTypes.object,
  actions: PropTypes.object,
  isProfileLoading: PropTypes.bool,
  featureConfiguration: PropTypes.object,
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(featureConfigurationHOC(withTranslation()(Profile)));
